import { useEffect } from "react";

const useAbortedEffect = (effect = (signal) => {}, dependencies) => {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const cleanupEffect = effect(signal);

    return () => {
      try {
        if (cleanupEffect) {
          cleanupEffect();
        }
        abortController.abort();
      } catch (e) {
        // console.log(e);
      }
    };
  }, [...dependencies]);
};

export default useAbortedEffect;
