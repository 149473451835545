import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout } from "../../redux/slice/system";

import { Sidebar } from "primereact/sidebar";
import { PanelMenu } from "primereact/panelmenu";
import { classNames } from "primereact/utils";

export default function BackSidebar(props) {
  let { LayoutCss } = props;
  const route_location = useLocation();
  const navigate = useNavigate();
  const system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  // menu 項目資料
  const [menuItem, setMenuItem] = useState([]);
  // 路徑
  const routes = [
    {
      label: "Inventory",
      path: "/back/inventory",
      icon: "pi pi-folder-open mr-3",
      items: [
        {
          label: "Gross",
          path: "/back/inventory/gross",
        },
        {
          label: "Warehouse",
          path: "/back/inventory/warehouse",
        },
        {
          label: "Pallet",
          path: "/back/inventory/pallet",
        },
        {
          label: "Receive",
          path: "/back/inventory/receive",
        },
      ],
    },
    {
      label: "Order",
      path: "/back/order",
      icon: "pi pi-folder-open mr-3",
      items: [
        {
          label: "VC",
          path: "/back/order/vc",
        },
      ],
    },
    {
      label: "Daily",
      path: "/back/daily",
      icon: "pi pi-folder-open mr-3",
      items: [
        {
          label: "ImportOrder",
          path: "/back/daily/importOrder",
        },
        {
          label: "NewOrder",
          path: "/back/daily/newOrder",
        },
        {
          label: "Overview",
          path: "/back/daily/overview",
        },
        {
          label: "CJ",
          path: "/back/daily/cj",
        },
      ],
    },
    {
      label: "Common",
      path: "/back/common",
      icon: "pi pi-folder-open mr-3",
      items: [
        {
          label: "Warehouse",
          path: "/back/common/warehouse",
        },
        {
          label: "Carrier",
          path: "/back/common/carrier",
        },
        // {
        //   label: "Google Table",
        //   path: "/back/common/GoogleTable",
        // },
        // {
        //   label: "Google Dump",
        //   path: "/back/common/googleDump",
        // },
      ],
    },
  ];

  const renderExpand = (item, options, layer) => {
    return (
      <>
        <a
          className={options.className}
          style={{ paddingLeft: 1 + layer * 2.28 + "rem" }}
          onClick={options.onClick}
        >
          <span className={classNames(options.iconClassName)}></span>
          <span className={classNames(options.labelClassName, "mr-auto")}>
            {item.label}
          </span>
          <i
            className={`pi pi-angle-down -rotate-${
              item.expanded ? "180" : "0"
            } transition-all transition-duration-200 transition-ease-in-out`}
          ></i>
        </a>
      </>
    );
  };

  const renderLink = (item, options, layer) => {
    return (
      <>
        <a
          className={options.className}
          style={{ paddingLeft: 1 + layer * 2.28 + "rem" }}
          onClick={options.onClick}
        >
          {/* <span className={classNames(options.iconClassName)}></span> */}
          <span className={classNames(options.labelClassName, "mr-auto")}>
            {item.label}
          </span>
        </a>
      </>
    );
  };

  const matchExpand = (path) => {
    let { pathname } = route_location;

    return pathname.indexOf(path) !== -1;
  };

  const matchPath = (path) => {
    let { pathname } = route_location;

    return pathname === path ? "menu-active" : "";
  };

  // 設定 menu 項目
  useEffect(() => {
    function formatRoutes(rawData) {
      let temp = [];

      function formatRoute(childs, layer) {
        let tempChild = [];
        layer++;

        childs.forEach((child) => {
          if (child.items && child.items.length > 0) {
            tempChild.push({
              label: child.label,
              icon: child.icon,
              expanded: matchExpand(child.path),
              template: (item, options) => renderExpand(item, options, layer),
              items: formatRoute(child.items, layer),
            });
          } else {
            tempChild.push({
              label: child.label,
              icon: child.icon,
              className: matchPath(child.path),
              template: (item, options) => renderLink(item, options, layer),
              command: (e) => {
                navigate(child.path);
              },
            });
          }
        });

        return tempChild;
      }

      rawData.forEach((route) => {
        if (route.items && route.items.length > 0) {
          temp.push({
            label: route.label,
            icon: route.icon,
            expanded: matchExpand(route.path),
            template: (item, options) => renderExpand(item, options),
            items: formatRoute(route.items, 0),
          });
        } else {
          temp.push({
            label: route.label,
            icon: route.icon,
            className: matchPath(route.path),
            command: (e) => {
              navigate(route.path);
            },
          });
        }
      });

      return temp;
    }

    setMenuItem(formatRoutes(routes));
  }, [route_location.pathname]);

  return (
    <>
      <div className={`${LayoutCss["layout-sidebar"]}`}>
        <Sidebar
          visible={system.backLayout.isSideShow}
          modal={!system.backLayout.isSideFixed}
          dismissable={!system.backLayout.isSideFixed}
          showCloseIcon={false}
          onHide={() =>
            dispatch(
              setBackLayout({
                isSideShow: false,
              })
            )
          }
        >
          {/* <div className={`${LayoutCss["layout-logo"]}`}>
            <img src={img_logo} />
          </div> */}
          {/* <Menu className="w-full border-none" model={menuItem} /> */}
          <PanelMenu model={menuItem} style={{ width: "100%" }} />
        </Sidebar>
      </div>
    </>
  );
}
