export default function ModuleTableEmpty(props) {
  return (
    <>
      <div className="text-center text-500 p-5 mt-4">
        <i className="fas fa-folder-open text-6xl"></i>
        <div className="text-lg mt-2">No Data</div>
      </div>
    </>
  );
}
