import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SystemAPI } from "../../axios/api";
import moment from "moment";

import {
  FormInputtext,
  FormInputpassword,
  FormDropdown,
} from "../../components/formElement/index";
import { formatOptions } from "../../service/common";
import { system_email } from "../../service/system";

import LoginCss from "../../styles/components/login.module.scss";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function SystemLogin(props) {
  const [optionData, setOptionData] = useState({});
  // 用於控制表單刷新
  const [formKey, setFormKey] = useState(new Date());
  // 步驟
  const [step, setStep] = useState(0);
  // 登入資訊
  const [loginData, setLoginData] = useState({});

  return (
    <>
      <div
        className={`${LoginCss["login-wrap"]} flex flex-column align-items-center justify-content-center w-full h-full`}
      >
        <Card
          className={`${LoginCss["login-inner-wrap"]} w-10 sm:w-6 max-w-30rem text-2xl px-3`}
        >
          <h1
            className={classNames("text-center mt-0", {
              "mb-2": process.env.REACT_APP_MODE === "development",
              "mb-6": process.env.REACT_APP_MODE !== "development",
            })}
          >
            Merchant
          </h1>

          {process.env.REACT_APP_MODE === "development" && (
            <div className="text-center mb-4">
              <div className="inline-block text-center text-xl text-white bg-red-500 font-bold mt-2 px-3 py-1">
                測 試 站
              </div>
            </div>
          )}

          {step === 0 ? (
            <ComAccPwd
              formKey={formKey}
              setFormKey={setFormKey}
              setStep={setStep}
              setLoginData={setLoginData}
            />
          ) : step === 1 ? (
            <ComSeller
              formKey={formKey}
              setFormKey={setFormKey}
              setStep={setStep}
              optionData={optionData}
              setOptionData={setOptionData}
              loginData={loginData}
            />
          ) : null}
        </Card>
      </div>
    </>
  );
}

function ComAccPwd(props) {
  const { formKey, setFormKey, setStep, setLoginData } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    username: "",
    password: "",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      username: yup
        .string()
        // .matches(system_email, {
        //   message: "電子郵件格式錯誤(ex:abc@gmail.com)",
        //   excludeEmptyString: true,
        // })
        .required("必填"),
      password: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    SystemAPI.login({ data: submitData }).then((result) => {
      let { Response, Data, message } = result;

      if (Response === 1) {
        result.now = moment().format("YYYY/MM/DD HH:mm:ss");

        reset();
        setFormKey(new Date());
        dispatch(
          setUser({
            ...user.userDetail,
            ...result,
          })
        );

        navigate("/back/inventory/gross");
        // setLoginData(Data);
        // setStep(1);
      } else if (Response === 401) {
        dispatch(
          setToast({
            severity: "error",
            summary: "登入失敗",
            detail: "帳密錯誤",
          })
        );
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "登入失敗",
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <form
        key={formKey}
        onSubmit={handleSubmit(onSubmit)}
        className="formgrid grid text-base"
      >
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors.username },
              "col-3 text-right mb-0 pr-0"
            )}
          >
            Account
          </label>
          <div className="col-9 pr-0">
            <FormInputtext setting={setting} data={{ name: "username" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors.password },
              "col-3 text-right mb-0 pr-0"
            )}
          >
            Password
          </label>
          <div className="col-9 pr-0">
            <FormInputpassword setting={setting} data={{ name: "password" }} />
          </div>
        </div>

        <div className="flex align-items-center col-12">
          <Button
            type="submit"
            label="Sign In"
            className="p-button-primary w-full"
          />
        </div>

        {/* <div className="flex align-items-center col-12 mt-3">
          <Link to="/register">Register</Link>
          <Link className="ml-auto" to="/forgotpwd">
            Forgot Password
          </Link>
        </div> */}
      </form>
    </>
  );
}

function ComSeller(props) {
  const { formKey, setFormKey, setStep, optionData, setOptionData, loginData } =
    props;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    SellerId: null,
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      SellerId: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    SystemAPI.seller({ data: submitData }).then((result) => {
      let { Code, Data, message } = result;

      if (Code === 100) {
        reset();
        setFormKey(new Date());
        dispatch(
          setUser({
            ...user.userDetail,
            seller: Data,
          })
        );

        navigate("/back/inventory/gross");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "登入失敗",
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  useEffect(() => {
    let seller = [];
    if (loginData.U.CCS && Object.keys(loginData.U.CCS).length > 0)
      seller.push(loginData.U.CCS);
    if (loginData.U.SCS && loginData.U.SCS.length > 0)
      seller = seller.concat(loginData.U.SCS);

    let temp = formatOptions(
      {
        seller: seller,
      },
      { uniqueKey: "ASId" }
    );

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, [loginData]);

  return (
    <>
      <form
        key={formKey}
        onSubmit={handleSubmit(onSubmit)}
        className="formgrid grid text-base"
      >
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors.SellerId },
              "col-3 text-right mb-0 pr-0"
            )}
          >
            Seller ID
          </label>
          <div className="col-9 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "SellerId",
                options: optionData.seller ?? [],
                optionLabel: "CName",
                optionValue: "ASId",
              }}
            />
          </div>
        </div>

        <div className="flex align-items-center col-12">
          <Button
            type="button"
            label="Back"
            className="p-button-outlined p-button-secondary w-full mr-2"
            onClick={() => setStep(0)}
          />
          <Button
            type="submit"
            label="OK"
            className="p-button-primary w-full"
          />
        </div>
      </form>
    </>
  );
}
