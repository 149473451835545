export default {
  getGross: "BackEnd/getGross",
  getWarehouseStock: "BackEnd/getWarehouseStock",
  getInventoryList: "BackEnd/getInventoryList",
  updateInventorySingle: "BackEnd/updateInventoryData",
  updateInventoryMulti: "BackEnd/batchUpdateInventoryData",
  splitInventory: "BackEnd/splitInventory",
  revokeSplitInventory: "BackEnd/revokeSplitInventory",
  checkShipmentName: "BackEnd/checkShipmentName",
  confirmToShip: "BackEnd/confirmToShip",
  getReceive: "BackEnd/getReceiveData",
  setReceive: "BackEnd/receive",
  backReceive: "BackEnd/backReceive",
};
