import { useEffect, useState } from "react";

import ComCss from "./com.module.scss";

import { BreadCrumb } from "primereact/breadcrumb";
import { classNames } from "primereact/utils";

export default function ModuleBreadCrumb(props) {
  const { className, crumbItem = [] } = props;
  const [model, setModel] = useState([]);

  const textTemplate = (item, options) => {
    return (
      <div className="p-menuitem-link">
        <span className="p-menuitem-icon"></span>
        <span className="p-menuitem-text">{item.label}</span>
      </div>
    );
  };

  const linkTemplate = (item, options) => {
    return (
      <a className="p-menuitem-link" href={item.url}>
        <span className="p-menuitem-icon"></span>
        <span className="p-menuitem-text">{item.label}</span>
      </a>
    );
  };

  useEffect(() => {
    let temp = crumbItem.map((item) => ({
      ...item,
      template: item.url ? linkTemplate : textTemplate,
    }));
    setModel(temp);
  }, [crumbItem]);

  return (
    <>
      <BreadCrumb
        className={classNames(className)}
        model={model}
        home={{
          icon: "pi pi-home",
        }}
      />
    </>
  );
}
