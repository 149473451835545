import { useSelector, useDispatch } from "react-redux";
import { setBackLayout } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

export default function BackTopbar(props) {
  let { LayoutCss } = props;
  const system = useSelector((state) => state.system);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 登出
  const logout = () => {
    // 清除 user 資料
    dispatch(setUser(null));
    // 導向登入畫面
    navigate("/login");
  };

  return (
    <>
      <div className={`${LayoutCss["layout-topbar"]}`}>
        <Button
          className="p-button-sm w-auto px-2 py-1"
          icon="pi pi-bars"
          onClick={() =>
            dispatch(
              setBackLayout({
                isSideShow: !system.backLayout.isSideShow,
              })
            )
          }
        />
        <span className={`${LayoutCss["layout-title"]}`}>
          Hello! {user.userDetail?.username}
        </span>

        {process.env.REACT_APP_MODE === "development" && (
          <div className="inline-block text-center text-xl text-white bg-red-500 font-bold mt-1 px-2 py-0 ml-3">
            測試站
          </div>
        )}

        <Button
          className="p-button-secondary p-button-text p-button-sm w-auto text-white px-3 py-1 ml-auto"
          label="登出"
          onClick={() => logout()}
        />
      </div>
    </>
  );
}
