export default {
  // importOrder-unshipped
  getUnshippedOrderList: "BackEnd/getUnshippedOrderList",
  getUnshippedOrderDetails: "BackEnd/getUnshippedOrderDetails",
  setDailyUnshippedImportData: "BackEnd/setDailyUnshippedImportData",
  updateUnshippedOrderSingle: "BackEnd/updateUnshippedOrderData",
  updateUnshippedOrderMulti: "BackEnd/batchUpdateUnshippedOrderData",
  copyDailyUnshippedOrderData: "BackEnd/copyDailyUnshippedOrderData",

  // importOrder-df
  getDFOrderList: "BackEnd/getDFOrderList",
  getDFOrderDetails: "BackEnd/getDFOrderDetails",
  setDailyDFImportData: "BackEnd/setDailyDFImportData",
  updateDFOrderSingle: "BackEnd/updateDFOrderData",
  updateDFOrderMulti: "BackEnd/batchUpdateDFOrderData",
  copyDailyDFOrderData: "BackEnd/copyDailyDFOrderData",

  // importOrder-new
  getNewOrderList: "BackEnd/getNewOrderList",
  getNewOrderDetails: "BackEnd/getNewOrderDetails",
  setDailyNewOrderImportData: "BackEnd/setDailyNewOrderImportData",
  updateNewOrderSingle: "BackEnd/updateNewOrderData",
  updateNewOrderMulti: "BackEnd/batchUpdateNewOrderData",

  // importOrder-wayfair
  getWayFairOrderList: "BackEnd/getWayFairOrderList",
  getWayFairOrderDetails: "BackEnd/getWayFairOrderDetails",
  setDailyWayFairImportData: "BackEnd/setDailyWayFairImportData",
  updateWayFairOrderSingle: "BackEnd/updateWayFairOrderData",
  updateWayFairOrderMulti: "BackEnd/batchUpdateWayFairOrderData",
  copyDailyWayFairOrderData: "BackEnd/copyDailyWayFairOrderData",

  // importOrder-complete
  getTodayOrderComplete: "BackEnd/getTodayOrderComplete",
  setOrderComplete: "BackEnd/setOrderComplete",

  // newOrder
  getTotalOrderData: "BackEnd/getTotalOrderData",
  updateTotalOrderData: "BackEnd/updateTotalOrderData",
  updateDailyInventory: "BackEnd/updateDailyInventory",

  // overview
  setWayFairRemittance: "BackEnd/setWayFairRemittance",
  updateShippedFee: "BackEnd/updateShippedFee",
  updateTacking: "BackEnd/updateTacking",

  // cj
  getCJFileData: "BackEnd/getCJFileData",
  getCJFedexData: "BackEnd/getCJFedexData",
  getFedexData: "BackEnd/getFedexData",
};
