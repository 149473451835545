import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SystemAPI } from "../../axios/api";

import { FormInputtext } from "../../components/formElement/index";
import { system_email } from "../../service/system";

import LoginCss from "../../styles/components/login.module.scss";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function SystemForgotPwd(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 用於控制表單刷新
  const [formKey, setFormKey] = useState(new Date());
  // 表單欄位預設值
  const defaultValues = {
    Email: "",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      Email: yup
        .string()
        .matches(system_email, {
          message: "電子郵件格式錯誤(ex:abc@gmail.com)",
          excludeEmptyString: true,
        })
        .required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    delete submitData.re_password;

    SystemAPI.forgotPwd({ data: submitData }).then((result) => {
      let { Code, Data, message } = result;

      if (Code === 100) {
        dispatch(
          setToast({
            severity: "success",
            summary: "重置密碼成功",
            detail: "",
          })
        );
        reset(defaultValues);
      } else if (Code === 200) {
        dispatch(
          setToast({
            severity: "error",
            summary: "重置密碼失敗",
            detail: "",
          })
        );
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <div
        className={`${LoginCss["login-wrap"]} flex flex-column align-items-center justify-content-center w-full h-full`}
      >
        <Card
          className={`${LoginCss["login-inner-wrap"]} w-10 sm:w-6 max-w-30rem text-2xl px-3`}
        >
          <h1 className="text-center mt-0 mb-6">Merchant</h1>
          <form
            key={formKey}
            onSubmit={handleSubmit(onSubmit)}
            className="formgrid grid text-base"
          >
            <div className="flex align-items-center field col-12">
              <label
                className={classNames(
                  { "p-error": errors.Email },
                  "col-2 text-right mb-0 pr-0"
                )}
              >
                Email
              </label>
              <div className="col-10 pr-0">
                <FormInputtext setting={setting} data={{ name: "Email" }} />
              </div>
            </div>

            <div className="flex align-items-center col-12">
              <Button
                type="button"
                label="Back"
                className="p-button-outlined p-button-secondary w-full mr-2"
                onClick={() => navigate("/login")}
              />
              <Button
                type="submit"
                label="OK"
                className="p-button-primary w-full"
              />
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}
