import request from "../request";
import Url from "../url/common";

const getWarehouse = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getWarehouse + path, data, options);
};
const getWarehouseDetail = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.getWarehouseDetail + path, data, options);
};
const setWarehouse = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setWarehouse + path, data, options);
};
const updateWarehouse = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateWarehouse + path, data, options);
};

const getCarrier = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getCarrier + path, data, options);
};
const getCarrierDetail = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.getCarrierDetail + path, data, options);
};
const setCarrier = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setCarrier + path, data, options);
};
const updateCarrier = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateCarrier + path, data, options);
};

const getGoogleSheetDumpTable = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getGoogleSheetDumpTable + path, data, options);
};
const setGoogleSheetDumpTable = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setGoogleSheetDumpTable + path, data, options);
};
const updateGoogleSheetDumpTable = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateGoogleSheetDumpTable + path, data, options);
};
const deleteGoogleSheetDumpTable = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteGoogleSheetDumpTable + path, data, options);
};

const getGoogleSheetDumpSchedule = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getGoogleSheetDumpSchedule + path, data, options);
};
const setGoogleSheetDumpSchedule = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setGoogleSheetDumpSchedule + path, data, options);
};
const updateGoogleSheetDumpSchedule = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.updateGoogleSheetDumpSchedule + path, data, options);
};
const deleteGoogleSheetDumpSchedule = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.deleteGoogleSheetDumpSchedule + path, data, options);
};

export default {
  getWarehouse,
  getWarehouseDetail,
  setWarehouse,
  updateWarehouse,

  getCarrier,
  getCarrierDetail,
  setCarrier,
  updateCarrier,

  getGoogleSheetDumpTable,
  setGoogleSheetDumpTable,
  updateGoogleSheetDumpTable,
  deleteGoogleSheetDumpTable,

  getGoogleSheetDumpSchedule,
  setGoogleSheetDumpSchedule,
  updateGoogleSheetDumpSchedule,
  deleteGoogleSheetDumpSchedule,
};
