import request from "../request";
import Url from "../url/order";

const vcOrderImport = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.vcOrderImport + path, data, options);
};
const getVcOrderList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getVcOrderList + path, data, options);
};
const getVcOrderDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getVcOrderDetails + path, data, options);
};
const getVcOrderCanUseInventory = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getVcOrderCanUseInventory + path, data, options);
};
const updateVcOrderMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateVcOrderMulti + path, data, options);
};
const updateVcOrderSingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateVcOrderSingle + path, data, options);
};
const updateVcInventory = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateVcInventory + path, data, options);
};
const confirmVcOrderLineItems = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.confirmVcOrderLineItems + path, data, options);
};
const backVcOrderLineItemsToNew = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.backVcOrderLineItemsToNew + path, data, options);
};
const setVcShippingData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setVcShippingData + path, data, options);
};
const updateVcShippingData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateVcShippingData + path, data, options);
};
const backVcShippingToRserved = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.backVcShippingToRserved + path, data, options);
};
const getShippingPlan = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getShippingPlan + path, data, options);
};
const uploadPODFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.uploadPODFile + path, data, options);
};
const getPODFile = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getPODFile + path, data, options);
};

export default {
  vcOrderImport,
  getVcOrderList,
  getVcOrderDetails,
  getVcOrderCanUseInventory,
  updateVcOrderMulti,
  updateVcOrderSingle,
  updateVcInventory,
  confirmVcOrderLineItems,
  backVcOrderLineItemsToNew,
  setVcShippingData,
  updateVcShippingData,
  backVcShippingToRserved,
  getShippingPlan,
  uploadPODFile,
  getPODFile,
};
