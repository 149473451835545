export const receiveImportColumns = {
  sku: {
    name: "Sku",
    value: "Sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 1,
  },
  pallet_no: {
    name: "PalletNo",
    value: "PalletNo",
    apiKey: "pallet_no",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 2,
  },
  qty: {
    name: "Qty",
    value: "Qty",
    apiKey: "qty",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 3,
  },
  notes: {
    name: "Notes",
    value: "Notes",
    apiKey: "notes",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 4,
  },
};

export const receiveExportColumns = {
  name: {
    name: "Name",
    value: "Name",
    apiKey: "name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 1,
  },
  warehouse_id: {
    name: "WH ID",
    value: "WH ID",
    apiKey: "warehouse_id",
    uiType: "select",
    options: "warehouse",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 2,
  },
  reciveddatatime: {
    name: "Date",
    value: "Date",
    apiKey: "reciveddatatime",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "MM/DD/YYYY HH:mm:ss",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 3,
  },
};
