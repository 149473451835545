export const palletExportColumns = {
  received_date: {
    name: "Receive Date",
    value: "Receive Date",
    apiKey: "received_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "MM/DD/YYYY",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 1,
  },
  warehouse_id: {
    name: "WH ID",
    value: "WH ID",
    apiKey: "warehouse_id",
    uiType: "select",
    options: "warehouse",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 60,
    order: 2,
  },
  pallet_no: {
    name: "Pallet No.",
    value: "Pallet No.",
    apiKey: "pallet_no",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 3,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 130,
    order: 4,
  },
  qty: {
    name: "Qty",
    value: "Qty",
    apiKey: "qty",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 60,
    order: 5,
  },
  notes: {
    name: "Notes",
    value: "Notes",
    apiKey: "notes",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: true,
    width: 150,
    order: 6,
  },
  pallet_type: {
    name: "Type",
    value: "Type",
    apiKey: "pallet_type",
    uiType: "select",
    options: "pallet_type",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 55,
    order: 7,
  },
  shipment_name: {
    name: "Shipment Name",
    value: "Shipment Name",
    apiKey: "shipment_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: true,
    width: 170,
    order: 8,
  },
  shipment_id: {
    name: "Shipment ID",
    value: "Shipment ID",
    apiKey: "shipment_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: true,
    width: 130,
    order: 9,
  },
  plan_date: {
    name: "Plan Date",
    value: "Plan Date",
    apiKey: "plan_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "MM/DD/YYYY",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 90,
    order: 10,
  },
};
