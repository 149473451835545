import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonAPI, DailyAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";

import ModuleFileTransfer from "../../components/fileTransfer";
import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ViewPallet from "../../components/module/pallet/viewPallet";
import EditPallet from "../../components/module/pallet/editPallet";
import { newOrderExportColumns } from "../../service/columns/daily/newOrder";
import { FormDropdown, FormCheckbox } from "../../components/formElement";
import { system_table } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  cleanObj,
  checkFormUpdate,
  checkColumnUpdate,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../service/table";
import { system_dropdown } from "../../service/system";
import {
  option_platform,
  option_shipping_status,
  option_carriers,
} from "../../service/option";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function DailyNewOrder(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Daily" }, { label: "新訂單" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    typeList: null,
    isshippedList: null,
    isreceived: null,
    warehouseList: null,
    keywords: "",
    isrevoked: 0,
    isconfirm: 0,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});
  // 是否完成
  const [confirmChecked, setConfirmChecked] = useState(false);
  // 匯入匯出 模組資料
  const [moduleData, setModuleData] = useState({
    importTemplate: {},
    exportTemplate: {},
  });
  // pallet pop
  const pallet_op = useRef(null);
  const [overlayData, setOverlayData] = useState({});
  // 表單設定
  const defaultValues = {
    checkAll_isconfirm: false,
    checkAll_isshipped: false,
    data: [],
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        // data: yup.array().of(
        //   yup.object().shape({
        //     // title: yup.string().required("必填"),
        //     // content: yup.string().required("必填"),
        //     // img_url: yup.string().required("必填"),
        //   })
        // ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 開啟 overlay
  const openOverlay = (e, data) => {
    setOverlayData(data);
    setTimeout(() => {
      pallet_op.current.toggle(e);
    }, 100);
  };

  useAbortedEffect((signal) => {
    CommonAPI.getWarehouse({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let temp = formatOptions(
          {
            warehouse: data,
          },
          { uniqueKey: "id", showLabel: "code" }
        );
        formatOtherOptions(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  }, []);

  const formatOtherOptions = (data) => {
    let temp = formatOptions(
      {
        platform: option_platform,
        shipping_status: option_shipping_status,
        carriers: option_carriers,
      },
      { uniqueKey: "value", showLabel: "label" }
    );
    setOptionData({
      ...data,
      ...temp,
    });
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setConfirmChecked(false);
    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/daily/newOrder"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      if (result.params.typeList) {
        result.params.typeList = result.params.typeList
          .split(",")
          .map((item) => Number(item));
      }

      let isshippedList = [];
      if (result.params.isshippedList !== undefined)
        isshippedList.push(Number(result.params.isshippedList == 0 ? 0 : 1));
      // else isshippedList = isshippedList.concat([0, 1]);

      if (result.params.isreceived !== undefined)
        isshippedList.push(Number(result.params.isreceived == 0 ? 2 : 3));
      // else isshippedList = isshippedList.concat([2, 3]);

      result.params.isshippedList = isshippedList;

      if (result.params.warehouseList) {
        result.params.warehouseList = result.params.warehouseList
          .split(",")
          .map((item) => Number(item));
      }

      setTimeout(() => {
        setTbRows(result.params.size);
        setFilterData((state) => ({
          ...state,
          ...result.params,
        }));
      }, 500);
    }

    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      getListData({ type: "list", signal });
    },
    [reload]
  );

  const getListData = ({ type = "list", signal }) => {
    let params = {
      ...search,
      page: search.isTotal ? -1 : search.page,
      size: search.isTotal ? -1 : search.size,
    };

    if (search.typeList) {
      params.typeList = search.typeList.join(",");
    }

    if (search.isshippedList) {
      let notShipped = search.isshippedList.indexOf(0) !== -1,
        shipped = search.isshippedList.indexOf(1) !== -1,
        notReceived = search.isshippedList.indexOf(2) !== -1,
        received = search.isshippedList.indexOf(3) !== -1;

      if (notShipped && shipped) params.isshippedList = null;
      else params.isshippedList = notShipped ? 0 : shipped ? 1 : null;

      if (notReceived && received) params.isreceived = null;
      else params.isreceived = notReceived ? 0 : received ? 1 : null;
    }

    if (search.warehouseList) {
      params.warehouseList = search.warehouseList.join(",");
    }

    params = cleanObj(params);

    if (type === "list") {
      setPageSetting({ params, page: "/daily/newOrder" });
    } else if (type === "export") {
      params.page = -1;
      params.size = -1;
    }
    delete params.isTotal;

    return DailyAPI.getTotalOrderData({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        if (type === "list") {
          data.forEach((item) => {
            item.isconfirm = !item.isconfirm ? false : true;
            item.isreceived = !item.isreceived ? false : true;
            item.isinsurance = !item.isinsurance ? false : true;
            item.issign = !item.issign ? false : true;
            item.isshipped = !item.isshipped ? false : true;

            // pallet 數量是否足夠
            let daily_inventoryList_sum = item.daily_inventoryList.reduce(
              (pre, cur) => pre + cur.inventoryData.qty,
              0
            );
            let inventoryList_sum = item.inventoryList.reduce(
              (pre, cur) => pre + cur.qty,
              0
            );
            item.pallet_enough =
              item.daily_inventoryList.length > 0
                ? daily_inventoryList_sum === item.qty
                : inventoryList_sum === item.qty;
          });

          let checkAll_isshipped = !data.some((item) => !item.isshipped);

          if (search.isTotal) {
            setTbRows(total);
          }
          console.log(data);
          setTbData(data);
          setTbTotal(total);
          reset({
            ...defaultValues,
            checkAll_isshipped: checkAll_isshipped,
            data: data,
          });
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          return data;
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  // 取得回傳的模板資料
  const getTemplate = ({ importTemplate = [], exportTemplate = [] } = {}) => {
    setModuleData({ importTemplate, exportTemplate });
  };

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(moduleData.exportTemplate.settings ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "single",
      saveFun: saveColumnAction,
      activeTemplate: entries,
      defaultTemplate: newOrderExportColumns,
      formSetting: setting,
      optionData: optionData,
      tbRows,
    });

    tempColumns.unshift({
      field: "isconfirm",
      header: () => (
        <FormCheckbox
          className="m-0"
          setting={setting}
          data={{
            name: "checkAll_isconfirm",
            label: "完成",
            type: "boolean",
            value: true,
            changeFun: (e) => checkChange("all", "isconfirm", e),
          }}
        />
      ),
      className: "",
      style: {
        width: 65,
        minWidth: 65,
      },
      body: (data, { rowIndex }) => (
        <>
          {data.is_rejected !== 1 && data.status === 1 ? (
            <FormCheckbox
              className="m-0"
              setting={setting}
              data={{
                name: `data[${rowIndex % tbRows}].isconfirm`,
                label: "",
                type: "boolean",
                value: true,
                changeFun: (e) => checkChange("single", "isconfirm", e),
                disabled:
                  !data.sr ||
                  data.daily_inventoryList.length === 0 ||
                  !data.pallet_enough,
              }}
            />
          ) : null}
        </>
      ),
    });

    let temp = [];
    tempColumns.forEach((item) => {
      if (item.field === "isshipped") {
        let label = item.header;
        item.header = () => (
          <FormCheckbox
            className="m-0"
            setting={setting}
            data={{
              name: "checkAll_isshipped",
              label: label,
              type: "boolean",
              value: true,
              changeFun: (e) => checkChange("all", item.field, e),
            }}
          />
        );
        item.body = (data, { rowIndex }) => (
          <>
            <FormCheckbox
              className="m-0"
              setting={setting}
              data={{
                name: `data[${rowIndex % tbRows}][${item.field}]`,
                label: "",
                type: "boolean",
                value: true,
                changeFun: (e) => checkChange("single", item.field, e),
                saveFun: () =>
                  saveColumnAction({
                    rowIndex: rowIndex % tbRows,
                    apiKey: item.field,
                  }),
              }}
            />
          </>
        );
      } else if (item.field === "warehouse") {
        item.body = (data, { rowIndex }) => (
          <>
            <FormDropdown
              className="p-sm w-full"
              panelClassName="p-sm"
              setting={setting}
              data={{
                name: `data[${rowIndex % tbRows}].${item.field}`,
                options: optionData.warehouse,
                optionLabel: "showLabel",
                optionValue: "showValue",
                saveFun: () =>
                  saveColumnAction({
                    rowIndex: rowIndex % tbRows,
                    apiKey: item.field,
                  }),
                showClear: false,
                disabled: data.daily_inventoryList.length === 0,
              }}
            />
          </>
        );
      } else if (item.field === "daily_inventoryList") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 1 || data.status === 2) &&
            data.is_rejected !== 1 ? (
              <>
                {!data[item.field] || data[item.field].length === 0 ? (
                  <Button
                    type="button"
                    label="Please Select"
                    className="p-button-secondary p-button-sm p-button-outlined bg-white border-dashed w-full text-base white-space-nowrap py-1 py-0"
                    onClick={(e) =>
                      openOverlay(e, { ...data, rowIndex: rowIndex % tbRows })
                    }
                  />
                ) : (
                  <ViewPallet
                    className="cursor-pointer"
                    chooseData={data[item.field]}
                    optionData={optionData}
                    clickFun={(e) =>
                      openOverlay(e, { ...data, rowIndex: rowIndex % tbRows })
                    }
                  />
                )}
              </>
            ) : (
              <ViewPallet
                inventoryData={data.inventoryList}
                chooseData={data[item.field]}
                optionData={optionData}
              />
            )}
          </>
        );
      }
      if (item.field !== "fnsku") {
        temp.push(item);
      }
    });
    setTbColumns(temp);
  }, [moduleData, setting, tbData, tbRows]);

  // 設定列表欄位驗證
  useEffect(() => {
    let entries = Object.values(moduleData.exportTemplate.settings ?? {});
    if (entries.length === 0 || tbData.length) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempSchemas = createSchema({
      type: "list",
      activeTemplate: entries,
      defaultTemplate: newOrderExportColumns,
      yup: yup,
    });

    setSchema(
      yup
        .object({
          data: yup.array().of(yup.object().shape(tempSchemas)),
        })
        .required()
    );
  }, [moduleData, tbData]);

  // 勾選-切換
  const checkChange = (type, fieldName, e) => {
    let tempData = getValues();
    if (!tempData.data || tempData.data.length === 0) return;

    let total = 0, // 可勾選數量
      checked = 0; // 勾選數量
    if (type === "all") {
      tempData.data.forEach((item) => {
        if (
          fieldName === "isshipped" ||
          (item.sr &&
            item.daily_inventoryList?.length > 0 &&
            item.pallet_enough)
        ) {
          item[fieldName] = tempData[`checkAll_${fieldName}`];

          if (item[fieldName]) checked++;
        }
      });
      reset(tempData);

      if (fieldName === "isshipped") {
        dispatch(setProgress(true));

        let jsonData = tempData.data.map((item) => ({
          daily_id: item.daily_id,
          type: item.type,
          [fieldName]: item[fieldName] ? 1 : 0,
        }));

        saveMultiAction({ jsonData: { data: jsonData }, msg: "變更" });
      }
    } else {
      tempData.data.forEach((item) => {
        if (
          fieldName === "isshipped" ||
          (item.sr &&
            item.daily_inventoryList?.length > 0 &&
            item.pallet_enough)
        ) {
          total++;
          if (item[fieldName]) checked++;
        }
      });

      if (tempData[`checkAll_${fieldName}`] !== !(total !== checked)) {
        tempData[`checkAll_${fieldName}`] = !(total !== checked);
        reset(tempData);
      }
    }

    if (fieldName === "isconfirm") {
      setConfirmChecked(checked > 0);
    }
  };

  // 整理單筆資料
  const saveColumnAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      daily_id: data.daily_id,
      type: data.type,
      [apiKey]: data[apiKey],
    };

    if (
      apiKey === "isshipped" ||
      apiKey === "isreceived" ||
      apiKey === "isinsurance" ||
      apiKey === "issign"
    ) {
      jsonData[apiKey] = data[apiKey] ? 1 : 0;
    }

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    dispatch(setProgress(true));

    saveMultiAction({ jsonData: { data: [jsonData] }, msg: "變更" });
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let updateData = [];
    tbData.forEach((item, index) => {
      let data = submitData.data[index];
      let update_flag = checkFormUpdate({
        newData: data,
        oldData: item,
        exclude: ["wayfair_remittance", "daily_inventoryList", "inventoryList"],
      });

      if (update_flag) {
        if (data.sr) data.sr = data.sr.toUpperCase();

        let temp = {
          daily_id: item.daily_id,
          type: item.type,
          ...formatTemplateColumns({
            type: "export",
            template: newOrderExportColumns,
            data: [data],
          })[0],
        };

        temp.isconfirm = temp.isconfirm ? 1 : 0;
        temp.isreceived = temp.isreceived ? 1 : 0;
        temp.isinsurance = temp.isinsurance ? 1 : 0;
        temp.issign = temp.issign ? 1 : 0;
        temp.isshipped = temp.isshipped ? 1 : 0;

        delete temp.daily_inventoryList;

        updateData.push(temp);
      }
    });

    if (updateData.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    saveMultiAction({ jsonData: { data: updateData }, msg: "變更" });
  };

  const saveMultiAction = ({ jsonData, msg = "" }) => {
    DailyAPI.updateTotalOrderData({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        // dispatch(
        //   setToast({
        //     severity: "success",
        //     summary: `${msg}成功`,
        //     detail: "",
        //   })
        // );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 完成
  const completeAction = () => {
    let submitData = getValues("data"),
      updateData = [];
    submitData.forEach((item) => {
      if (item.isconfirm)
        updateData.push({
          daily_id: item.daily_id,
          type: item.type,
          isconfirm: 1,
        });
    });

    saveMultiAction({ jsonData: { data: updateData }, msg: "變更" });
  };

  // 重整 pallet 資料
  const reloadPallet = ({ parent_data = [], child_data = [] }) => {
    pallet_op.current.hide();

    let submitData = getValues("data"),
      rowIndex = overlayData.rowIndex;

    submitData[rowIndex].daily_inventoryList = child_data;
    submitData.forEach((item, index) => {
      item.inventoryList.forEach((old_inventory, old_index) => {
        parent_data.forEach((new_inventory) => {
          if (old_inventory.id === new_inventory.id) {
            submitData[index].inventoryList[old_index].qty = new_inventory.qty;
          }
        });
      });
    });
    submitData[rowIndex].pallet_enough = child_data.length !== 0;

    setTbData(submitData);
    reset({ ...getValues(), data: submitData });

    dispatch(setProgress(false));
  };

  const tbCellClass = (value, cellData) => {
    let field = cellData.column.props.field,
      rowData = cellData.rowData;

    return {
      "bg-red-200": field === "daily_inventoryList" && !rowData.pallet_enough,
    };
  };

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-4 md:col-6 pr-0">
          <Button
            className="p-button-primary ml-auto"
            type="button"
            label="Complete Order"
            onClick={() => completeAction()}
            disabled={!confirmChecked}
          />
        </div>
        <div className="col-12 sm:col-8 md:col-6 pr-0">
          <ModuleFileTransfer
            setting={{ importFlag: false, exportFlag: true }}
            moduleType="newOrders"
            exportColumns={newOrderExportColumns}
            exportData={tbData}
            getTemplate={getTemplate}
            getAllData={getListData}
          />
        </div>
        <div className="col-12 border-top-1 border-300 p-0"></div>
        <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
          <MultiSelect
            className="w-full"
            name="typeList"
            value={filterData.typeList}
            options={optionData.platform}
            onChange={(e) => changeFilter(e)}
            optionValue="value"
            optionLabel="label"
            placeholder="選擇平台"
            display="chip"
          />
        </div>
        <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
          <MultiSelect
            className="w-full"
            name="isshippedList"
            value={filterData.isshippedList}
            options={optionData.shipping_status}
            onChange={(e) => changeFilter(e)}
            optionValue="value"
            optionLabel="label"
            placeholder="選擇貨物狀態"
            display="chip"
          />
        </div>
        <div className="col-12 sm:col-4 md:col-3 lg:col-4 pr-0">
          <MultiSelect
            className="w-full"
            name="warehouseList"
            value={filterData.warehouseList}
            options={optionData.warehouse}
            onChange={(e) => changeFilter(e)}
            optionValue="id"
            optionLabel="code"
            placeholder="選擇倉庫"
            display="chip"
          />
        </div>
        <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
          <InputText
            name="keywords"
            className="w-full"
            value={filterData.keywords}
            onChange={(e) => changeFilter(e)}
            placeholder="Keyword"
          />
        </div>
        <div className="flex col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataTable
            value={tbData}
            className={classNames("size-sm", {
              "table-empty": tbData.length === 0,
            })}
            size="normal"
            paginator
            paginatorTemplate={ModuleTablePaginator}
            currentPageReportTemplate="Total {totalRecords} items"
            cellClassName={tbCellClass}
            lazy
            first={tbFirstIndex}
            totalRecords={tbTotal}
            onPage={onPage}
            rows={tbRows}
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            scrollDirection="both"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column key={`${item.field}_${index}`} {...item} />
            ))}
          </DataTable>

          {tbData.length === 0 ? (
            <ModuleTableEmpty />
          ) : (
            // <div className="flex mt-2">
            //   <Button className="px-3 ml-auto" type="submit" label="Save" />
            // </div>
            <></>
          )}
        </form>
      </div>

      <OverlayPanel ref={pallet_op} style={{ width: "350px" }}>
        <EditPallet
          palletType="newOrders"
          palletData={{
            daily_id: overlayData.daily_id,
            type: overlayData.type,
            inventoryData: overlayData.inventoryList,
            chooseData: overlayData.daily_inventoryList,
            quantity_requested: overlayData.qty,
          }}
          optionData={optionData}
          reloadFun={({ parent_data, child_data }) => {
            // reloadPallet({ parent_data, child_data })
            pallet_op.current.hide();
            setReload((state) => ({ ...state, list: true }));
          }}
        />
      </OverlayPanel>
    </>
  );
}
