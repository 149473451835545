import { useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "./redux/slice/system";
import { setUser } from "./redux/slice/user";

import { system_toast } from "./service/system";

import BackLayoutMain from "./components/backLayout/main"; // 後台 - 介面外框
import SystemLogin from "./page/system/login"; // 登入
import SystemRegister from "./page/system/register"; // 註冊
import SystemForgotPwd from "./page/system/forgotPwd"; // 忘記密碼
// inventory
import InventoryMain from "./page/inventory";
import InventoryGross from "./page/inventory/gross";
import InventoryWarehouse from "./page/inventory/warehouse";
import InventoryPallet from "./page/inventory/pallet";
import InventoryReceive from "./page/inventory/receive";
// order
import OrderMain from "./page/order";
import OrderVc from "./page/order/vc";
// daily
import DailyMain from "./page/daily";
import DailyImportOrder from "./page/daily/importOrder";
import DailyNewOrder from "./page/daily/newOrder";
import DailyOverview from "./page/daily/overview";
import DailyCJ from "./page/daily/cj";
// common
import CommonMain from "./page/common";
import CommonWarehouse from "./page/common/warehouse";
import CommonCarrier from "./page/common/carrier";
import CommonGoogleTable from "./page/common/googleTable";
import CommonGoogleDump from "./page/common/googleDump";

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

export default function App() {
  const system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  const toast = useRef(null);

  // 控制 toast 顯示內容
  useEffect(() => {
    if (!system.toast) {
      return;
    }

    toast.current.show({
      ...system.toast,
    });

    // toast 顯示結束後，將全域 toast 資料清除
    setTimeout(() => {
      dispatch(setToast(null));
    }, system.toast.life ?? system_toast.life);
  }, [system.toast]);

  // 偵測是否有 localStorage，有則寫入 store
  useEffect(() => {
    let user_local = localStorage.getItem("user");

    if (user_local) {
      user_local = JSON.parse(user_local);
      dispatch(setUser(user_local));
    }
  }, []);

  return (
    <>
      {system.isProgress && (
        <div
          className="fixed flex align-items-center justify-content-center w-full h-full bg-black-alpha-20"
          style={{ zIndex: 1199 }}
        >
          <ProgressSpinner />
        </div>
      )}

      <Toast ref={toast} />

      <CreatRoute />
    </>
  );
}

function CreatRoute() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<SystemLogin />} />
          <Route path="/login" element={<SystemLogin />} />
          <Route path="/register" element={<SystemRegister />} />
          <Route path="/forgotpwd" element={<SystemForgotPwd />} />

          {/* 後台相關頁面 */}
          <Route path="/back" element={<BackLayoutMain />}>
            <Route path="inventory" element={<InventoryMain />}>
              <Route path="gross" element={<InventoryGross />} />
              <Route path="warehouse" element={<InventoryWarehouse />} />
              <Route path="pallet" element={<InventoryPallet />} />
              <Route path="receive" element={<InventoryReceive />} />
            </Route>
            <Route path="order" element={<OrderMain />}>
              <Route path="vc" element={<OrderVc />} />
            </Route>
            <Route path="daily" element={<DailyMain />}>
              <Route path="importOrder" element={<DailyImportOrder />} />
              <Route path="newOrder" element={<DailyNewOrder />} />
              <Route path="overview" element={<DailyOverview />} />
              <Route path="cj" element={<DailyCJ />} />
            </Route>
            <Route path="common" element={<CommonMain />}>
              <Route path="warehouse" element={<CommonWarehouse />} />
              <Route path="carrier" element={<CommonCarrier />} />
              <Route path="googleTable" element={<CommonGoogleTable />} />
              <Route path="googleDump" element={<CommonGoogleDump />} />
            </Route>

            <Route path="*" element={<>無畫面</>} />
          </Route>

          <Route path="*" element={<>無畫面</>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
