export const warehouseExportColumns = {
  name: {
    name: "Name",
    value: "Name",
    apiKey: "name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 70,
    order: 1,
  },
  code: {
    name: "Code",
    value: "Code",
    apiKey: "code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 70,
    order: 2,
  },
  contact_name: {
    name: "Contact Name",
    value: "Contact Name",
    apiKey: "contact_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 3,
  },
  contact_phone: {
    name: "Contact Phone",
    value: "Contact Phone",
    apiKey: "contact_phone",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 4,
  },
  contact_email: {
    name: "Contact Email",
    value: "Contact Email",
    apiKey: "contact_email",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 200,
    order: 5,
  },
  address1: {
    name: "Address1",
    value: "Address1",
    apiKey: "address1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 6,
  },
  address2: {
    name: "	Address2",
    value: "Address2",
    apiKey: "address2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 7,
  },
  // status: {
  //   name: "Status",
  //   value: "Status",
  //   apiKey: "status",
  //   uiType: "select",
  //   options: "open",
  //   optionType: "number",
  //   isShow: true,
  //   checked: true,
  //   listDisabled: false,
  //   listUI: false,
  //   required: true,
  //   width: 100,
  //   order: 8,
  // },
};
