export default {
  vcOrderImport: "BackEnd/vcOrderImport",
  getVcOrderList: "BackEnd/getVcOrderList",
  getVcOrderDetails: "BackEnd/getVcOrderDetails",
  getVcOrderCanUseInventory: "BackEnd/getVcOrderCanUseInventory",
  updateVcOrderMulti: "BackEnd/batchUpdateVcOrderData",
  updateVcOrderSingle: "BackEnd/updateVcOrderData",
  updateVcInventory: "BackEnd/updateVcInventory",
  confirmVcOrderLineItems: "BackEnd/confirmVcOrderLineItems",
  backVcOrderLineItemsToNew: "BackEnd/backVcOrderLineItemsToNew",
  setVcShippingData: "BackEnd/setVcShippingData",
  updateVcShippingData: "BackEnd/updateVcShippingData",
  backVcShippingToRserved: "BackEnd/backVcShippingToRserved",
  getShippingPlan: "BackEnd/getShippingPlan",
  uploadPODFile: "BackEnd/uploadPODFile",
  getPODFile: "BackEnd/getfile",
};
