import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAbortedEffect } from "../hooks";
import { TransferAPI } from "../../axios/api";

import FileTransferTemplate from "./template";
import { FormDropdown } from "../formElement";
import { option_function_type } from "../../service/option";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function FileTransferPopSetting(props) {
  const {
    closePrePop, // 關閉 pop
    setting: {
      importFlag = true, // 是否啟用 匯入
      exportFlag = true, // 是否啟用 匯出
      importDisabled = false, // 是否禁用 匯入
      exportDisabled = false, // 是否禁用 匯出
    },
    moduleType, // 分類
    importColumns, // 匯入欄位
    exportColumns, // 匯出欄位
    activeModule, // 生效的模組
    setPreReload,
  } = props;
  const dispatch = useDispatch();
  // 是否呼叫 API
  const [reload, setReload] = useState({ list: false });
  // 是否顯示彈出視窗
  const [isPop, setIsPop] = useState({
    edit: false, // 編輯模組資料
  });
  // 彈出視窗資料
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  // 現有模組資料
  const [moduleData, setModuleData] = useState({ import: [], export: [] });
  const defaultValues = {
    import: null, // 匯入模組
    export: null, // 匯出模組
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      import: yup.number().nullable(),
      export: yup.number().nullable(),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 設定生效的模組
  useEffect(() => {
    if (!activeModule.activeImport && !activeModule.activeExport) return;

    reset({
      import: activeModule.activeImport,
      export: activeModule.activeExport,
    });
  }, [activeModule]);

  useEffect(() => {
    if (!moduleType) return;

    setReload((state) => ({ ...state, list: true }));
  }, [moduleType]);

  // 搜尋模組資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        function_type: option_function_type[moduleType],
      };

      TransferAPI.getExportImportModuleList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;

        if (Response === 1) {
          let temp = { import: [], export: [] };
          data.forEach((item) => {
            temp[item.type === 1 ? "import" : "export"].push(item);
          });
          setModuleData(temp);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message ?? "編輯失敗",
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    submitData = Object.values(submitData);

    let updateData = [];
    submitData.forEach((item, index) => {
      if (
        item &&
        ((index === 0 && item !== activeModule.activeImport) ||
          (index === 1 && item !== activeModule.activeExport))
      ) {
        updateData.push(item);
      }
    });

    if (updateData.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    saveUse({ submitData: updateData, index: 0, count: updateData.length });
  };

  const saveUse = ({ submitData, index, count }) => {
    let jsonData = {
      id: submitData[index],
      is_use: 1,
    };

    TransferAPI.updateExportImportModule({
      data: jsonData,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        index++;
        if (index < count) {
          saveUse({ submitData, index, count });
        } else {
          setPreReload((state) => ({ ...state, module: true }));
          closePrePop({ type: "setting" });
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "編輯失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="formgrid grid mx-0 my-3"
      >
        {importFlag && (
          <>
            <div className="flex align-items-center field col-12">
              <label
                htmlFor="import"
                className={classNames(
                  { "p-error": errors.import },
                  "col-2 text-right mb-0"
                )}
              >
                Import
              </label>
              <div className="col-6 lg:col-5">
                <FormDropdown
                  setting={setting}
                  data={{
                    name: "import",
                    options: moduleData.import,
                    optionLabel: "name",
                    optionValue: "id",
                    showClear: false,
                    disabled: importDisabled,
                  }}
                />
              </div>
              <div className="col-4 lg:col-5">
                <Button
                  type="button"
                  label="Edit Template"
                  className="p-button-secondary px-3"
                  onClick={() =>
                    openPop({
                      type: "edit",
                      module: "import",
                      defaultColumns: importColumns,
                      existColumns: moduleData.import ?? [],
                      reGetTemplate: () => {
                        setReload((state) => ({
                          ...state,
                          list: true,
                        }));
                        setPreReload((state) => ({ ...state, module: true }));
                      },
                    })
                  }
                  disabled={importDisabled}
                />
              </div>
            </div>
          </>
        )}

        {exportFlag && (
          <>
            <div className="flex align-items-center field col-12">
              <label
                htmlFor="export"
                className={classNames(
                  { "p-error": errors.export },
                  "col-2 text-right mb-0"
                )}
              >
                Export
              </label>
              <div className="col-6 lg:col-5">
                <FormDropdown
                  setting={setting}
                  data={{
                    name: "export",
                    options: moduleData.export,
                    optionLabel: "name",
                    optionValue: "id",
                    showClear: false,
                    disabled: exportDisabled,
                  }}
                />
              </div>
              <div className="col-4 lg:col-5">
                <Button
                  type="button"
                  label="Edit Template"
                  className="p-button-secondary px-3"
                  onClick={() =>
                    openPop({
                      type: "edit",
                      module: "export",
                      defaultColumns: exportColumns,
                      existColumns: moduleData.export ?? [],
                      reGetTemplate: () => {
                        setReload((state) => ({
                          ...state,
                          list: true,
                        }));
                        setPreReload((state) => ({ ...state, module: true }));
                      },
                    })
                  }
                  disabled={exportDisabled}
                />
              </div>
            </div>
          </>
        )}

        <div className="field col-12 text-right border-top-1 border-300 mb-0 pt-3 pr-3">
          <Button
            type="button"
            label="Close"
            className="p-button-secondary p-button-outlined ml-auto px-3"
            onClick={() => closePrePop({ type: "setting" })}
          />
          {(importFlag || exportFlag) && (
            <Button
              type="submit"
              label="Save"
              className="ml-2 px-3"
              disabled={importDisabled && exportDisabled}
            />
          )}
        </div>
      </form>

      <Dialog
        header={
          <h3 className="my-0">
            {popOption.type === "edit"
              ? `${
                  popOption.module === "import" ? "Import" : "Export"
                } Template`
              : ""}
          </h3>
        }
        headerClassName="border-300"
        contentClassName="p-0"
        visible={isPop.edit}
        className="w-12 md:w-10 lg:w-9"
        onHide={() => closePop({ type: "edit" })}
      >
        {isPop.edit ? (
          <FileTransferTemplate
            popPreOption={popOption}
            closePrePop={closePop}
            moduleType={moduleType}
            activeModule={activeModule}
          />
        ) : null}
      </Dialog>
    </>
  );
}
