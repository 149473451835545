import request from "../request";
import Url from "../url/system";

const login = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.login + path, data, options);
};
const seller = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.seller + path, data, options);
};
const forgotPwd = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.forgotPwd + path, data, options);
};
const signup = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.signup + path, data, options);
};
const signupCheck = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.signupCheck + path, data, options);
};

export default {
  login,
  forgotPwd,
  seller,
  signup,
  signupCheck,
};
