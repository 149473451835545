import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { CommonAPI, DailyAPI } from "../../axios/api";
import * as XLSXSTYLE from "xlsx-js-style";
import moment from "moment";

import ModuleFileTransfer from "../../components/fileTransfer";
import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ViewPallet from "../../components/module/pallet/viewPallet";
import { FormCheckbox } from "../../components/formElement";
import { useAbortedEffect } from "../../components/hooks";
import {
  cjFileExportColumns,
  cjFedExExportColumns,
} from "../../service/columns/daily/cj";
import { system_table } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  checkFormUpdate,
  cleanObj,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../service/table";
import { option_daily_cj_type } from "../../service/option";
import { readExcelFile, saveExcel, datenum } from "../../service/excel";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

export default function DailyCJ(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Daily" }, { label: "CJ 匯出" }];
  const [reload, setReload] = useState({ list: false });
  // 註銷(全選)
  const [revokeChecked, setRevokeChecked] = useState(false);
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    dateRange: [],
    warehouseList: [],
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});
  // 彈出視窗
  const [isPop, setIsPop] = useState({
    copy: false, // 編輯
  });
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  // tab
  const [tabData, setTabData] = useState([]);
  const [tabActive, setTabActive] = useState(0);
  // 預設模組資料
  const defTemplate = {
    cjFile: {
      import: {},
      export: cjFileExportColumns,
    },
    cjFedEx: {
      import: {},
      export: cjFedExExportColumns,
    },
  };
  // 匯入匯出 模組資料
  const [moduleData, setModuleData] = useState({
    importTemplate: {},
    exportTemplate: {},
  });

  useAbortedEffect((signal) => {
    CommonAPI.getWarehouse({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let warehouseList = [1, 2, 3, 4];
        let match = data.filter(
          (item) => warehouseList.indexOf(item.id) !== -1
        );
        let temp = formatOptions(
          {
            warehouse: match,
          },
          { uniqueKey: "id", showLabel: "name" }
        );
        setOptionData(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
    });
  }, []);

  // 帶入 tab 資料
  useEffect(() => {
    setTabData(option_daily_cj_type);
  }, [option_daily_cj_type]);

  // tab 切換
  const tabChange = (e) => {
    setTabActive(e.index);

    setTbData([]);
    setTbTotal(0);

    let temp = { ...filterData };
    setFilterData(temp);
    setSearch(temp);
    setTbFirstIndex(0);
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    if (tabData.length === 0) return;

    let result = getPageSetting([`/daily/cj/${tabData[tabActive].value}`]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      if (
        tabData[tabActive]?.value === "cjFedEx" &&
        result.params.warehouseList
      ) {
        result.params.warehouseList = result.params.warehouseList
          .split(",")
          .map((item) => Number(item));
      }
      if (result.params.startDate && result.params.endDate) {
        result.params.dateRange = [
          new Date(result.params.startDate),
          new Date(result.params.endDate),
        ];
      }
      delete result.params.startDate;
      delete result.params.endDate;

      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, [tabData]);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      getListData({ type: "list", signal });
    },
    [reload]
  );

  const getListData = ({ type = "list", signal }) => {
    let params = {
      ...search,
      page: search.isTotal ? -1 : search.page,
      size: search.isTotal ? -1 : search.size,
    };
    if (tabData[tabActive]?.value === "cjFedEx") {
      params.warehouseList =
        search.warehouseList.length === 0
          ? "1,2,3,4"
          : search.warehouseList.join(",");
    } else {
      delete params.warehouseList;
    }
    if (search.dateRange && search.dateRange[0] && search.dateRange[1]) {
      params.startDate = moment(search.dateRange[0]).format("YYYY-MM-DD");
      params.endDate = moment(search.dateRange[1]).format("YYYY-MM-DD");
    }
    delete params.dateRange;
    params = cleanObj(params);

    if (type === "list") {
      setPageSetting({
        params,
        page: `/daily/cj/${tabData[tabActive].value}`,
      });
    } else if (type === "export") {
      params.page = -1;
      params.size = -1;
    }
    delete params.isTotal;

    let promise;
    if (tabData[tabActive].value === "cjFile") {
      promise = DailyAPI.getCJFileData({ data: params, options: { signal } });
    } else if (tabData[tabActive].value === "cjFedEx") {
      promise = DailyAPI.getFedexData({
        data: params,
        options: { signal },
      });
    }

    return promise.then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        if (type === "list") {
          data.forEach((item) => {
            item.signature = item.signature === 1 ? "Y" : "";
            item.is_there_insurance_fee =
              item.is_there_insurance_fee === 1 ? "Y" : "";
          });

          if (search.isTotal) {
            setTbRows(total);
          }

          setTbData(data);
          setTbTotal(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          data.forEach((item) => {
            item.signature = item.signature === 1 ? "签收" : "";
            item.is_there_insurance_fee =
              item.is_there_insurance_fee === 1 ? "YES" : "";
          });
          return data;
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  // 取得回傳的模板資料
  const getTemplate = ({ importTemplate = {}, exportTemplate = {} } = {}) => {
    setModuleData({ importTemplate, exportTemplate });
  };

  // 設定列表欄位
  useEffect(() => {
    let activeTab = tabData[tabActive]?.value ?? "cjFile";
    let entries = Object.values(
      moduleData.exportTemplate.settings ?? defTemplate[activeTab]?.export
    );
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "multi",
      activeTemplate: entries,
      defaultTemplate: defTemplate[activeTab]?.export,
      tbRows,
    });

    if (activeTab === "cjFedEx") {
      let matchIdx = tempColumns.findIndex((item) => item.field === "tracking");
      tempColumns.splice(matchIdx, 1);
    }

    setTbColumns(tempColumns);
  }, [moduleData, tabActive, tbRows]);

  const sheet_from_array_of_arrays_cjFedEx = (data) => {
    let space = { c: 0, r: 2 }; // 若需要空行/列
    let ws: any = {};

    for (let C = 0; C !== data[0].length; ++C) {
      let column: any = {
        0: "TEMPLATE_INFO",
        1: "MK",
        2: "NEW_EXPRESS_LABEL",
        3: "V2020110517364136698",
        4: "DONOT REMOVE THIS LINE!!!",
      };

      ws[
        XLSXSTYLE.utils.encode_cell({
          c: C,
          r: 0,
        })
      ] = {
        v: column[C] ?? "",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: 0,
          },
          border: {},
          fill: {
            fgColor: { rgb: "fa0000" },
          },
        },
      };
    }

    for (let C = 0; C !== data[0].length; ++C) {
      let column: any = {
        0: {
          label: "发件地址信息 / Sender Address Information",
          fgColor: "bdd7ee",
        },
        11: {
          label: "收件地址信息 / Sender Address Information",
          fgColor: "c6e0b4",
        },
        24: {
          label: "包裹重量尺寸信息 / Package Weight Dim Information",
          fgColor: "f8cbad",
        },
        35: {
          label:
            "包裹内商品信息 / Package internal product information (国际件 / International)",
          fgColor: "ddebf7",
        },
        43: { label: "订单标注", fgColor: "fff2cc" },
        46: { label: "增值服务 / Value added services", fgColor: "d9e1f2" },
      };

      ws[
        XLSXSTYLE.utils.encode_cell({
          c: C,
          r: 1,
        })
      ] = {
        v: column[C]?.label ?? "",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: 0,
          },
          border:
            C >= 0 && C <= 4
              ? {}
              : {
                  top: { style: "thin", color: { rgb: "000000" } },
                  bottom: { style: "thin", color: { rgb: "000000" } },
                  left: { style: "thin", color: { rgb: "000000" } },
                  right: { style: "thin", color: { rgb: "000000" } },
                },
          fill: {
            fgColor: {
              rgb: C >= 0 && C <= 4 ? "bdd7ee" : column[C]?.fgColor ?? "ffffff",
            },
          },
        },
      };
    }

    let required = [
        1, 3, 4, 7, 8, 9, 10, 11, 13, 14, 17, 18, 19, 21, 24, 25, 26,
      ],
      product = [35, 36, 37, 38, 39, 40, 41, 42];
    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        let cell: any = {
          v: data[R][C] ?? "",
          s: {
            font: {
              sz: "12",
              color: {
                rgb: "000000",
              },
              bold: false,
            },
            alignment: {
              horizontal: "left",
              vertical: "center",
              wrapText: false,
            },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
            fill: {
              fgColor: {
                rgb:
                  (R === 0 || R === 1) && required.indexOf(C) !== -1
                    ? "fff2cc"
                    : (R === 0 || R === 1) && product.indexOf(C) !== -1
                    ? "ddebf7"
                    : R === 0 || R === 1
                    ? "e2efda"
                    : "ffffff",
              },
            },
          },
        };

        if (cell.v === null) continue;
        let cell_ref = XLSXSTYLE.utils.encode_cell({ c: C, r: R + space.r });

        if (typeof cell.v === "number") cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else if (cell.v instanceof Date) {
          cell.t = "n";
          cell.z = XLSXSTYLE.SSF._table[14];
          cell.v = datenum(cell.v, null);
        } else cell.t = "s";

        ws[cell_ref] = cell;
      }
    }
    let range = {
      s: { c: 0, r: 0 },
      e: { c: data[0].length, r: space.r + data.length + 10 },
    };
    if (range.s.c < 10000000) ws["!ref"] = XLSXSTYLE.utils.encode_range(range);
    ws["!merges"] = [
      // 合併欄位
      { s: { c: 0, r: 1 }, e: { c: 10, r: 1 } },
      { s: { c: 11, r: 1 }, e: { c: 23, r: 1 } },
      { s: { c: 24, r: 1 }, e: { c: 34, r: 1 } },
      { s: { c: 35, r: 1 }, e: { c: 42, r: 1 } },
      { s: { c: 43, r: 1 }, e: { c: 45, r: 1 } },
      { s: { c: 46, r: 1 }, e: { c: 50, r: 1 } },
    ];

    // 每行 寬度
    ws["!cols"] = [];
    for (let i = 0; i < data[0].length; i++) {
      if (i <= 2 || i === 4 || i === 9) {
        ws["!cols"].push({ wch: 22 });
      } else {
        ws["!cols"].push({ wch: 15 });
      }
    }

    return ws;
  };

  // 匯出 FedEx 檔案
  const downloadFile = () => {
    dispatch(setProgress(true));

    let head = {
      ch: [],
      en: [],
    };
    Object.values(cjFedExExportColumns).forEach((item) => {
      if (
        item.apiKey !== "order_id" &&
        item.apiKey !== "sku" &&
        item.apiKey !== "model" &&
        item.apiKey !== "total_pcs" &&
        item.apiKey !== "total_price" &&
        item.apiKey !== "tracking"
      ) {
        head.ch.push(item.exportChName ?? "");
        head.en.push(item.exportEnName ?? "");
      }
    });
    // 欄位內容
    let keys = Object.keys(cjFedExExportColumns);
    let body = tbData.map((item: any) => {
      let row: any = [];
      keys.forEach((key) => {
        if (
          key !== "order_id" &&
          key !== "sku" &&
          key !== "model" &&
          key !== "total_pcs" &&
          key !== "total_price" &&
          key !== "tracking"
        ) {
          row.push(item[key]);
        }
      });
      return row;
    });

    body.unshift(head.en);
    body.unshift(head.ch);
    let ws = sheet_from_array_of_arrays_cjFedEx(body);
    let setting = {
      filename: `CJ_FedEx_${moment().format("YYYYMMDDHHmmss")}`,
      ws: ws,
    };
    saveExcel(setting, () => dispatch(setProgress(false)));
  };

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <TabView
        className="mb-3"
        activeIndex={tabActive}
        onTabChange={(e) => tabChange(e)}
        // scrollable
      >
        {tabData.map((item, index) => (
          <TabPanel key={`tab_item_${index}`} header={item.label}></TabPanel>
        ))}
      </TabView>

      <div className="grid mr-0">
        <div className="flex col-12 pr-0">
          <div className="flex ml-auto">
            <ModuleFileTransfer
              setting={{
                importFlag: false,
                exportFlag: true,
              }}
              moduleType={tabData[tabActive]?.value}
              importColumns={
                defTemplate[tabData[tabActive]?.value ?? "cjFile"]?.import
              }
              exportColumns={
                defTemplate[tabData[tabActive]?.value ?? "cjFile"]?.export
              }
              exportData={tbData}
              getTemplate={getTemplate}
              getAllData={getListData}
            />
            {tabData[tabActive]?.value === "cjFedEx" && (
              <Button
                className="ml-2"
                label="匯出 Excel"
                onClick={() => downloadFile()}
              />
            )}
          </div>
        </div>

        <div className="col-12 border-top-1 border-300 p-0"></div>
        <div className="col-12 sm:col-4 md:col-3 pr-0">
          <Calendar
            className="w-full"
            name="dateRange"
            value={filterData.dateRange}
            onChange={(e) => changeFilter(e)}
            placeholder="Start Date ~ End Date"
            selectionMode="range"
            numberOfMonths={2}
            readOnlyInput
            showButtonBar
          />
        </div>
        {tabData[tabActive]?.value === "cjFedEx" && (
          <div className="col-12 sm:col-4 md:col-3 pr-0">
            <MultiSelect
              className="w-full"
              name="warehouseList"
              value={filterData.warehouseList}
              options={optionData.warehouse}
              onChange={(e) => changeFilter(e)}
              optionValue="id"
              optionLabel="name"
              placeholder="選擇倉庫"
              display="chip"
            />
          </div>
        )}
        <div className="flex col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
        </div>
      </div>

      <div className="mt-2">
        <DataTable
          value={tbData}
          className={classNames("size-sm", {
            "table-empty": tbData.length === 0,
          })}
          size="normal"
          paginator
          paginatorTemplate={ModuleTablePaginator}
          currentPageReportTemplate="Total {totalRecords} items"
          lazy
          first={tbFirstIndex}
          totalRecords={tbTotal}
          onPage={onPage}
          rows={tbRows}
          scrollable
          scrollHeight="calc(100vh - 20rem)"
          scrollDirection="both"
          responsiveLayout="scroll"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column key={`${item.field}_${index}`} {...item} />
          ))}
        </DataTable>

        {tbData.length === 0 && <ModuleTableEmpty />}
      </div>
    </>
  );
}
