import request from "../request";
import Url from "../url/daily";

const getUnshippedOrderList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getUnshippedOrderList + path, data, options);
};
const getUnshippedOrderDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getUnshippedOrderDetails + path, data, options);
};
const setDailyUnshippedImportData = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.setDailyUnshippedImportData + path, data, options);
};
const updateUnshippedOrderSingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateUnshippedOrderSingle + path, data, options);
};
const updateUnshippedOrderMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateUnshippedOrderMulti + path, data, options);
};
const copyDailyUnshippedOrderData = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.copyDailyUnshippedOrderData + path, data, options);
};

const getDFOrderList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getDFOrderList + path, data, options);
};
const getDFOrderDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getDFOrderDetails + path, data, options);
};
const setDailyDFImportData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setDailyDFImportData + path, data, options);
};
const updateDFOrderSingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateDFOrderSingle + path, data, options);
};
const updateDFOrderMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateDFOrderMulti + path, data, options);
};
const copyDailyDFOrderData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.copyDailyDFOrderData + path, data, options);
};

const getNewOrderList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getNewOrderList + path, data, options);
};
const getNewOrderDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getNewOrderDetails + path, data, options);
};
const setDailyNewOrderImportData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setDailyNewOrderImportData + path, data, options);
};
const updateNewOrderSingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateNewOrderSingle + path, data, options);
};
const updateNewOrderMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateNewOrderMulti + path, data, options);
};

const getWayFairOrderList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getWayFairOrderList + path, data, options);
};
const getWayFairOrderDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getWayFairOrderDetails + path, data, options);
};
const setDailyWayFairImportData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setDailyWayFairImportData + path, data, options);
};
const updateWayFairOrderSingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateWayFairOrderSingle + path, data, options);
};
const updateWayFairOrderMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateWayFairOrderMulti + path, data, options);
};
const copyDailyWayFairOrderData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.copyDailyWayFairOrderData + path, data, options);
};

const getTodayOrderComplete = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getTodayOrderComplete + path, data, options);
};
const setOrderComplete = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setOrderComplete + path, data, options);
};

const getTotalOrderData = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getTotalOrderData + path, data, options);
};
const updateTotalOrderData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateTotalOrderData + path, data, options);
};
const updateDailyInventory = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateDailyInventory + path, data, options);
};

const setWayFairRemittance = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setWayFairRemittance + path, data, options);
};
const updateShippedFee = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateShippedFee + path, data, options);
};
const updateTacking = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateTacking + path, data, options);
};

const getCJFileData = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getCJFileData + path, data, options);
};
const getCJFedexData = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getCJFedexData + path, data, options);
};
const getFedexData = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getFedexData + path, data, options);
};

export default {
  getUnshippedOrderList,
  getUnshippedOrderDetails,
  setDailyUnshippedImportData,
  updateUnshippedOrderSingle,
  updateUnshippedOrderMulti,
  copyDailyUnshippedOrderData,

  getDFOrderList,
  getDFOrderDetails,
  setDailyDFImportData,
  updateDFOrderSingle,
  updateDFOrderMulti,
  copyDailyDFOrderData,

  getNewOrderList,
  getNewOrderDetails,
  setDailyNewOrderImportData,
  updateNewOrderSingle,
  updateNewOrderMulti,

  getWayFairOrderList,
  getWayFairOrderDetails,
  setDailyWayFairImportData,
  updateWayFairOrderSingle,
  updateWayFairOrderMulti,
  copyDailyWayFairOrderData,

  getTodayOrderComplete,
  setOrderComplete,

  getTotalOrderData,
  updateTotalOrderData,
  updateDailyInventory,

  setWayFairRemittance,
  updateShippedFee,
  updateTacking,

  getCJFileData,
  getCJFedexData,
  getFedexData,
};
