import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { TransferAPI } from "../../axios/api";

import FileTransferPopImport from "./popImport";
import FileTransferPopExport from "./popExport";
import FileTransferPopSetting from "./popSetting";
import { useAbortedEffect } from "../hooks";
import { readExcelFile } from "../../service/excel";
import { option_function_type } from "../../service/option";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function ModuleFileTransfer(props) {
  const {
    setting: {
      importFlag = true, // 是否啟用 匯入
      exportFlag = true, // 是否啟用 匯出
      importDisabled = false, // 是否禁用 匯入
      exportDisabled = false, // 是否禁用 匯出
    },
    moduleType, // 分類
    importColumns, // 匯入預設欄位
    exportColumns, // 匯出預設欄位
    exportData = [], // 匯出資料
    optionData = {}, // 選項資料
    saveAction = () => {}, // 儲存資料(函式)
    getTemplate, // 回傳模組資料(函式)
    getAllData, // 取得全部列表資料
  } = props;
  const dispatch = useDispatch();
  // 是否顯示彈出視窗
  const [isPop, setIsPop] = useState({
    setting: false, // 設定生效模組
    import: false, // 匯入資料
    export: false, // 匯出類型
  });
  // 彈出視窗資料
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  // 是否呼叫 API
  const [reload, setReload] = useState({ module: false });
  // 生效的模組
  const [activeModule, setActiveModule] = useState({
    activeImport: null, // 生效的 匯入模組
    importTemplate: {}, // 匯入模組 資料
    activeExport: null, // 生效的 匯出模組
    exportTemplate: {}, // 匯出模組 資料
  });
  // 匯入資料
  const [importData, setImportData] = useState({ keys: [], data: [] });

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  useEffect(() => {
    if (!moduleType) return;

    setReload((state) => ({ ...state, module: true }));
  }, [moduleType]);

  // 搜尋模組資料
  useAbortedEffect(
    (signal) => {
      if (!reload.module) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        function_type: option_function_type[moduleType],
      };

      TransferAPI.getIsUseExportImportModule({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;

        if (Response === 1) {
          let matchData = {
            importTemplate: data.import_module
              ? {
                  ...data.import_module,
                  settings: JSON.parse(data.import_module.settings),
                }
              : { settings: importColumns ?? {} }, // 生效的匯入模組
            exportTemplate: data.export_module
              ? {
                  ...data.export_module,
                  settings: JSON.parse(data.export_module.settings),
                }
              : { settings: exportColumns ?? {} }, // 生效的匯出模組
          };
          setActiveModule({
            activeImport: data.import_module?.id ?? null,
            importTemplate: matchData.importTemplate,
            activeExport: data.export_module?.id ?? null,
            exportTemplate: matchData.exportTemplate,
          });
          getTemplate(matchData);
          setReload((state) => ({
            ...state,
            module: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message ?? "編輯失敗",
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  // 選擇匯入檔案
  const fileChange = (e) => {
    let file = e.target.files[0];
    let data = readExcelFile(file, 0);

    data.then((result) => {
      setImportData({ keys: result.keys, data: result.data });
    });
  };

  // 重置匯入選擇器
  const resetFileIpt = () => {
    document.getElementById("selectFile").value = "";
    setImportData({ keys: [], data: [] });
  };

  return (
    <>
      <div className="flex">
        {importFlag && (
          <>
            <Button
              type="button"
              icon="pi pi-folder-open"
              // label="Select File"
              className="p-button-secondary p-button-icon-only p-button-outlined ml-auto px-4"
              onClick={() => document.getElementById("selectFile").click()}
              disabled={importDisabled}
            />
            <input
              id="selectFile"
              type="file"
              accept=".xlsx,.csv,.txt"
              onChange={(e) => fileChange(e)}
              hidden
            />

            <Button
              type="button"
              label="Import"
              className="ml-2 px-3"
              onClick={() => openPop({ type: "import" })}
              disabled={importData.data.length === 0 || importDisabled}
            />
          </>
        )}

        {exportFlag && (
          <>
            <Button
              type="button"
              label="Export"
              className={classNames("px-3", {
                "ml-2": importFlag,
                "ml-auto": !importFlag,
              })}
              onClick={() => openPop({ type: "export" })}
              disabled={exportDisabled}
            />
          </>
        )}

        <Button
          type="button"
          label="Setting"
          icon="pi pi-cog"
          className="p-button-secondary p-button-outlined ml-2 px-3"
          onClick={() => openPop({ type: "setting" })}
        />
      </div>

      <Dialog
        header={
          <h3 className="my-0">
            {popOption.type === "setting"
              ? "匯入匯出設定"
              : isPop.import
              ? "匯入資料"
              : isPop.export
              ? "匯出資料"
              : ""}
          </h3>
        }
        headerClassName="border-300"
        contentClassName="p-0"
        visible={isPop.setting || isPop.import || isPop.export}
        className={classNames({ "max-w-30rem": !isPop.import }, "w-11")}
        onHide={() => closePop({ type: popOption.type })}
        // closable={false}
      >
        {isPop.setting ? (
          <FileTransferPopSetting
            closePrePop={closePop}
            setting={{ importFlag, exportFlag, importDisabled, exportDisabled }}
            moduleType={moduleType}
            importColumns={importColumns}
            exportColumns={exportColumns}
            activeModule={activeModule}
            setPreReload={setReload}
          />
        ) : isPop.import ? (
          <FileTransferPopImport
            isPop={isPop}
            prePopOption={popOption}
            closePrePop={closePop}
            importColumns={importColumns}
            activeModule={activeModule}
            importData={importData}
            optionData={optionData}
            saveAction={saveAction}
            resetFileIpt={resetFileIpt}
          />
        ) : isPop.export ? (
          <FileTransferPopExport
            isPop={isPop}
            prePopOption={popOption}
            closePrePop={closePop}
            moduleType={moduleType}
            exportColumns={exportColumns}
            activeModule={activeModule}
            optionData={optionData}
            exportData={exportData}
            getAllData={getAllData}
          />
        ) : null}
      </Dialog>
    </>
  );
}
