import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { CommonAPI } from "../../../axios/api";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInputtext,
  FormInputnumber,
  FormDropdown,
} from "../../../components/formElement/index";
import { formatResData, checkFormUpdate } from "../../../service/common";
import { option_open } from "../../../service/option";
import { system_email } from "../../../service/system";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function CommonGoogleTablePopEdit(props) {
  const { closePrePop, prePopOption, setPreReload } = props;
  const dispatch = useDispatch();
  // 表單設定
  const defaultValues = {
    name: "",
    schema_name: "",
    status: 1,
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        name: yup.string().required("必填"),
        schema_name: yup.string().required("必填"),
        status: yup.number().required("必填").nullable(),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useEffect(() => {
    if (prePopOption.data) {
      reset(prePopOption.data);
    }
  }, [prePopOption]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    if (
      !checkFormUpdate({
        newData: submitData,
        oldData: prePopOption.data ?? {},
      })
    ) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    let promise;
    if (prePopOption.data) {
      // 修改
      promise = CommonAPI.updateGoogleSheetDumpTable({ data: submitData });
    } else {
      // 新增
      promise = CommonAPI.setGoogleSheetDumpTable({ data: submitData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "編輯成功",
            detail: "",
          })
        );
        closePrePop({ type: "edit" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "編輯失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid mx-0">
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Name
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext setting={setting} data={{ name: "name" }} />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="schema_name"
              className={classNames(
                { "p-error": errors.schema_name },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Schema Name
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext setting={setting} data={{ name: "schema_name" }} />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="status"
              className={classNames(
                { "p-error": errors.status },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Status
            </label>
            <div className="col-12 sm:col-8">
              <FormDropdown
                setting={setting}
                data={{
                  name: "status",
                  options: option_open,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                }}
              />
            </div>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="Back"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "edit" })}
            />
            <Button type="submit" label="Save" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
