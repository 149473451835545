export const cjFileExportColumns = {
  po: {
    name: "order_id",
    value: "order_id",
    apiKey: "po",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 1,
  },
  sku: {
    name: "sku",
    value: "sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 2,
  },
  tracking_number: {
    name: "tracking",
    value: "tracking",
    apiKey: "tracking_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 3,
  },
  carrier_name: {
    name: "carrier",
    value: "carrier",
    apiKey: "carrier_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 4,
  },
  qty: {
    name: "total_pcs",
    value: "total_pcs",
    apiKey: "qty",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 5,
  },
};

export const cjFedExExportColumns = {
  order_id: {
    name: "order_id",
    value: "order_id",
    apiKey: "order_id",
    exportChName: "",
    exportEnName: "訂單號碼 (不轉出)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 1,
  },
  sku: {
    name: "sku",
    value: "sku",
    apiKey: "sku",
    exportChName: "",
    exportEnName: "SKU (不轉出)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 2,
  },
  model: {
    name: "model",
    value: "model",
    apiKey: "model",
    exportChName: "",
    exportEnName: "Model (不轉出)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 3,
  },
  total_pcs: {
    name: "total_pcs",
    value: "total_pcs",
    apiKey: "total_pcs",
    exportChName: "",
    exportEnName: "數量 (不轉出)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 4,
  },
  total_price: {
    name: "total_price",
    value: "total_price",
    apiKey: "total_price",
    exportChName: "",
    exportEnName: "訂單金額 (不轉出)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 5,
  },
  shipping_region: {
    name: "shipping_region",
    value: "shipping_region",
    apiKey: "shipping_region",
    exportChName: "发货所属地区(省、州)",
    exportEnName: "Shipping region",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 6,
  },
  sender_name: {
    name: "sender_name",
    value: "sender_name",
    apiKey: "sender_name",
    exportChName: "姓名（必填）",
    exportEnName: "Name (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 7,
  },
  sender_company: {
    name: "sender_company",
    value: "sender_company",
    apiKey: "sender_company",
    exportChName: "公司",
    exportEnName: "Company",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 8,
  },
  sender_phone: {
    name: "sender_phone",
    value: "sender_phone",
    apiKey: "sender_phone",
    exportChName: "电话（必填）",
    exportEnName: "Phone (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 9,
  },
  sender_address1: {
    name: "sender_address1",
    value: "sender_address1",
    apiKey: "sender_address1",
    exportChName: "地址1（必填）",
    exportEnName: "Address line1 (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 10,
  },
  sender_address2: {
    name: "sender_address2",
    value: "sender_address2",
    apiKey: "sender_address2",
    exportChName: "收件地址2",
    exportEnName: "Address line2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 11,
  },
  sender_address3: {
    name: "sender_address3",
    value: "sender_address3",
    apiKey: "sender_address3",
    exportChName: "收件地址3",
    exportEnName: "Address line3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 12,
  },
  sender_city: {
    name: "sender_city",
    value: "sender_city",
    apiKey: "sender_city",
    exportChName: "城市（必填）",
    exportEnName: "City (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 13,
  },
  sender_state: {
    name: "sender_state",
    value: "sender_state",
    apiKey: "sender_state",
    exportChName: "州/省（必填）",
    exportEnName: "State (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 14,
  },
  sender_zipcode: {
    name: "sender_zipcode",
    value: "sender_zipcode",
    apiKey: "sender_zipcode",
    exportChName: "邮编（必填）",
    exportEnName: "ZipCode (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 15,
  },
  sender_country: {
    name: "sender_country",
    value: "sender_country",
    apiKey: "sender_country",
    exportChName: "国家（必填）",
    exportEnName: "Country (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 16,
  },
  recipient_name: {
    name: "recipient_name",
    value: "recipient_name",
    apiKey: "recipient_name",
    exportChName: "姓名（必填）",
    exportEnName: "Name (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 17,
  },
  recipient_company: {
    name: "recipient_company",
    value: "recipient_company",
    apiKey: "recipient_company",
    exportChName: "公司",
    exportEnName: "Company",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 18,
  },
  recipient_phone: {
    name: "recipient_phone",
    value: "recipient_phone",
    apiKey: "recipient_phone",
    exportChName: "电话（必填）",
    exportEnName: "Phone (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 19,
  },
  recipient_address1: {
    name: "recipient_address1",
    value: "recipient_address1",
    apiKey: "recipient_address1",
    exportChName: "地址1（必填）",
    exportEnName: "Address line1 (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 20,
  },
  recipient_address2: {
    name: "recipient_address2",
    value: "recipient_address2",
    apiKey: "recipient_address2",
    exportChName: "地址2",
    exportEnName: "Address line2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 21,
  },
  recipient_address3: {
    name: "recipient_address3",
    value: "recipient_address3",
    apiKey: "recipient_address3",
    exportChName: "地址3",
    exportEnName: "Address line3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 22,
  },
  recipient_city: {
    name: "recipient_city",
    value: "recipient_city",
    apiKey: "recipient_city",
    exportChName: "城市（必填）",
    exportEnName: "City (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 23,
  },
  recipient_state: {
    name: "recipient_state",
    value: "recipient_state",
    apiKey: "recipient_state",
    exportChName: "州/省（必填）",
    exportEnName: "State (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 24,
  },
  recipient_zipcode: {
    name: "recipient_zipcode",
    value: "recipient_zipcode",
    apiKey: "recipient_zipcode",
    exportChName: "邮编（必填）",
    exportEnName: "ZipCode (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 25,
  },
  recipient_zip4: {
    name: "recipient_zip4",
    value: "recipient_zip4",
    apiKey: "recipient_zip4",
    exportChName: "Zip4",
    exportEnName: "Zip4",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 26,
  },
  recipient_country: {
    name: "recipient_country",
    value: "recipient_country",
    apiKey: "recipient_country",
    exportChName: "国家（必填）",
    exportEnName: "Country (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 27,
  },
  residential_address: {
    name: "residential_address",
    value: "residential_address",
    apiKey: "residential_address",
    exportChName: "收货地址为住宅地址",
    exportEnName: "Residential Address",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 28,
  },
  fba_code: {
    name: "fba_code",
    value: "fba_code",
    apiKey: "fba_code",
    exportChName: "FBA Code",
    exportEnName: "FBA Code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 29,
  },
  weight_dim_unit: {
    name: "weight_dim_unit",
    value: "weight_dim_unit",
    apiKey: "weight_dim_unit",
    exportChName: "重量尺寸单位",
    exportEnName: "Weight Dim unit (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 30,
  },
  multi_package: {
    name: "multi_package",
    value: "multi_package",
    apiKey: "multi_package",
    exportChName: "是否多包裹（必填）",
    exportEnName: "Multi Package (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 31,
  },
  package_weight: {
    name: "package_weight",
    value: "package_weight",
    apiKey: "package_weight",
    exportChName: "包裹重量（必填）",
    exportEnName: "Package Weight (Required)",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 32,
  },
  package_length: {
    name: "package_length",
    value: "package_length",
    apiKey: "package_length",
    exportChName: "包裹长",
    exportEnName: "Package Length",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 33,
  },
  package_width: {
    name: "package_width",
    value: "package_width",
    apiKey: "package_width",
    exportChName: "包裹宽",
    exportEnName: "Package Width",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 34,
  },
  package_height: {
    name: "package_height",
    value: "package_height",
    apiKey: "package_height",
    exportChName: "包裹高",
    exportEnName: "Package Height",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 35,
  },
  select_freight_class: {
    name: "select_freight_class",
    value: "select_freight_class",
    apiKey: "select_freight_class",
    exportChName: "选择货运类型",
    exportEnName: "Select Freight Class",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 36,
  },
  label_ref1: {
    name: "label_ref1",
    value: "label_ref1",
    apiKey: "label_ref1",
    exportChName: "面单备注1",
    exportEnName: "Label Ref1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 37,
  },
  label_ref2: {
    name: "label_ref2",
    value: "label_ref2",
    apiKey: "label_ref2",
    exportChName: "面单备注2",
    exportEnName: "Label Ref2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 38,
  },
  label_ref3: {
    name: "label_ref3",
    value: "label_ref3",
    apiKey: "label_ref3",
    exportChName: "面单备注3",
    exportEnName: "Label Ref3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 39,
  },
  label_ref4: {
    name: "label_ref4",
    value: "label_ref4",
    apiKey: "label_ref4",
    exportChName: "面单备注4",
    exportEnName: "Label Ref4",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 40,
  },
  internal_sku: {
    name: "internal_sku",
    value: "internal_sku",
    apiKey: "internal_sku",
    exportChName: "商品SKU",
    exportEnName: "SKU",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 41,
  },
  internal_chinese_product_name: {
    name: "internal_chinese_product_name",
    value: "internal_chinese_product_name",
    apiKey: "internal_chinese_product_name",
    exportChName: "商品中文品名",
    exportEnName: "Chinese Product Name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 42,
  },
  internal_english_product_name: {
    name: "internal_english_product_name",
    value: "internal_english_product_name",
    apiKey: "internal_english_product_name",
    exportChName: "商品英文品名",
    exportEnName: "English Product Name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 250,
    order: 43,
  },
  internal_HScode: {
    name: "internal_HScode",
    value: "internal_HScode",
    apiKey: "internal_HScode",
    exportChName: "海关编码",
    exportEnName: "HScode",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 44,
  },
  internal_country_of_origin: {
    name: "internal_country_of_origin",
    value: "internal_country_of_origin",
    apiKey: "internal_country_of_origin",
    exportChName: "产地",
    exportEnName: "Country of Origin",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 45,
  },
  internal_product_qty: {
    name: "internal_product_qty",
    value: "internal_product_qty",
    apiKey: "internal_product_qty",
    exportChName: "包裹内件数量",
    exportEnName: "Product Qty",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 46,
  },
  internal_unit_price: {
    name: "internal_unit_price",
    value: "internal_unit_price",
    apiKey: "internal_unit_price",
    exportChName: "单价",
    exportEnName: "Unit Price",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 47,
  },
  internal_product_weight: {
    name: "internal_product_weight",
    value: "internal_product_weight",
    apiKey: "internal_product_weight",
    exportChName: "商品重量",
    exportEnName: "Product Weight",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 48,
  },
  expected_shipping_date: {
    name: "expected_shipping_date",
    value: "expected_shipping_date",
    apiKey: "expected_shipping_date",
    exportChName: "预计发货日期",
    exportEnName: "Expected Shipping Date",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 49,
  },
  customer_order: {
    name: "customer_order",
    value: "customer_order",
    apiKey: "customer_order",
    exportChName: "客户订单号",
    exportEnName: "Customer Order#",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 50,
  },
  customer_remark: {
    name: "customer_remark",
    value: "customer_remark",
    apiKey: "customer_remark",
    exportChName: "订单备注",
    exportEnName: "PO",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 51,
  },
  signature: {
    name: "signature",
    value: "signature",
    apiKey: "signature",
    exportChName: "签名",
    exportEnName: "Signature",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 52,
  },
  is_there_insurance_fee: {
    name: "is_there_insurance_fee",
    value: "is_there_insurance_fee",
    apiKey: "is_there_insurance_fee",
    exportChName: "是否收保险费",
    exportEnName: "Is there insurance Fee",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 180,
    order: 53,
  },
  insurance_fee: {
    name: "insurance_fee",
    value: "insurance_fee",
    apiKey: "insurance_fee",
    exportChName: "保险费",
    exportEnName: "Insurance Fee",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 54,
  },
  cod_service: {
    name: "cod_service",
    value: "cod_service",
    apiKey: "cod_service",
    exportChName: "是否COD服务",
    exportEnName: "COD Service",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 55,
  },
  code_service: {
    name: "code_service",
    value: "code_service",
    apiKey: "code_service",
    exportChName: "COD服务费",
    exportEnName: "CODE Service Fee",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 56,
  },
  tracking: {
    name: "tracking",
    value: "tracking",
    apiKey: "tracking",
    exportChName: "",
    exportEnName: "",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 57,
  },
};
