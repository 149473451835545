import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosMulti } from "../../../axios/request/multi";
import { InventoryAPI, CommonAPI } from "../../../axios/api";
import * as XLSXSTYLE from "xlsx-js-style";
import moment from "moment";
import axios from "axios";

import ModuleBreadCrumb from "../../../components/module/breadCrumb";
import ModuleTableEmpty from "../../../components/module/table/empty";
import InventoryPalletPopEdit from "./popEdit";
import InventoryPalletPopDuplicate from "./popDuplicate";
import { FormInputtext, FormCheckbox } from "../../../components/formElement";
import { useAbortedEffect } from "../../../components/hooks";
import { palletExportColumns } from "../../../service/columns/inventory/pallet";
import { system_table, system_dropdown } from "../../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  cleanObj,
  checkFormUpdate,
  checkColumnUpdate,
} from "../../../service/common";
import {
  createColumns,
  createSchema,
  ModuleTablePaginator,
} from "../../../service/table";
import { option_pallet_type } from "../../../service/option";
import { saveExcel, datenum } from "../../../service/excel";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";

export default function InventoryPallet(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Inventory" }, { label: "Pallet" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    keywords: "",
    warehouse_id: null,
    isshipped: 0,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  // 彈出視窗
  const [isPop, setIsPop] = useState({
    edit: false, // 編輯
    duplicate: false, // 重複 shipment name
  });
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  const [optionData, setOptionData] = useState({});
  // 是否強制出貨
  const [isForceShip, setIsForceShip] = useState(false);
  // 使否可出貨
  const [shipChecked, setShipChecked] = useState(false);
  // 確認資訊
  const [confirmOption, setConfirmOption] = useState({});
  // 表單設定
  const defaultValues = {
    checkAll: false,
    data: [],
  };
  const [schema, setSchema] = useState(
    yup
      .object({
        // data: yup.array().of(
        //   yup.object().shape({
        //     // title: yup.string().required("必填"),
        //     // content: yup.string().required("必填"),
        //     // img_url: yup.string().required("必填"),
        //   })
        // ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/inventory/pallet"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: search.isTotal ? -1 : search.page,
        size: search.isTotal ? -1 : search.size,
      };

      setPageSetting({ params, page: "/inventory/pallet" });

      AxiosMulti([
        InventoryAPI.getInventoryList({ data: params, options: { signal } }),
        CommonAPI.getWarehouse({
          data: { page: -1, status: 1 },
          options: { signal },
        }),
      ]).then(
        axios.spread((result1, result2) => {
          if (result1) {
            let { Response, data, total, message } = result1;

            if (Response === 1 && data) {
              data.forEach((item) => {
                item.ship_flag = false;
                item.mark_flag = !!item.shipment_name;
                item.cancel_flag = !item.qty;
              });

              if (search.isTotal) {
                setTbRows(total);
              }

              setTbData(data);
              setTbTotal(total);
              reset({ ...defaultValues, data: data });
              setShipChecked(false);
              setReload((state) => ({
                ...state,
                list: false,
              }));
            } else {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            }
          }

          if (result2) {
            let { Response, data, total, message } = result2;
            if (Response === 1 && data) {
              let temp = formatOptions(
                {
                  warehouse: data,
                },
                { uniqueKey: "id", showLabel: "code" }
              );
              formatOtherOptions(temp);
            } else {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            }
          }

          dispatch(setProgress(false));
        })
      );
    },
    [reload]
  );

  const formatOtherOptions = (data) => {
    let temp = formatOptions(
      {
        pallet_type: option_pallet_type,
      },
      { uniqueKey: "value", showLabel: "label" }
    );
    setOptionData({
      ...data,
      ...temp,
    });
  };

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(palletExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "single",
      saveFun: saveSingleAction,
      activeTemplate: entries,
      defaultTemplate: palletExportColumns,
      formSetting: setting,
      optionData: optionData,
      tbRows,
    });

    if (filterData.isshipped === 0) {
      tempColumns.unshift({
        field: "status",
        header: () => (
          <FormCheckbox
            className="m-0"
            setting={setting}
            data={{
              name: "checkAll",
              label: "",
              type: "boolean",
              value: true,
              changeFun: (e) => checkChange("all", e),
            }}
          />
        ),
        className: "justify-content-center",
        style: {
          width: 30,
          minWidth: 30,
        },
        body: (data, { rowIndex }) => (
          <>
            {/* 
              狀態為註銷(status = 0) 且 類型為子單(pallet_type = 2) && 類型為子單(pallet_type = 2) 且 來源種類 (source_type = 1) 不顯示元件
              類型為主單(pallet_type = 1) || shipment_name不存在 不能勾選(disabled)
            */}
            {!(data.status === 0 && data.pallet_type === 2) &&
            data.pallet_type === 2 &&
            data.source_type === 1 ? (
              <FormCheckbox
                className="m-0"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].ship_flag`,
                  label: "",
                  type: "boolean",
                  value: true,
                  disabled: data.pallet_type !== 2 || !data.shipment_name,
                  changeFun: (e) => checkChange("single", e),
                }}
              />
            ) : null}
          </>
        ),
      });
    }

    tempColumns.push({
      field: "id",
      header: "",
      className: "justify-content-center",
      style: {
        width: 40,
        minWidth: 40,
      },
      body: (data, { rowIndex }) => (
        <>
          {data.pallet_type === 1 ? (
            <Button
              type="button"
              icon="fas fa-expand-alt"
              className="p-button-primary p-button-sm p-button-icon-only p-button-outlined w-auto px-2 py-0"
              onClick={() => openPop({ type: "edit", data: data })}
              disabled={!(data.qty >= 1)}
            />
          ) : data.pallet_type === 2 && data.source_type === 1 ? (
            <Button
              id={`button_back_${rowIndex % tbRows}`}
              type="button"
              icon="fas fa-reply"
              className="p-button-danger p-button-sm p-button-icon-only p-button-outlined w-auto px-2 py-0"
              onClick={() =>
                setTimeout(() => {
                  setConfirmOption({
                    target: document.getElementById(
                      `button_back_${rowIndex % tbRows}`
                    ),
                    message: "確認是否取消拆分?",
                    icon: "pi pi-exclamation-triangle",
                    visible: true,
                    acceptLabel: "是",
                    rejectLabel: "否",
                    accept: () => revokeSplitInventory({ id: data.id }),
                    reject: () => null,
                  });
                }, 200)
              }
            />
          ) : null}
        </>
      ),
    });

    tempColumns.forEach((item) => {
      if (
        item.field === "notes" ||
        item.field === "shipment_name" ||
        item.field === "shipment_id"
      ) {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.pallet_type === 2 && data.source_type === 1) ||
            (item.field === "notes" && data.pallet_type === 1) ? (
              <FormInputtext
                className="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  saveFun: () =>
                    saveSingleAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      }
    });

    setTbColumns(tempColumns);
  }, [optionData, tbData, tbRows]);

  // 勾選-切換
  const checkChange = (type, e) => {
    let tempData = getValues();
    if (!tempData.data || tempData.data.length === 0) return;

    let total = 0, // 可勾選數量
      checked = 0; // 勾選數量
    if (type === "all") {
      tempData.data.forEach((item) => {
        if (
          !(item.status === 0 && item.pallet_type === 2) &&
          !(item.pallet_type !== 2 || !item.shipment_name) &&
          item.pallet_type === 2 &&
          item.source_type === 1
        ) {
          item.ship_flag = tempData.checkAll;

          if (item.ship_flag) checked++;
        }
      });
      reset(tempData);
    } else {
      tempData.data.forEach((item) => {
        if (
          !(item.status === 0 && item.pallet_type === 2) &&
          !(item.pallet_type !== 2 || !item.shipment_name) &&
          item.pallet_type === 2 &&
          item.source_type === 1
        ) {
          total++;
          if (item.ship_flag) checked++;
        }
      });

      if (tempData.checkAll !== !(total !== checked)) {
        tempData.checkAll = !(total !== checked);
        reset(tempData);
      }
    }

    setShipChecked(checked > 0);
  };

  // 切換 complete
  const changeComplete = () => {
    let temp = {
      ...filterData,
      isshipped: filterData.isshipped === 1 ? 0 : 1,
      page: 1,
    };

    setFilterData(temp);
    setSearch(temp);
    setTbFirstIndex(0);
  };

  // 退回拆分的庫存
  const revokeSplitInventory = ({ id }) => {
    dispatch(setProgress(true));

    let jsonData = {
      id: id,
    };

    InventoryAPI.revokeSplitInventory({ data: jsonData }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "取消拆分成功",
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "取消拆分失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const sheet_from_array_of_arrays = (data) => {
    let space = { c: 0, r: 0 }; // 若需要空行/列
    let ws: any = {};

    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        let cell: any = {
          v: data[R][C] ?? "",
          s: {
            font: {
              sz: "12",
              color: { rgb: R === 0 ? "ffffff" : "000000" },
            },
            alignment: {
              horizontal: "left",
              vertical: "center",
              wrapText: true,
            },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
            fill: {
              fgColor: {
                rgb: R === 0 ? "305496" : "ffffff",
              },
            },
          },
        };

        if (cell.v === null) continue;
        let cell_ref = XLSXSTYLE.utils.encode_cell({ c: C, r: R + space.r });

        if (typeof cell.v === "number") cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else if (cell.v instanceof Date) {
          cell.t = "n";
          cell.z = XLSXSTYLE.SSF._table[14];
          cell.v = datenum(cell.v, null);
        } else cell.t = "s";

        ws[cell_ref] = cell;
      }
    }

    let range = {
      s: { c: 0, r: 0 },
      e: { c: data[0].length, r: space.r + data.length + 10 },
    };
    if (range.s.c < 10000000) ws["!ref"] = XLSXSTYLE.utils.encode_range(range);
    // ws["!merges"] = [
    //   // 合併欄位
    //   { s: { c: 4, r: 0 }, e: { c: 7, r: 0 } },
    // ];

    // 每行 寬度
    ws["!cols"] = [
      { wch: 23 },
      { wch: 40 },
      { wch: 10 },
      { wch: 30 },
      { wch: 25 },
      { wch: 25 },
    ];

    return ws;
  };

  // 匯出資料
  const formatExport = () => {
    dispatch(setProgress(true));

    let params = cleanObj(search);
    params.page = -1;
    delete params.size;
    InventoryAPI.getInventoryList({ data: params }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        if (data.length === 0) {
          dispatch(setProgress(false));
          return;
        }

        let columns = [
          { name: "Pallet No.", key: "pallet_no" },
          { name: "SKU", key: "sku" },
          { name: "Qty", key: "qty" },
          { name: "Notes", key: "notes" },
          { name: "Shipment Name", key: "shipment_name" },
          { name: "Plan Date", key: "plan_date" },
        ];
        let head = [],
          body = [],
          count = 0;
        data.forEach((item, index) => {
          /*
           * Not completed：匯 shipment_name 不存在的資料
           * Completed：匯 shipment_name、qty 存在的資料
           */
          if (
            (search.isshipped === 0 && item.shipment_name) ||
            (search.isshipped === 1 && (!item.shipment_name || !item.qty))
          )
            return;

          let row = [];
          columns.forEach((column) => {
            if (count === 0) {
              head.push(column.name);
            }

            let value = item[column.key];
            if (column.key === "plan_date") {
              value = value ? moment(value).format("MM/DD/YYYY") : "";
            }

            row.push(value);
          });

          body.push(row);
          count++;
        });
        body.unshift(head);

        let ws = sheet_from_array_of_arrays(body);
        let setting = {
          filename: `Pallet_${
            search.isshipped === 0 ? "uncompleted" : "completed"
          }_${moment().format("YYYYMMDD")}`,
          ws: ws,
        };
        saveExcel(setting, () => dispatch(setProgress(false)));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let updateData = [];
    tbData.forEach((item, index) => {
      let data = submitData.data[index];
      let update_flag = checkFormUpdate({
        newData: data,
        oldData: item,
      });
      if (update_flag) {
        updateData.push({
          id: item.id,
          notes: data.notes,
          shipment_name: data.shipment_name,
          shipment_id: data.shipment_id,
        });
      }
    });
    saveAction({ data: updateData });
  };

  // 整理單筆資料
  const saveSingleAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];

    let jsonData = {
      id: data.id,
      [apiKey]: data[apiKey],
    };

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    saveAction(jsonData);
  };

  // 儲存資料
  const saveAction = (submitData) => {
    dispatch(setProgress(true));

    InventoryAPI.updateInventorySingle({ data: submitData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        // dispatch(
        //   setToast({
        //     severity: "success",
        //     summary: "儲存成功",
        //     detail: "",
        //   })
        // );
        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 確認 shipment_name 是否已被使用
  const checkShipmentName = () => {
    dispatch(setProgress(true));

    let jsonData = {
      shipment_nameList: [],
    };

    let submitData = getValues(),
      validData = [];
    submitData.data.forEach((item) => {
      if (item.ship_flag) {
        jsonData.shipment_nameList.push(item.shipment_name);
        validData.push(item);
      }
    });

    InventoryAPI.checkShipmentName({ data: jsonData }).then((result) => {
      let { Response, shipment_nameList, message } = result;

      if (Response === 1) {
        if (shipment_nameList.length > 0) {
          // dispatch(
          //   setToast({
          //     severity: "warn",
          //     summary: "Shipment Name 重複",
          //     detail: `重複名稱：${shipment_nameList.join("、")}`,
          //   })
          // );
          openPop({
            type: "duplicate",
            data: validData,
            duplicateName: shipment_nameList,
          });
          dispatch(setProgress(false));
        } else {
          confirmToShip(validData);
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 確認出貨
  const confirmToShip = (validData) => {
    let jsonData = {
      idList: [],
    };

    validData.forEach((item) => {
      jsonData.idList.push(item.id);
    });

    InventoryAPI.confirmToShip({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "出貨成功",
            detail: "",
          })
        );
        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "出貨失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 表格 列 樣式
  const tbRowClass = (data) => {
    return {
      "bg-yellow-200": data.mark_flag,
      "opacity-40": data.cancel_flag,
    };
  };

  return (
    <>
      <ConfirmPopup
        {...confirmOption}
        onHide={() =>
          setConfirmOption((state) => ({ ...state, visible: false }))
        }
      />

      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <InputText
            name="keywords"
            className="w-full"
            value={filterData.keywords}
            onChange={(e) => changeFilter(e)}
            placeholder="Keyword"
          />
        </div>
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <Dropdown
            name="warehouse_id"
            className="w-full"
            value={filterData.warehouse_id}
            onChange={(e) => changeFilter(e)}
            options={optionData.warehouse}
            optionLabel="code"
            optionValue="id"
            placeholder="Select Warehouse"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div>
        <div className="flex col-12 sm:col-6 md:col-3 lg:col-5 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
          <Button
            className={classNames("px-4 ml-2", {
              "p-button-secondary": filterData.isshipped === 0,
              "p-button-outlined": filterData.isshipped === 0,
            })}
            label={filterData.isshipped === 0 ? "Not Completed" : "Completed"}
            onClick={() => changeComplete()}
          />
        </div>
        <div className="flex col-12 sm:col-6 md:col-3 lg:col-3 pr-0">
          <Button
            className="p-button-primary ml-auto"
            label="匯出"
            onClick={() => formatExport()}
          />

          {filterData.isshipped === 0 && (
            <Button
              className="p-button-primary ml-2"
              label="Check Ship"
              onClick={() => checkShipmentName()}
              disabled={!shipChecked}
            />
          )}
        </div>
      </div>

      <div className="mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataTable
            value={tbData}
            className={classNames("size-sm", {
              "table-empty": tbData.length === 0,
            })}
            size="normal"
            paginator
            paginatorTemplate={ModuleTablePaginator}
            currentPageReportTemplate="Total {totalRecords} items"
            // "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            // rowsPerPageOptions={[25, 50, 100, 200, "All"]}
            rowClassName={tbRowClass}
            lazy
            first={tbFirstIndex}
            totalRecords={tbTotal}
            onPage={onPage}
            rows={tbRows}
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            scrollDirection="both"
            responsiveLayout="scroll"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column key={`${item.field}_${index}`} {...item} />
            ))}
          </DataTable>

          {tbData.length === 0 && <ModuleTableEmpty />}

          {/* <div className="flex mt-2">
            <Button className="px-3 ml-auto" type="submit" label="Save" />
          </div> */}
        </form>
      </div>

      <Dialog
        header={
          <div className="flex align-items-center">
            <h3 className="my-0">
              {popOption.type === "edit"
                ? "Split Row"
                : popOption.type === "duplicate"
                ? "Check Ship"
                : ""}
            </h3>
            {isPop.duplicate && (
              <div className="flex align-items-center text-lg font-normal ml-4">
                <Checkbox
                  inputId="forceShip"
                  checked={isForceShip}
                  onChange={(e) => setIsForceShip(e.checked)}
                />
                <label htmlFor="forceShip" className="white-space-nowrap ml-2">
                  force ship
                </label>
              </div>
            )}
          </div>
        }
        headerClassName="border-300"
        contentClassName="p-0"
        visible={isPop.edit || isPop.duplicate}
        className={classNames("w-11", {
          "max-w-30rem": isPop.edit,
        })}
        onHide={() => closePop({ type: popOption.type })}
        // closable={false}
      >
        {isPop.edit ? (
          <InventoryPalletPopEdit
            closePrePop={closePop}
            prePopOption={popOption}
            setPreReload={setReload}
          />
        ) : isPop.duplicate ? (
          <InventoryPalletPopDuplicate
            closePrePop={closePop}
            prePopOption={popOption}
            setPreReload={setReload}
            isForceShip={isForceShip}
            optionData={optionData}
            palletExportColumns={palletExportColumns}
            confirmToShip={confirmToShip}
          />
        ) : null}
      </Dialog>
    </>
  );
}
