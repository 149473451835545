export const carrierExportColumns = {
  name: {
    name: "Name",
    value: "Name",
    apiKey: "name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 1,
  },
  type: {
    name: "Type",
    value: "Type",
    apiKey: "type",
    uiType: "select",
    options: "shipping_method",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 2,
  },
  // status: {
  //   name: "Status",
  //   value: "Status",
  //   apiKey: "status",
  //   uiType: "select",
  //   options: "open",
  //   optionType: "number",
  //   isShow: true,
  //   checked: true,
  //   listDisabled: false,
  //   listUI: false,
  //   required: true,
  //   width: 100,
  //   order: 3,
  // },
};
