import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderAPI, DailyAPI } from "../../../axios/api";
import { AxiosMulti } from "../../../axios/request/multi";
import axios from "axios";

import { FormInputnumber } from "../../formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function EditPallet(props) {
  const {
    palletType, // 類型 (vc、newOrders)
    palletData = {
      quantity_requested: 0, // 出貨量
      inventoryData: [], // 可選擇的 pallet 資料
      chooseData: [], // 選擇的 pallet 資料
    },
    optionData = {}, // 選單資料
    reloadFun = () => {}, // 重撈資料
  } = props;
  const dispatch = useDispatch();
  // 是否可送出
  const [isChecked, setIsChecked] = useState(false);
  const palletIDKey = {
    vc: "id",
    newOrders: "daily_id",
  };
  // 全部 pallet
  const [totalData, setTotalData] = useState([]);
  // 表單設定
  const defaultValues = {
    data: [],
  };
  const [schema, setSchema] = useState(
    yup
      .object({
        // data: yup.array().of(
        //   yup.object().shape({
        //     // title: yup.string().required("必填"),
        //     // content: yup.string().required("必填"),
        //     // img_url: yup.string().required("必填"),
        //   })
        // ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });
  // 監聽欄位
  let watchColumns = {
    checkAll: watch("checkAll"),
    data: watch("data"),
  };

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useEffect(() => {
    let tempTotal = JSON.parse(JSON.stringify(palletData.inventoryData));
    palletData.chooseData.forEach((chooseItem) => {
      let matchIdx = tempTotal.findIndex(
        (inventory) => inventory.id === chooseItem.inventoryData.parent_id
      );
      if (matchIdx !== -1) {
        tempTotal[matchIdx].qty += chooseItem.inventoryData.qty;
      } else {
        tempTotal.push({
          id: chooseItem.inventoryData.parent_id,
          warehouse_id: chooseItem.inventoryData.warehouse_id,
          pallet_no: chooseItem.inventoryData.pallet_no,
          notes: chooseItem.inventoryData.notes,
          qty: chooseItem.inventoryData.qty,
        });
      }
    });
    // 重整排序
    tempTotal.sort(function (pre, next) {
      return pre.id - next.id;
    });
    setTotalData(tempTotal);

    let columns = [],
      isNew = palletData.chooseData.length === 0,
      remain_qty = palletData.quantity_requested;
    tempTotal.forEach((item, index) => {
      let match = palletData.chooseData.find(
        (chooseItem) => chooseItem.inventoryData?.parent_id === item.id
      );

      // 若尚未新增過，直接帶入需求數量
      let qty = 0;
      if (isNew) {
        qty = item.qty >= remain_qty ? remain_qty : item.qty;
        remain_qty -= qty;
      }

      columns.push({
        id: match ? match.inventory_id : item.id,
        type: match ? "update" : "add",
        qty: match ? match.inventoryData.qty : qty,
      });
    });
    reset({ data: columns });
    checkQty();
  }, [palletData]);

  useEffect(() => {}, [palletData]);

  // 檢查數量
  const checkQty = () => {
    let submitData = getValues();
    let sum = submitData.data.reduce((pre, cur) => (pre += cur.qty), 0);

    setIsChecked(sum === palletData.quantity_requested);
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      [palletIDKey[palletType]]: palletData[palletIDKey[palletType]],
      data: {
        delete: [],
        update: [],
        insert: [],
      },
    };

    if (palletType === "newOrders") jsonData.type = palletData.type;

    submitData.data.forEach((item) => {
      if (item.type === "update" && !item.qty) {
        jsonData.data.delete.push({
          split_inventory_id: item.id,
        });
      } else if (item.type === "update") {
        jsonData.data.update.push({
          split_inventory_id: item.id,
          qty: item.qty,
        });
      } else if (item.type === "add" && item.qty) {
        jsonData.data.insert.push({
          inventory_id: item.id,
          qty: item.qty,
        });
      }
    });

    updateInventory({ type: "submit", jsonData });
  };

  // 退庫存
  const backInventory = () => {
    dispatch(setProgress(true));

    let jsonData = {
      [palletIDKey[palletType]]: palletData[palletIDKey[palletType]],
      data: {
        delete: palletData.chooseData.map((item) => ({
          split_inventory_id: item.inventory_id,
        })),
        update: [],
        insert: [],
      },
    };

    if (palletType === "newOrders") jsonData.type = palletData.type;

    updateInventory({ type: "back", jsonData });
  };

  // 更新庫存
  const updateInventory = ({ type, jsonData }) => {
    let promise = [];
    if (palletType === "vc") {
      // let deleteData = jsonData.data.delete.map(
      //     (item) => item.split_inventory_id
      //   ),
      //   updateZeroData = jsonData.data.update
      //     .filter((item) => item.qty === 0)
      //     .map((item) => item.split_inventory_id),
      //   insertData = jsonData.data.insert
      //     .filter((item) => item.qty > 0)
      //     .map((item) => item.inventory_id),
      //   nowWarehouse = [
      //     ...palletData.chooseData
      //       .filter(
      //         (item) =>
      //           !deleteData.includes(item.inventoryData.id) &&
      //           !updateZeroData.includes(item.inventoryData.id)
      //       )
      //       .map((item) => item.inventoryData.warehouse_id),
      //     ...palletData.inventoryData
      //       .filter((item) => insertData.includes(item.id))
      //       .map((item) => item.warehouse_id),
      //   ].filter((item, index, array) => array.indexOf(item) === index);

      promise = [OrderAPI.updateVcInventory({ data: jsonData })];
      // if (type === "back" || nowWarehouse.length === 1) {
      //   promise.push(
      //     OrderAPI.updateVcOrderSingle({
      //       data: {
      //         id: jsonData.id,
      //         warehouse_id: type === "back" ? null : nowWarehouse[0],
      //       },
      //     })
      //   );
      // } else {
      //   dispatch(
      //     setToast({
      //       severity: "warn",
      //       summary: "Pallet 來自不同的 Warehouse",
      //       detail: "請自行選擇 Warehouse",
      //     })
      //   );
      // }
    } else if (palletType === "newOrders") {
      promise = [DailyAPI.updateDailyInventory({ data: jsonData })];
    }

    AxiosMulti(promise).then(
      axios.spread(
        (
          result_inventory = { Response: 1 },
          result_order = { Response: 1 }
        ) => {
          if (result_inventory.Response === 1 && result_order.Response === 1) {
            const { parent_data, child_data } = result_inventory;
            reloadFun({ parent_data, child_data });
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: result_inventory.message,
                detail: "",
              })
            );
            dispatch(setProgress(false));
          }
        }
      )
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="flex align-items-center border-bottom-1 border-300 px-3 py-2">
          <div className="font-bold">Select Pallet Numbers</div>
          <div className="ml-auto">{palletData.quantity_requested}</div>
        </div>
        <div className="px-3 py-2">
          {totalData.length > 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              {totalData.map((item, index) => (
                <div
                  key={`pallet_item_${index}`}
                  className={classNames("flex py-1", {
                    "border-top-1 border-300": index !== 0,
                  })}
                >
                  <div className="w-5rem">
                    {optionData.warehouse_obj?.[item.warehouse_id].showLabel}
                  </div>
                  <div className="w-8rem">{item.pallet_no}</div>
                  <div className="w-6rem flex-auto">{item.notes}</div>
                  <div className="w-3rem text-right">{item.qty}</div>
                  <div>
                    <FormInputnumber
                      className="p-sm ml-2"
                      setting={setting}
                      data={{
                        uiClassName: "w-3rem ui-text-right",
                        name: `data[${index}].qty`,
                        max: item.qty,
                        changeFun: () => checkQty(),
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="flex mt-2">
                <Button
                  className="p-button-danger w-full py-1"
                  type="button"
                  label="退庫存"
                  onClick={() => backInventory()}
                  disabled={palletData.chooseData.length === 0}
                />
                <Button
                  className="w-full py-1 ml-2"
                  type="submit"
                  label="扣庫存"
                  disabled={!isChecked}
                />
              </div>
            </form>
          ) : (
            <div className="text-center text-gray-500 py-2">No Data</div>
          )}
        </div>
      </div>
    </>
  );
}
