import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonAPI, InventoryAPI } from "../../axios/api";

import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
// import ModuleFileTransfer from "../../components/fileTransfer";
import { FormInputtext, FormDropdown } from "../../components/formElement";
import { useAbortedEffect } from "../../components/hooks";
import {
  receiveImportColumns,
  receiveExportColumns,
} from "../../service/columns/inventory/receive";
import { system_table, system_dropdown } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../service/table";
import { readExcelFile } from "../../service/excel";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function InventoryReceive(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Inventory" }, { label: "Gross" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 匯入匯出 模組資料
  // const [moduleData, setModuleData] = useState({
  //   importTemplate: {},
  //   exportTemplate: {},
  // });
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});
  // 確認資訊
  const [confirmOption, setConfirmOption] = useState({});
  // 表單設定
  const defaultValues = {
    name: "",
    warehouse_id: null,
    isFile: false,
    fileData: { keys: [], data: [] },
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        name: yup.string().required("必填"),
        warehouse_id: yup.number().required("必填").nullable(),
        isFile: yup.boolean().oneOf([true], "必填"),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useAbortedEffect((signal) => {
    CommonAPI.getWarehouse({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let temp = formatOptions(
          {
            warehouse: data,
          },
          { uniqueKey: "id", showLabel: "code" }
        );
        setOptionData(temp);
        setValue("warehouse_id", data[0]?.id);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  }, []);

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };
    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/inventory/receive"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: search.isTotal ? -1 : search.page,
        size: search.isTotal ? -1 : search.size,
      };
      delete params.isTotal;

      setPageSetting({ params, page: "/inventory/receive" });

      InventoryAPI.getReceive({ data: params, options: { signal } }).then(
        (result) => {
          let { Response, data, total, message } = result;

          if (Response === 1 && data) {
            if (search.isTotal) {
              setTbRows(total);
            }

            setTbData(data);
            setTbTotal(total);
            setReload((state) => ({
              ...state,
              list: false,
            }));
            dispatch(setProgress(false));
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          }

          dispatch(setProgress(false));
        }
      );
    },
    [reload]
  );

  // 取得回傳的模板資料
  // const getTemplate = ({ importTemplate = [], exportTemplate = [] } = {}) => {
  //   console.log("module", importTemplate, exportTemplate);

  //   setModuleData({ importTemplate, exportTemplate });
  // };

  // 設定列表欄位
  useEffect(() => {
    // let entries = Object.values(moduleData.exportTemplate.settings ?? {});
    let entries = Object.values(receiveExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "no",
      activeTemplate: entries,
      defaultTemplate: receiveExportColumns,
      optionData: optionData,
      tbRows,
    });
    tempColumns.push({
      field: "id",
      header: "",
      className: "",
      style: {
        width: 50,
        minWidth: 50,
      },
      body: (data, { rowIndex }) => (
        <>
          {data.status === 1 && (
            <Button
              id={`button_back_${rowIndex % tbRows}`}
              type="button"
              icon="fas fa-reply"
              className="p-button-danger p-button-sm p-button-icon-only p-button-outlined w-auto px-2 py-0"
              onClick={() =>
                setTimeout(() => {
                  setConfirmOption({
                    target: document.getElementById(
                      `button_back_${rowIndex % tbRows}`
                    ),
                    message: "確認是否退刪除?",
                    icon: "pi pi-exclamation-triangle",
                    visible: true,
                    acceptLabel: "是",
                    rejectLabel: "否",
                    accept: () => backReceive({ id: data.id }),
                    reject: () => null,
                  });
                }, 200)
              }
            />
          )}
        </>
      ),
    });
    setTbColumns(tempColumns);
  }, [optionData, tbRows]); // moduleData

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    // 比對匯入欄位
    let errorData = { keys: [], columns: {} };
    let entries = Object.values(receiveImportColumns);
    entries.forEach((key) => {
      let isMatch = submitData.fileData.keys.indexOf(key.value) !== -1;
      // 比對必填欄位是否存在
      if (!isMatch && key.required) {
        errorData.keys.push(key.value);
      }

      // 比對必填欄位值是否存在
      if (isMatch && key.required) {
        submitData.fileData.data.forEach((row, rowIdx) => {
          if (
            row[key.value] === "" ||
            row[key.value] === undefined ||
            row[key.value] === null
          ) {
            if (!errorData.columns[key.value])
              errorData.columns[key.value] = [];
            errorData.columns[key.value].push(rowIdx + 1);
          }
        });
      }
    });
    if (errorData.keys.length > 0) {
      dispatch(
        setToast({
          severity: "error",
          summary: "Excel 缺少欄位",
          detail: errorData.keys.join("、"),
        })
      );
      dispatch(setProgress(false));
      return;
    } else if (Object.keys(errorData.columns).length > 0) {
      let errorColumns = Object.entries(errorData.columns),
        detail = "";
      errorColumns.forEach((column) => {
        if (detail) detail += "\n";
        detail += `${column[0]}：第 ${column[1].join("、")} 列`;
      });
      dispatch(
        setToast({
          severity: "error",
          summary: "必填欄位未填",
          detail: detail,
        })
      );
      dispatch(setProgress(false));
      return;
    }

    let jsonData = {
      name: submitData.name,
      warehouse_id: submitData.warehouse_id,
      data: formatTemplateColumns({
        type: "import",
        template: receiveImportColumns,
        data: submitData.fileData.data,
      }),
    };

    InventoryAPI.setReceive({ data: jsonData }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "儲存成功",
            detail: "",
          })
        );
        reset(defaultValues);
        document.getElementById("selectFile").value = "";
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 退庫存
  const backReceive = ({ id }) => {
    dispatch(setProgress(true));

    let jsonData = {
      id: id,
    };

    InventoryAPI.backReceive({ data: jsonData }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "退庫存成功",
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "退庫存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 選擇匯入檔案
  const fileChange = (e) => {
    let file = e.target.files[0];
    let data = readExcelFile(file, 0);

    data.then((result) => {
      setValue("isFile", true);
      setValue("fileData", result);
      trigger("isFile");
    });
  };

  return (
    <>
      <ConfirmPopup
        {...confirmOption}
        onHide={() =>
          setConfirmOption((state) => ({ ...state, visible: false }))
        }
      />

      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid mr-0">
          <div className="col-12 sm:col-3 md:col-3 lg:col-2 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "name", placeholder: "Name" }}
            />
          </div>
          <div className="col-12 sm:col-3 md:col-2 lg:col-2 xl:col-1 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "warehouse_id",
                options: optionData.warehouse,
                optionLabel: "code",
                optionValue: "id",
                showClear: false,
              }}
            />
          </div>
          <div className="flex col-12 sm:col-3 md:col-3 lg:col-2 pr-0">
            <Button
              type="button"
              icon="pi pi-folder-open"
              className={classNames(
                { "p-invalid": errors.isFile },
                "p-button-gray p-button-icon-only p-button-outlined px-4"
              )}
              onClick={() => document.getElementById("selectFile").click()}
            />
            <input
              id="selectFile"
              type="file"
              accept=".xlsx"
              onChange={(e) => fileChange(e)}
              hidden
            />
            <Button type="submit" label="Receive" className="ml-2 px-4" />
          </div>
        </div>
      </form>

      <div className="mt-3">
        <DataTable
          value={tbData}
          className={classNames("size-sm w-12 xl:w-7", {
            "table-empty": tbData.length === 0,
          })}
          size="normal"
          paginator
          paginatorTemplate={ModuleTablePaginator}
          currentPageReportTemplate="Total {totalRecords} items"
          lazy
          first={tbFirstIndex}
          totalRecords={tbTotal}
          onPage={onPage}
          rows={tbRows}
          scrollable
          scrollHeight="calc(100vh - 20rem)"
          scrollDirection="both"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column key={`${item.field}_${index}`} {...item} />
          ))}
        </DataTable>

        {tbData.length === 0 && (
          <div className="w-12 xl:w-7">
            <ModuleTableEmpty />
          </div>
        )}
      </div>
    </>
  );
}
