export const overviewExportColumns = {
  sr: {
    name: "SR",
    value: "SR",
    apiKey: "sr",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 1,
  },
  isshipped: {
    name: "已出貨",
    value: "isShipped",
    apiKey: "isshipped",
    uiType: "check",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 80,
    order: 2,
  },
  isreceived: {
    name: "已收到",
    value: "isReceived",
    apiKey: "isreceived",
    uiType: "check",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 60,
    order: 3,
  },
  order_platform: {
    name: "平台",
    value: "order_platform",
    apiKey: "order_platform",
    uiType: "select",
    options: "platform",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 70,
    order: 4,
  },
  order_id: {
    name: "訂單號(Order ID)",
    value: "order_id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 170,
    order: 5,
  },
  date: {
    name: "日期(Date)",
    value: "date",
    apiKey: "date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 6,
  },
  amazon_sku: {
    name: "Amazon SKU",
    value: "amazon_SKU",
    apiKey: "amazon_sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 7,
  },
  model: {
    name: "外箱印刷型號(Model)",
    value: "model",
    apiKey: "model",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 8,
  },
  qty: {
    name: "數量(Qty)",
    value: "qty",
    apiKey: "qty",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 9,
  },
  daily_inventoryList: {
    name: "Pallet Number",
    value: "PalletNos",
    apiKey: "daily_inventoryList",
    uiType: "pallet",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 330,
    order: 10,
  },
  wayfair_sku: {
    name: "Wayfair SKU",
    value: "wayfair_SKU",
    apiKey: "wayfair_sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 230,
    order: 11,
  },
  customer_name: {
    name: "客人名稱(Customer Name)",
    value: "customer_name",
    apiKey: "customer_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 190,
    order: 12,
  },
  amount: {
    name: "產品總價(Amount)",
    value: "amount",
    apiKey: "amount",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 130,
    order: 13,
  },
  total: {
    name: "總金額(total)",
    value: "total",
    apiKey: "total",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 14,
  },
  promote: {
    name: "折扣(Promo)",
    value: "promo",
    apiKey: "promote",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 15,
  },
  refund: {
    name: "退款金額(Refund)",
    value: "refund",
    apiKey: "refund",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 16,
  },
  note: {
    name: "備註(Notes)",
    value: "note",
    apiKey: "note",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 17,
  },
  length: {
    name: "L",
    value: "length",
    apiKey: "length",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 18,
  },
  width: {
    name: "W",
    value: "width",
    apiKey: "width",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 19,
  },
  high: {
    name: "H",
    value: "high",
    apiKey: "high",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 20,
  },
  weight: {
    name: "重量",
    value: "weight",
    apiKey: "weight",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 21,
  },
  girth: {
    name: "Girth",
    value: "girth",
    apiKey: "girth",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 80,
    order: 22,
  },
  carrier: {
    name: "貨運商(Carrier)",
    value: "carrier",
    apiKey: "carrier",
    uiType: "select",
    options: "carriers",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 120,
    order: 23,
  },
  tracking: {
    name: "追蹤碼(Tracking)",
    value: "tracking",
    apiKey: "tracking",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 24,
  },
  tracking_link: {
    name: "連結",
    value: "tracking_link",
    apiKey: "tracking_link",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 60,
    order: 25,
  },
  isinsurance: {
    name: "保險",
    value: "isInsurance",
    apiKey: "isinsurance",
    uiType: "check",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 50,
    order: 26,
  },
  issign: {
    name: "簽名",
    value: "isSign",
    apiKey: "issign",
    uiType: "check",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 50,
    order: 27,
  },
  delivery_fee: {
    name: "運費",
    value: "delivery_fee",
    apiKey: "delivery_fee",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 28,
  },
  claim_id: {
    name: "求償ID(Claim ID)",
    value: "claim_id",
    apiKey: "claim_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 29,
  },
  claim_result: {
    name: "求償結果",
    value: "claim_result",
    apiKey: "claim_result",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 30,
  },
  mos_id: {
    name: "OMS ID",
    value: "mos_id",
    apiKey: "mos_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 31,
  },
  remittance_date_1: {
    name: "Remittance Date",
    value: "remittance_date_1",
    apiKey: "remittance_date_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 32,
  },
  remittance_1: {
    name: "第一次 Remittance",
    value: "remittance_1",
    apiKey: "remittance_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 33,
  },
  remittance_amount_1: {
    name: "Remittance Amount",
    value: "remittance_amount_1",
    apiKey: "remittance_amount_1",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 34,
  },
  discrepancy_1: {
    name: "Discrepancy",
    value: "discrepancy_1",
    apiKey: "discrepancy_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 35,
  },
  remittance_date_2: {
    name: "Remittance Date",
    value: "remittance_date_2",
    apiKey: "remittance_date_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 36,
  },
  remittance_2: {
    name: "第一次 Remittance",
    value: "remittance_2",
    apiKey: "remittance_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 37,
  },
  remittance_amount_2: {
    name: "Remittance Amount",
    value: "remittance_amount_2",
    apiKey: "remittance_amount_2",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 38,
  },
  discrepancy_2: {
    name: "Discrepancy",
    value: "discrepancy_2",
    apiKey: "discrepancy_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 39,
  },
  remittance_date_3: {
    name: "Remittance Date",
    value: "remittance_date_3",
    apiKey: "remittance_date_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 40,
  },
  remittance_3: {
    name: "第一次 Remittance",
    value: "remittance_3",
    apiKey: "remittance_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 41,
  },
  remittance_amount_3: {
    name: "Remittance Amount",
    value: "remittance_amount_3",
    apiKey: "remittance_amount_3",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 42,
  },
  discrepancy_3: {
    name: "Discrepancy",
    value: "discrepancy_3",
    apiKey: "discrepancy_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 43,
  },
  fnsku: {
    name: "fnsku",
    value: "fnsku",
    apiKey: "fnsku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 44,
  },
  warehouse: {
    name: "warehouse",
    value: "warehouse",
    apiKey: "warehouse",
    uiType: "select",
    options: "warehouse",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 45,
  },
  upc: {
    name: "upc",
    value: "upc",
    apiKey: "upc",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 46,
  },
};

export const wayfairFinancialImportColumns = {
  po_number: {
    name: "PO_number",
    value: "PO_number",
    apiKey: "po_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 180,
    order: 1,
  },
  remittance: {
    name: "remittance",
    value: "remittance",
    apiKey: "remittance",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 2,
  },
  remittance_date: {
    name: "remittance_date",
    value: "remittance_date",
    apiKey: "remittance_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD HH:mm:ss",
    showTime: true,
    showSeconds: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 3,
  },
  remittance_amount: {
    name: "remittance_amount",
    value: "remittance_amount",
    apiKey: "remittance_amount",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 4,
  },
};

export const wayfairFinancialExportColumns = {
  order_id: {
    name: "訂單號(Order ID)",
    value: "order_id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 170,
    order: 1,
  },
  remittance_date_1: {
    name: "Remittance Date",
    value: "remittance_date_1",
    apiKey: "remittance_date_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 2,
  },
  remittance_1: {
    name: "第一次 Remittance",
    value: "remittance_1",
    apiKey: "remittance_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 3,
  },
  remittance_amount_1: {
    name: "Remittance Amount",
    value: "remittance_amount_1",
    apiKey: "remittance_amount_1",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 4,
  },
  discrepancy_1: {
    name: "Discrepancy",
    value: "discrepancy_1",
    apiKey: "discrepancy_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 5,
  },
  remittance_date_2: {
    name: "Remittance Date",
    value: "remittance_date_2",
    apiKey: "remittance_date_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 6,
  },
  remittance_2: {
    name: "第二次 Remittance",
    value: "remittance_2",
    apiKey: "remittance_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 7,
  },
  remittance_amount_2: {
    name: "Remittance Amount",
    value: "remittance_amount_2",
    apiKey: "remittance_amount_2",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 8,
  },
  discrepancy_2: {
    name: "Discrepancy",
    value: "discrepancy_2",
    apiKey: "discrepancy_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 9,
  },
  remittance_date_3: {
    name: "Remittance Date",
    value: "remittance_date_3",
    apiKey: "remittance_date_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 10,
  },
  remittance_3: {
    name: "第三次 Remittance",
    value: "remittance_3",
    apiKey: "remittance_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 11,
  },
  remittance_amount_3: {
    name: "Remittance Amount",
    value: "remittance_amount_3",
    apiKey: "remittance_amount_3",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 12,
  },
  discrepancy_3: {
    name: "Discrepancy",
    value: "discrepancy_3",
    apiKey: "discrepancy_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 150,
    order: 13,
  },
};

export const shippedFeeImportColumns = {
  order_id: {
    name: "訂單號(Order ID)",
    value: "order_id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 1,
  },
  delivery_fee: {
    name: "運費",
    value: "delivery_fee",
    apiKey: "delivery_fee",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 2,
  },
};

export const shippedFeeExportColumns = {
  order_id: {
    name: "訂單號(Order ID)",
    value: "order_id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 170,
    order: 1,
  },
  delivery_fee: {
    name: "運費",
    value: "delivery_fee",
    apiKey: "delivery_fee",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 100,
    order: 2,
  },
};

export const trackingImportColumns = {
  order_id: {
    name: "訂單號(Order ID)",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 1,
  },
  carrier: {
    name: "貨運商(Carrier)",
    value: "Carrier",
    apiKey: "carrier",
    uiType: "select",
    options: "carriers",
    optionType: "number",
    optionTransfer: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 2,
  },
  tracking: {
    name: "追蹤碼(Tracking)",
    value: "Tracking",
    apiKey: "tracking",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 3,
  },
};

export const trackingExportColumns = {
  order_id: {
    name: "訂單號(Order ID)",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 170,
    order: 1,
  },
  carrier: {
    name: "貨運商(Carrier)",
    value: "Carrier",
    apiKey: "carrier",
    uiType: "select",
    options: "carriers",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 120,
    order: 2,
  },
  tracking: {
    name: "追蹤碼(Tracking)",
    value: "Tracking",
    apiKey: "tracking",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: false,
    width: 200,
    order: 3,
  },
};
