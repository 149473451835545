import request from "../request";
import Url from "../url/inventory";

const getGross = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getGross + path, data, options);
};

const getWarehouseStock = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getWarehouseStock + path, data, options);
};

const getInventoryList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getInventoryList + path, data, options);
};
const updateInventorySingle = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateInventorySingle + path, data, options);
};
const updateInventoryMulti = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateInventoryMulti + path, data, options);
};
const splitInventory = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.splitInventory + path, data, options);
};
const revokeSplitInventory = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.revokeSplitInventory + path, data, options);
};
const checkShipmentName = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.checkShipmentName + path, data, options);
};
const confirmToShip = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.confirmToShip + path, data, options);
};

const getReceive = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getReceive + path, data, options);
};
const setReceive = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setReceive + path, data, options);
};
const backReceive = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.backReceive + path, data, options);
};

export default {
  getGross,

  getWarehouseStock,

  getInventoryList,
  updateInventorySingle,
  updateInventoryMulti,
  splitInventory,
  revokeSplitInventory,
  checkShipmentName,
  confirmToShip,

  getReceive,
  setReceive,
  backReceive,
};
