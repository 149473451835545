import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosMulti } from "../../../axios/request/multi";
import { OrderAPI, CommonAPI } from "../../../axios/api";
import * as XLSXSTYLE from "xlsx-js-style";
import moment from "moment";
import axios from "axios";

import ModuleBreadCrumb from "../../../components/module/breadCrumb";
import ModuleTableEmpty from "../../../components/module/table/empty";
import ViewPallet from "../../../components/module/pallet/viewPallet";
import EditPallet from "../../../components/module/pallet/editPallet";
import {
  FormInputtext,
  FormCheckbox,
  FormDropdown,
  FormInputnumber,
} from "../../../components/formElement";
import { useAbortedEffect } from "../../../components/hooks";
import {
  vcImportColumns,
  vcExportColumns,
} from "../../../service/columns/order/vc";
import { system_table, system_dropdown } from "../../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  cleanObj,
  checkFormUpdate,
  checkColumnUpdate,
} from "../../../service/common";
import { com_downloadFile } from "../../../service/download/downloadFile";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../../service/table";
import {
  option_confirm_type,
  option_accept_reject,
  option_shipping_method,
  option_shipping_unit_ground,
  option_shipping_unit_freight,
} from "../../../service/option";
import { readExcelFile, saveExcel, datenum } from "../../../service/excel";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

export default function OrderVc(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Order" }, { label: "VC" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    dateRange: [],
    statusList: null,
    is_rejected: null,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});
  // confirm order line
  const [confirmChecked, setConfirmChecked] = useState(false);
  // 上傳檔案資料
  const [fileData, setFileData] = useState({ keys: [], data: [] });
  // pallet pop
  const pallet_op = useRef(null);
  const [overlayData, setOverlayData] = useState({});
  // 表單設定
  const defaultValues = {
    checkAll: false,
    data: [],
  };
  const [schema, setSchema] = useState(
    yup
      .object({
        // data: yup.array().of(
        //   yup.object().shape({
        //     // title: yup.string().required("必填"),
        //     // content: yup.string().required("必填"),
        //     // img_url: yup.string().required("必填"),
        //   })
        // ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useAbortedEffect((signal) => {
    AxiosMulti([
      CommonAPI.getWarehouse({
        data: { page: -1, status: 1 },
        options: { signal },
      }),
      CommonAPI.getCarrier({
        data: { page: -1, status: 1 },
        options: { signal },
      }),
    ]).then(
      axios.spread((result1, result2) => {
        let temp = {};
        if (result1) {
          let { Response, data, total, message } = result1;

          if (Response === 1 && data) {
            temp = {
              ...temp,
              ...formatOptions(
                {
                  warehouse: data,
                },
                { uniqueKey: "id", showLabel: "name" }
              ),
            };
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
            return;
          }
        }

        if (result2) {
          let { Response, data, total, message } = result2;

          if (Response === 1 && data) {
            let carrier_ground = [],
              carrier_freight = [];
            data.forEach((item) => {
              if (item.type === 1) carrier_ground.push(item);
              else if (item.type === 2) carrier_freight.push(item);
            });

            temp = {
              ...temp,
              ...formatOptions(
                {
                  carrier_ground: carrier_ground,
                  carrier_freight: carrier_freight,
                },
                { uniqueKey: "id", showLabel: "name" }
              ),
            };
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
            return;
          }
        }

        temp = {
          ...temp,
          ...formatOptions(
            {
              confirm_type: option_confirm_type,
              accept_reject: option_accept_reject,
              shipping_method: option_shipping_method,
              shipping_unit_ground: option_shipping_unit_ground,
              shipping_unit_freight: option_shipping_unit_freight,
            },
            { uniqueKey: "value", showLabel: "label" }
          ),
        };
        setOptionData(temp);
      })
    );
  }, []);

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setConfirmChecked(false);
    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/order/vc"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      if (result.params.startDate && result.params.endDate) {
        result.params.dateRange = [
          new Date(result.params.startDate),
          new Date(result.params.endDate),
        ];
      }
      delete result.params.startDate;
      delete result.params.endDate;
      result.params.statusList =
        result.params.statusList?.split(",").map((item) => Number(item)) ?? [];

      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: search.isTotal ? -1 : search.page,
        size: search.isTotal ? -1 : search.size,
      };
      if (search.dateRange && search.dateRange[0] && search.dateRange[1]) {
        params.startDate = moment(search.dateRange[0]).format("YYYY/MM/DD");
        params.endDate = moment(search.dateRange[1]).format("YYYY/MM/DD");
      } else {
        delete params.startDate;
        delete params.endDate;
      }
      if (search.statusList) {
        params.statusList = search.statusList.join(",");
      }
      delete params.dateRange;
      params = cleanObj(params);

      setPageSetting({ params, page: "/order/vc" });

      OrderAPI.getVcOrderList({ data: params, options: { signal } }).then(
        (result) => {
          let { Response, data, total, message } = result;

          if (Response === 1 && data) {
            data.forEach((item) => {
              item.confirm_flag = false;

              // 狀態
              item.status = item.vc_shipping_status ?? item.status;

              // 欄位值固定
              item.mancr_price = 0;
              item.checking_price = "High";

              // pallet 數量是否足夠
              let vc_inventoryList_sum = item.vc_inventoryList.reduce(
                (pre, cur) => pre + cur.inventoryData.qty,
                0
              );
              let inventoryList_sum = item.inventoryList.reduce(
                (pre, cur) => pre + cur.qty,
                0
              );
              item.pallet_enough =
                item.vc_inventoryList.length > 0
                  ? vc_inventoryList_sum === item.expected_quantity
                  : inventoryList_sum >= item.expected_quantity;
            });

            document.getElementById("btn_multi_reject").style.display = "none";

            if (search.isTotal) {
              setTbRows(total);
            }

            setTbData(data);
            setTbTotal(total);
            reset({ ...defaultValues, data: data });
            setReload((state) => ({
              ...state,
              list: false,
            }));
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          }

          dispatch(setProgress(false));
        }
      );
    },
    [reload]
  );

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(vcExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "single",
      saveFun: saveColumnAction,
      activeTemplate: entries,
      defaultTemplate: vcExportColumns,
      formSetting: setting,
      optionData: optionData,
      frozenSetting: {
        frozen: true,
        align: "left",
        column: ["createdatetime", "po"],
      },
      tbRows,
    });
    tempColumns.unshift({
      field: "confirm",
      header: () => (
        <FormCheckbox
          className="m-0"
          setting={setting}
          data={{
            name: "checkAll",
            label: "",
            type: "boolean",
            value: true,
            changeFun: (e) => checkChange("all", e),
          }}
        />
      ),
      className: "justify-content-center",
      style: {
        width: 30,
        minWidth: 30,
      },
      frozen: true,
      alignFrozen: "left",
      body: (data, { rowIndex }) => (
        <>
          {/*  && data.status === 1 */}
          {data.is_rejected !== 1 ? (
            <FormCheckbox
              className="m-0"
              setting={setting}
              data={{
                name: `data[${rowIndex % tbRows}].confirm_flag`,
                label: "",
                type: "boolean",
                value: true,
                changeFun: (e) => checkChange("single", e),
                // disabled:
                //   data.warehouse_id === null ||
                //   data.shipping_method === null ||
                //   data.vc_inventoryList.length === 0 ||
                //   !data.pallet_enough,
              }}
            />
          ) : null}
        </>
      ),
    });

    tempColumns.push({
      field: "id",
      header: () => (
        <>
          <Button
            id="btn_multi_reject"
            type="button"
            icon="pi pi-trash"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0"
            )}
            style={{ display: "none" }}
            onClick={() => rejectMultiAction()}
          />
        </>
      ),
      // className: "justify-content-center",
      style: {
        width: 180,
        minWidth: 180,
      },
      body: (data, { rowIndex }) => (
        <>
          <Button
            type="button"
            icon="pi pi-trash"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0 mr-2",
              {
                "border-transparent": data.is_rejected === 1,
              }
            )}
            onClick={() => rejectSingleAction({ rowIndex: rowIndex % tbRows })}
            disabled={
              data.is_rejected === 1 ||
              data.status === 12 ||
              data.status === 22 ||
              data.status === 40
            }
          />

          <Button
            type="button"
            icon="pi pi-download"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0 mr-2",
              {
                "border-transparent": data.is_rejected === 1,
              },
              `download_shipping_plan_${rowIndex}`
            )}
            onClick={() => downloadShipPlan({ rowIndex: rowIndex % tbRows })}
            disabled={data.is_rejected === 1 || data.status < 3}
          />
          <Tooltip
            target={`.download_shipping_plan_${rowIndex}`}
            position="top"
            content="Shipping Plan"
          />

          <Button
            type="button"
            icon="pi pi-envelope"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0 mr-2",
              {
                "border-transparent": data.is_rejected === 1,
              }
            )}
            onClick={() => sendEnvelope({ rowIndex: rowIndex % tbRows })}
            disabled={
              data.is_rejected === 1 ||
              (data.status !== 3 && data.status !== 10 && data.status !== 20) ||
              (data.status === 3 && data.shipping_method === 1) ||
              (data.status === 10 && !data.vc_shipping_carrier) ||
              (data.status === 20 &&
                (!data.vc_shipping_carrier || !data.vc_shipping_tracking))
            }
          />

          <Button
            type="button"
            icon="pi pi-folder-open"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0 mr-2",
              {
                "border-transparent": data.is_rejected === 1,
              }
            )}
            onClick={() =>
              document
                .getElementById(`selectPodFile_${rowIndex % tbRows}`)
                .click()
            }
            disabled={
              data.is_rejected === 1 ||
              data.status !== 11 ||
              (data.status === 11 && !data.vc_shipping_tracking)
            }
          />
          <input
            id={`selectPodFile_${rowIndex % tbRows}`}
            type="file"
            onChange={(e) =>
              fileChange(e, "pod", (file, result) =>
                uploadPODFile({
                  rowIndex: rowIndex % tbRows,
                  file,
                })
              )
            }
            hidden
          />

          <Button
            type="button"
            icon="pi pi-download"
            className={classNames(
              "p-button-secondary p-button-sm p-button-icon-only p-button-outlined w-auto text-lg px-2 py-0",
              {
                "border-transparent": data.is_rejected === 1,
              },
              `download_pod_${rowIndex}`
            )}
            onClick={() => downloadPod({ rowIndex: rowIndex % tbRows })}
            disabled={
              data.is_rejected === 1 ||
              (data.status !== 12 && data.status !== 40) ||
              (data.status === 40 && data.shipping_method === 1)
            }
          />
          <Tooltip
            target={`.download_pod_${rowIndex}`}
            position="top"
            content="POD"
          />
        </>
      ),
    });

    tempColumns.forEach((item) => {
      if (item.field === "warehouse_id") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.status === 1 && data.is_rejected !== 1 ? (
              <FormDropdown
                className="p-sm"
                panelClassName="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  options: optionData.warehouse,
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  saveFun: () =>
                    saveColumnAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                  showClear: false,
                }}
              />
            ) : (
              <>
                {data[item.field]
                  ? optionData.warehouse_obj?.[data[item.field]]?.showLabel
                  : ""}
              </>
            )}
          </>
        );
      } else if (item.field === "status") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.status === 1 ||
            data.status === 12 ||
            data.status === 40 ||
            data.is_rejected === 1 ? (
              <>{optionData.confirm_type_obj?.[data.status]?.showLabel}</>
            ) : (
              <>
                <div>
                  <Button
                    type="button"
                    label={
                      optionData.confirm_type_obj?.[data.status]?.showLabel
                    }
                    className="p-button-secondary p-button-sm p-button-outlined w-auto text-base white-space-nowrap px-1 py-0"
                    onClick={() =>
                      backAction({ id: data.id, status: data.status })
                    }
                  />
                  {data.status === 21 && (
                    <>
                      <br />
                      <Button
                        type="button"
                        label="Confirm Ship"
                        className="p-button-sm w-auto text-base white-space-nowrap px-1 py-0 mt-2"
                        onClick={() => confirmShip({ id: data.id })}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </>
        );
      } else if (item.field === "vc_shipping_carrier") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 10 ||
              data.status === 11 ||
              data.status === 12 ||
              data.status === 20 ||
              data.status === 21 ||
              data.status === 22 ||
              data.status === 40) &&
            data.is_rejected !== 1 ? (
              <FormDropdown
                className="p-sm w-full"
                panelClassName="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  options:
                    optionData[
                      data.shipping_method === 1
                        ? "carrier_ground"
                        : "carrier_freight"
                    ],
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  saveFun: () =>
                    saveOtherAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                  showClear: false,
                }}
              />
            ) : (
              <>
                {data.vc_shipping_carrier
                  ? optionData?.[
                      `carrier_${
                        data.shipping_method === 1 ? "ground" : "freight"
                      }_obj`
                    ]?.[data[item.field]]?.showLabel
                  : ""}
              </>
            )}
          </>
        );
      } else if (item.field === "vc_shipping_tracking") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 11 ||
              data.status === 12 ||
              data.status === 20 ||
              data.status === 21 ||
              data.status === 22 ||
              data.status === 40) &&
            data.is_rejected !== 1 ? (
              <FormInputtext
                className="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  saveFun: () =>
                    saveOtherAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      } else if (item.field === "vc_shipping_arn") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.status === 2 && data.is_rejected !== 1 ? (
              <FormInputtext
                className="p-sm"
                setting={setting}
                data={{
                  uiClassName: classNames("ui-bg-transparent", {
                    "bg-red-500 text-white":
                      data[item.field] === null || data[item.field] === "",
                  }),
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  saveFun: () =>
                    setVcShippingData({
                      rowIndex: rowIndex % tbRows,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      } else if (item.field === "vc_shipping_asn") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 12 || data.status === 22 || data.status === 40) &&
            data.is_rejected !== 1 ? (
              <FormInputtext
                className="p-sm"
                setting={setting}
                data={{
                  uiClassName: classNames("ui-bg-transparent", {
                    "bg-red-500 text-white":
                      data[item.field] === null || data[item.field] === "",
                  }),
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  saveFun: () =>
                    saveOtherAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      } else if (item.field === "shipping_method") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.status === 1 && data.is_rejected !== 1 ? (
              <FormDropdown
                className="p-sm"
                panelClassName="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  options: optionData.shipping_method,
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  saveFun: () =>
                    saveColumnAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                  showClear: false,
                }}
              />
            ) : (
              <>
                {data.warehouse_id
                  ? optionData.shipping_method_obj?.[data[item.field]]
                      ?.showLabel
                  : ""}
              </>
            )}
          </>
        );
      } else if (item.field === "shipping_unit_qty") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.is_rejected !== 1 ? (
              <FormDropdown
                className="p-sm"
                panelClassName="p-sm"
                setting={setting}
                data={{
                  uiClassName: classNames("ui-bg-transparent", {
                    "bg-yellow": !data[item.field],
                  }),
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  options:
                    optionData[
                      data.shipping_method === 1
                        ? "shipping_unit_ground"
                        : "shipping_unit_freight"
                    ],
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  saveFun: () =>
                    saveColumnAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                  showClear: false,
                }}
              />
            ) : (
              <>
                {data[item.field]
                  ? optionData[
                      `${
                        data.shipping_method === 1
                          ? "shipping_unit_ground"
                          : "shipping_unit_freight"
                      }_obj`
                    ]?.[data[item.field]]?.showLabel
                  : 0}
              </>
            )}
          </>
        );
      } else if (item.field === "expected_quantity") {
        item.body = (data, { rowIndex }) => (
          <>
            {data.status === 1 && data.is_rejected !== 1 ? (
              <FormInputnumber
                className="p-sm"
                setting={setting}
                data={{
                  uiClassName: classNames("ui-bg-transparent", {
                    "bg-yellow": data.quantity_requested != data[item.field],
                    "bg-white":
                      !data[item.field] ||
                      data.quantity_requested == data[item.field],
                  }),
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  max: data.quantity_requested,
                  saveFun: () =>
                    saveColumnAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      } else if (item.field === "vc_inventoryList") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 1 || data.status === 2) &&
            data.is_rejected !== 1 ? (
              <>
                {!data.vc_inventoryList ||
                data.vc_inventoryList.length === 0 ? (
                  <Button
                    type="button"
                    label="Please Select"
                    className="p-button-secondary p-button-sm p-button-outlined bg-white border-dashed w-full text-base white-space-nowrap py-1 py-0"
                    onClick={(e) => openOverlay(e, data)}
                  />
                ) : (
                  <ViewPallet
                    className="cursor-pointer"
                    chooseData={data.vc_inventoryList}
                    optionData={optionData}
                    clickFun={(e) => openOverlay(e, data)}
                  />
                )}
              </>
            ) : (
              <ViewPallet
                inventoryData={data.inventoryList}
                chooseData={data.vc_inventoryList}
                optionData={optionData}
              />
            )}
          </>
        );
      } else if (item.field === "oms_code") {
        item.body = (data, { rowIndex }) => (
          <>
            {(((data.status === 3 ||
              data.status === 10 ||
              data.status === 11 ||
              data.status === 12) &&
              data.shipping_method === 2) ||
              ((data.status === 20 ||
                data.status === 21 ||
                data.status === 22 ||
                data.status === 40) &&
                data.shipping_method === 1)) &&
            data.is_rejected !== 1 ? (
              <FormInputtext
                className="p-sm"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].${item.field}`,
                  saveFun: () =>
                    saveColumnAction({
                      rowIndex: rowIndex % tbRows,
                      apiKey: item.field,
                    }),
                }}
              />
            ) : (
              <>{data[item.field]}</>
            )}
          </>
        );
      } else if (item.field === "is_checking_instock") {
        item.body = (data, { rowIndex }) => (
          <>
            {(data.status === 1 ||
              (data.status !== 1 && data[item.field] === 1)) && (
              <Button
                type="button"
                icon="pi pi-check"
                className={classNames(
                  "p-button-sm p-button-icon-only w-auto text-lg px-2 py-0",
                  {
                    "p-button-secondary":
                      data[item.field] === 0 ||
                      data.status !== 1 ||
                      data.is_rejected === 1,
                    "p-button-outlined":
                      data[item.field] === 0 ||
                      data.status !== 1 ||
                      data.is_rejected === 1,
                    "border-transparent":
                      data.status !== 1 || data.is_rejected === 1,
                  }
                )}
                onClick={() =>
                  saveColumnAction({
                    rowIndex: rowIndex % tbRows,
                    apiKey: item.field,
                  })
                }
                disabled={data.status !== 1 || data.is_rejected === 1}
              />
            )}
          </>
        );
      }
    });
    setTbColumns(tempColumns);
  }, [optionData, tbData, tbRows]);

  // 開啟 overlay
  const openOverlay = (e, data) => {
    setOverlayData(data);
    setTimeout(() => {
      pallet_op.current.toggle(e);
    }, 100);
  };

  /*
   * 勾選-切換
   * 以下條件才能勾選 checkbox：
   * 非註銷(is_rejected != 1)
   * 狀態為 New(status = 1)
   * 有填寫 Warehouse(warehouse_id)
   * 有選擇 Shipping Method(shipping_method)
   * 有選擇 Pallet(vc_inventoryList)
   * 選擇的 pallet 數量足夠(pallet_enough = true)
   */
  const checkChange = (type, e) => {
    let tempData = getValues();
    if (!tempData.data || tempData.data.length === 0) return;

    let total = 0, // 可勾選數量
      checked = 0, // 勾選數量
      isConfirm = true; // 是否可 ConFirm Order
    if (type === "all") {
      tempData.data.forEach((item) => {
        if (
          item.is_rejected !== 1
          // item.status === 1 &&
          // item.warehouse_id !== null &&
          // item.shipping_method !== null &&
          // item.vc_inventoryList.length > 0 &&
          // item.pallet_enough
        ) {
          item.confirm_flag = tempData.checkAll;

          if (item.confirm_flag) {
            if (item.status !== 1) isConfirm = false;
            checked++;
          }
        }
      });
      reset(tempData);
    } else {
      tempData.data.forEach((item) => {
        if (
          item.is_rejected !== 1
          // item.status === 1 &&
          // item.warehouse_id !== null &&
          // item.shipping_method !== null &&
          // item.vc_inventoryList.length > 0 &&
          // item.pallet_enough
        ) {
          total++;
          if (item.confirm_flag) {
            if (item.status !== 1) isConfirm = false;
            checked++;
          }
        }
      });

      if (tempData.checkAll !== !(total !== checked)) {
        tempData.checkAll = !(total !== checked);
        reset(tempData);
      }
    }

    setConfirmChecked(isConfirm && checked > 0);

    let target = document.getElementById("btn_multi_reject");
    target.style.display = checked > 0 ? "flex" : "none";
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let updateData = [];
    tbData.forEach((item, index) => {
      let update_flag = checkFormUpdate({
        newData: submitData.data[index],
        oldData: item,
      });
      if (update_flag) {
        updateData.push({
          id: item.id,
          notes: submitData.data[index].notes,
          shipment_name: submitData.data[index].shipment_name,
          shipment_id: submitData.data[index].shipment_id,
        });
      }
    });
    saveMultiAction({ data: submitData });
  };

  // 註銷 單筆 reject
  const rejectSingleAction = ({ rowIndex }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      id: data.id,
      is_rejected: 1,
    };

    saveSingleAction(jsonData);
  };

  // 註銷 多筆 reject
  const rejectMultiAction = () => {
    let submitData = getValues();

    let temp = submitData.data.filter((item) => item.confirm_flag);
    temp = temp.map((item) => ({
      id: item.id,
      is_rejected: 1,
    }));

    saveMultiAction({ data: temp });
  };

  // 下載 ShipPlan
  const downloadShipPlan = ({ rowIndex }) => {
    dispatch(setProgress(true));

    let submitData = getValues(),
      rowData = submitData.data[rowIndex];

    const sheet_from_array_of_arrays = (data, main) => {
      let space = { c: 0, r: 3 }; // 若需要空行/列
      let ws: any = {};

      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 0,
          r: 0,
        })
      ] = {
        v: "Please follow the instruction in the excel shipping plan and let us know if you have any questions",
        t: "s",
        s: {
          font: {
            sz: "12",
            color: { rgb: "ff0000" },
          },
          alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "d9d9d9" } },
            bottom: { style: "thin", color: { rgb: "d9d9d9" } },
            left: { style: "thin", color: { rgb: "d9d9d9" } },
            right: { style: "thin", color: { rgb: "d9d9d9" } },
          },
          fill: {
            fgColor: { rgb: "ffffff" },
          },
        },
      };
      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 0,
          r: 1,
        })
      ] = {
        v: "Need Carton Label",
        t: "s",
        s: {
          font: {
            sz: "12",
            color: { rgb: "ff0000" },
            bold: true,
          },
          alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "d9d9d9" } },
            bottom: { style: "thin", color: { rgb: "d9d9d9" } },
            left: { style: "thin", color: { rgb: "d9d9d9" } },
            right: { style: "thin", color: { rgb: "d9d9d9" } },
          },
          fill: {
            fgColor: { rgb: "ffffff" },
          },
        },
      };
      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 0,
          r: 2,
        })
      ] = {
        v: "PO:",
        t: "s",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "right",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
          fill: {
            fgColor: { rgb: "ffffff" },
          },
        },
      };
      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 1,
          r: 2,
        })
      ] = {
        v: main.po ?? "",
        t: "s",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
          fill: {
            fgColor: { rgb: "ead1dc" },
          },
        },
      };
      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 2,
          r: 2,
        })
      ] = {
        v: "ARN:",
        t: "s",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "right",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
          fill: {
            fgColor: { rgb: "ffffff" },
          },
        },
      };
      ws[
        XLSXSTYLE.utils.encode_cell({
          c: 3,
          r: 2,
        })
      ] = {
        v: main.arn ?? "",
        t: "s",
        s: {
          font: {
            sz: "12",
          },
          alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: false,
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
          fill: {
            fgColor: { rgb: "edf2e5" },
          },
        },
      };

      for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
          let cell: any = {
            v: data[R][C] ?? "",
            s: {
              font: {
                sz: "12",
                color: { rgb: "000000" },
              },
              alignment: {
                horizontal: "right",
                vertical: "center",
                wrapText: true,
              },
              border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
              fill: {
                fgColor: {
                  rgb: R === 0 ? "ff9900" : "ffffff",
                },
              },
            },
          };

          if (cell.v === null) continue;
          let cell_ref = XLSXSTYLE.utils.encode_cell({
            c: C + space.c,
            r: R + space.r,
          });

          if (typeof cell.v === "number") cell.t = "n";
          else if (typeof cell.v === "boolean") cell.t = "b";
          else if (cell.v instanceof Date) {
            cell.t = "n";
            cell.z = XLSXSTYLE.SSF._table[14];
            cell.v = datenum(cell.v, null);
          } else cell.t = "s";

          ws[cell_ref] = cell;
        }
      }

      let range = {
        s: { c: 0, r: 0 },
        e: { c: data[0].length, r: space.r + data.length + 10 },
      };
      if (range.s.c < 10000000)
        ws["!ref"] = XLSXSTYLE.utils.encode_range(range);
      // ws["!merges"] = [
      //   // 合併欄位
      //   { s: { c: 4, r: 0 }, e: { c: 7, r: 0 } },
      // ];

      // 每行 寬度
      ws["!cols"] = [
        { wch: 22 },
        { wch: 22 },
        { wch: 22 },
        { wch: 18 },
        { wch: 18 },
        { wch: 18 },
        { wch: 22 },
        { wch: 50 },
      ];

      return ws;
    };

    OrderAPI.getShippingPlan({ data: { id: rowData.id } }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        let columns = [
          { name: "UPC", key: "upc" },
          { name: "Model", key: "model" },
          { name: "SKU", key: "sku" },
          { name: "Case Size", key: "case_size" },
          { name: "Case Qty", key: "case_qty" },
          { name: "Total Qty", key: "total_qty" },
          { name: "Pallet No.", key: "pallet_no" },
          { name: "Note", key: "notes" },
        ];
        let head = [],
          body = [];
        data.data.forEach((item, index) => {
          let row = [];
          columns.forEach((column) => {
            if (index === 0) {
              head.push(column.name);
            }

            row.push(item[column.key]);
          });
          body.push(row);
        });
        body.unshift(head);

        let ws = sheet_from_array_of_arrays(body, data);
        let setting = {
          filename: "shipping_plan",
          ws: ws,
        };
        saveExcel(setting, () => {
          if (rowData.status === 3 && rowData.shipping_method === 1) {
            // ground
            updateVcShippingData({
              jsonData: {
                id: rowData.id,
                status: 20, // Waiting Shipping Label
              },
            });
          } else {
            dispatch(setProgress(false));
          }
        });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "下載失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 寄送信件
  const sendEnvelope = ({ rowIndex }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      id: data.id,
      status:
        data.status === 3
          ? 10 // Waiting BOL
          : data.status === 10
          ? 11 // Sent BOL
          : data.status === 20
          ? 21 // Unshipped Parcel
          : "",
    };

    if (jsonData.status === null || jsonData.status === undefined) return;

    updateVcShippingData({ jsonData });
  };

  // 上傳 POD
  const uploadPODFile = ({ rowIndex, file }) => {
    dispatch(setProgress(true));

    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let formData = new FormData();
    formData.append("id", data.vc_shipping_id);
    formData.append("typename", "vc_order_line_items_shipping");
    formData.append("pod", file);

    OrderAPI.uploadPODFile({ data: formData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        updateVcShippingData({
          jsonData: {
            id: data.id,
            status: 12, // Archive POD
          },
        });
        // setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 下載 POD
  const downloadPod = ({ rowIndex }) => {
    dispatch(setProgress(true));

    let submitData = getValues(),
      data = submitData.data[rowIndex];

    // let jsonData = {
    //   e53cc407dbcaa29584715b9f39d1f79c: data.e53cc407dbcaa29584715b9f39d1f79c,
    //   name: data.vc_shipping_pod_file_name,
    // };

    // OrderAPI.getPODFile({ data: jsonData }).then((result) => {
    //   console.log(result);
    //   let { Response, data, message } = result;

    //   if (data) {
    //   } else {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: message ?? "下載失敗",
    //         detail: "",
    //       })
    //     );
    //     dispatch(setProgress(false));
    //   }
    // });

    let fileName = data.vc_shipping_pod_file_name.split("pod_mancr_")[1];
    let fileUrl = `${process.env.REACT_APP_ROOT_URL}images/getfile?e53cc407dbcaa29584715b9f39d1f79c=${data.e53cc407dbcaa29584715b9f39d1f79c}&name=${data.vc_shipping_pod_file_name}`;

    com_downloadFile({
      fileName: fileName,
      url: fileUrl,
      finishAction: () => dispatch(setProgress(false)),
    });
  };

  // 確認出貨
  const confirmShip = ({ id }) => {
    let jsonData = {
      id: id,
      status: 22, // Shipped Parcel
    };

    updateVcShippingData({ jsonData });
  };

  // 整理單筆資料
  const saveColumnAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      id: data.id,
      [apiKey]: data[apiKey],
    };

    if (apiKey === "shipping_method" && data.shipping_unit_qty === 0) {
      jsonData.shipping_unit_qty = 1;
    }

    if (apiKey === "is_checking_instock") {
      data[apiKey] = data[apiKey] === 1 ? 0 : 1;
      jsonData[apiKey] = data[apiKey];
    }

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    saveSingleAction(jsonData);
  };

  // 儲存資料
  const saveSingleAction = (submitData) => {
    dispatch(setProgress(true));

    OrderAPI.updateVcOrderSingle({ data: submitData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        // dispatch(
        //   setToast({
        //     severity: "success",
        //     summary: "儲存成功",
        //     detail: "",
        //   })
        // );

        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const saveMultiAction = (submitData) => {
    dispatch(setProgress(true));

    OrderAPI.updateVcOrderMulti({ data: submitData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        // dispatch(
        //   setToast({
        //     severity: "success",
        //     summary: "儲存成功",
        //     detail: "",
        //   })
        // );

        document.getElementById("btn_multi_reject").style.display = "none";

        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 更新 arn
  const setVcShippingData = ({ rowIndex }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];

    if (!checkColumnUpdate(tbData[rowIndex], data, "vc_shipping_arn")) return;

    dispatch(setProgress(true));

    let jsonData = {
      id: data.id,
      shipping_type: data.shipping_method,
      arn: data.vc_shipping_arn,
    };

    OrderAPI.setVcShippingData({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        jsonData = {
          id: data.id,
          status: 3, // Notified Amazon
        };
        updateVcShippingData({ jsonData });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        setReload((state) => ({ ...state, list: true }));
      }
    });
  };

  // 變更特定欄位
  const saveOtherAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      id: data.id,
    };

    if (apiKey === "vc_shipping_carrier") {
      jsonData.carrier = data[apiKey];
    } else if (apiKey === "vc_shipping_tracking") {
      jsonData.tracking = data[apiKey];
    } else if (apiKey === "vc_shipping_asn") {
      jsonData.asn = data[apiKey];

      if (
        data.status === 40 &&
        (data[apiKey] === null || data[apiKey] === "")
      ) {
        setReload((state) => ({ ...state, list: true }));
        return;
      }
    }

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    if (
      (data.status === 12 || data.status === 22) &&
      apiKey === "vc_shipping_asn"
    ) {
      jsonData.status = 40; // ASN
    }

    updateVcShippingData({ jsonData });
  };

  // 狀態退回 (步驟一)
  const backAction = ({ id, status }) => {
    dispatch(setProgress(true));

    if (status === 2) {
      // Reserved
      let jsonData = { id: id };
      OrderAPI.backVcOrderLineItemsToNew({ data: jsonData }).then((result) => {
        let { Response, message } = result;

        if (Response === 1) {
          // backStatus({ id, status });
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message ?? "退回失敗",
              detail: "",
            })
          );
        }
        setReload((state) => ({ ...state, list: true }));
      });
    } else if (status === 3) {
      // Notified Amazon
      let jsonData = { id: id };
      OrderAPI.backVcShippingToRserved({ data: jsonData }).then((result) => {
        let { Response, message } = result;

        if (Response === 1) {
          // backStatus({ id, status });
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message ?? "退回失敗",
              detail: "",
            })
          );
        }
        setReload((state) => ({ ...state, list: true }));
      });
    } else {
      backStatus({ id, status });
    }
  };

  // 狀態退回 (步驟二)
  const backStatus = ({ id, status }) => {
    if (status === 10) status = 3;
    else if (status === 11) status = 10;
    else if (status === 12) status = 11;
    else if (status === 20) status = 3;
    else if (status === 21) status = 20;
    else if (status === 22) status = 21;
    else if (status === 40) status = 22;

    let jsonData = {
      id: id,
      status: status,
    };
    updateVcShippingData({ jsonData });
  };

  const updateVcShippingData = ({ jsonData }) => {
    dispatch(setProgress(true));

    OrderAPI.updateVcShippingData({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "退回失敗",
            detail: "",
          })
        );
      }
    });
  };

  // confirm order line
  const confirmOrder = () => {
    // item.status === 1 &&
    // item.warehouse_id !== null &&
    // item.shipping_method !== null &&
    // item.vc_inventoryList.length > 0 &&
    // item.pallet_enough

    let jsonData = {
      data: [],
    };

    let submitData = getValues(),
      pass = true;
    submitData.data.forEach((item) => {
      if (item.confirm_flag) {
        if (
          item.status !== 1 ||
          item.warehouse_id === null ||
          item.shipping_method === null ||
          item.vc_inventoryList.length === 0 ||
          !item.pallet_enough
        )
          pass = false;

        jsonData.data.push({ id: item.id });
      }
    });

    if (!pass) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "資料不完整",
          detail: `必填欄位：\nWarehouse\nShipping Method\nPallet Number`,
        })
      );
      return;
    }

    dispatch(setProgress(true));

    OrderAPI.confirmVcOrderLineItems({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "Confirm Success",
            detail: "",
          })
        );

        document.getElementById("btn_multi_reject").style.display = "none";

        setReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "Confirm Failed",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 選擇匯入檔案
  const fileChange = (e, type, callback = () => {}) => {
    let file = e.target.files[0];

    if (type === "vc") {
      let data = readExcelFile(file, 0);
      data.then((result) => {
        setFileData(result);
      });
    } else if (type === "pod") {
      callback(file);
    }
  };

  // 資料匯入
  const vcOrderImport = () => {
    dispatch(setProgress(true));

    document.getElementById("selectFile").value = "";

    // 比對匯入欄位
    let errorData = { keys: [], columns: {} };
    let entries = Object.values(vcImportColumns);
    entries.forEach((key) => {
      let isMatch = fileData.keys.indexOf(key.value) !== -1;
      // 比對必填欄位是否存在
      if (!isMatch && key.required) {
        errorData.keys.push(key.value);
      }

      // 比對必填欄位值是否存在
      if (isMatch && key.required) {
        fileData.data.forEach((row, rowIdx) => {
          if (
            row[key.value] === "" ||
            row[key.value] === undefined ||
            row[key.value] === null
          ) {
            if (!errorData.columns[key.value])
              errorData.columns[key.value] = [];
            errorData.columns[key.value].push(rowIdx + 1);
          }
        });
      }
    });
    if (errorData.keys.length > 0) {
      dispatch(
        setToast({
          severity: "error",
          summary: "Excel 缺少欄位",
          detail: errorData.keys.join("、"),
        })
      );
      dispatch(setProgress(false));
      return;
    } else if (Object.keys(errorData.columns).length > 0) {
      let errorColumns = Object.entries(errorData.columns),
        detail = "";
      errorColumns.forEach((column) => {
        if (detail) detail += "\n";
        detail += `${column[0]}：第 ${column[1].join("、")} 列`;
      });
      dispatch(
        setToast({
          severity: "error",
          summary: "必填欄位未填",
          detail: detail,
        })
      );
      dispatch(setProgress(false));
      return;
    }

    let jsonData = {
      data: formatTemplateColumns({
        type: "import",
        template: vcImportColumns,
        data: fileData.data,
      }),
    };

    OrderAPI.vcOrderImport({ data: jsonData }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        setFileData({ keys: [], data: [] });

        dispatch(
          setToast({
            severity: "success",
            summary: "儲存成功",
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "儲存失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 表格 列 樣式
  const tbRowClass = (data) => {
    return {
      "opacity-40": data.is_rejected === 1,
    };
  };

  const tbCellClass = (value, cellData) => {
    let field = cellData.column.props.field,
      rowData = cellData.rowData;

    return {
      "bg-red-200":
        field === "vc_inventoryList" &&
        !rowData.pallet_enough &&
        rowData.is_rejected !== 1,
      "bg-yellow":
        (field === "expected_quantity" &&
          rowData.status !== 1 &&
          rowData.expected_quantity !== rowData.quantity_requested) ||
        (field === "shipping_unit_qty" && rowData.is_rejected === 1),
      "bg-green-100": field === "checking_price",
    };
  };

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 pr-0">
          <Calendar
            className="w-full"
            name="dateRange"
            value={filterData.dateRange}
            onChange={(e) => changeFilter(e)}
            placeholder="Start Date ~ End Date"
            selectionMode="range"
            numberOfMonths={2}
            readOnlyInput
            showButtonBar
          />
        </div>
        <div className="col-12 sm:col-6 md:col-4 lg:col-3 pr-0">
          <MultiSelect
            className="w-full"
            name="statusList"
            value={optionData.confirm_type ? filterData.statusList : []}
            options={optionData.confirm_type}
            onChange={(e) => changeFilter(e)}
            optionValue="value"
            optionLabel="label"
            placeholder="Select Status"
            display="chip"
          />
        </div>
        <div className="col-12 sm:col-6 md:col-4 lg:col-2 pr-0">
          <Dropdown
            className="w-full"
            name="is_rejected"
            value={filterData.is_rejected}
            onChange={(e) => changeFilter(e)}
            options={optionData.accept_reject}
            placeholder="Select Type"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div>
        <div className="flex col-12 sm:col-6 md:col-12 lg:col-4 xl:col-5 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
          <Button
            className="p-button-primary ml-2 mr-2"
            label="Confirm Order"
            onClick={() => confirmOrder()}
            disabled={!confirmChecked}
          />

          <Button
            type="button"
            icon="pi pi-folder-open"
            // label="Select File"
            className="p-button-gray p-button-outlined ml-auto"
            onClick={() => document.getElementById("selectFile").click()}
          />
          <input
            id="selectFile"
            type="file"
            accept=".xlsx"
            onChange={(e) => fileChange(e, "vc")}
            hidden
          />
          <Button
            type="button"
            label="Import"
            className="ml-2"
            disabled={fileData.data.length === 0}
            onClick={() => vcOrderImport()}
          />
        </div>
        <div className="flex align-items-center col-12">
          <div className="inline-block border-2 border-solid border-red-500 px-3 py-2 mr-2"></div>
          Selected pallet number is not match shipping qty.
        </div>
      </div>

      <div className="mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataTable
            value={tbData}
            className={classNames("size-sm", {
              "table-empty": tbData.length === 0,
            })}
            size="normal"
            paginator
            paginatorTemplate={ModuleTablePaginator}
            currentPageReportTemplate="Total {totalRecords} items"
            rowClassName={tbRowClass}
            cellClassName={tbCellClass}
            lazy
            first={tbFirstIndex}
            totalRecords={tbTotal}
            onPage={onPage}
            rows={tbRows}
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            scrollDirection="both"
            responsiveLayout="scroll"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column key={`${item.field}_${index}`} {...item} />
            ))}
          </DataTable>

          {tbData.length === 0 && <ModuleTableEmpty />}

          {/* <div className="flex mt-2">
            <Button className="px-3 ml-auto" type="submit" label="Save" />
          </div> */}
        </form>
      </div>

      <OverlayPanel ref={pallet_op} style={{ width: "350px" }}>
        <EditPallet
          palletType="vc"
          palletData={{
            id: overlayData.id,
            expected_quantity: overlayData.expected_quantity,
            inventoryData: overlayData.inventoryList,
            chooseData: overlayData.vc_inventoryList,
            quantity_requested: overlayData.expected_quantity,
          }}
          optionData={optionData}
          reloadFun={() => {
            pallet_op.current.hide();
            setReload((state) => ({ ...state, list: true }));
          }}
        />
      </OverlayPanel>
    </>
  );
}
