export const system_api = {
  delay: 300,
  timeout: 1000 * 60 * 3,
};

export const system_toast = {
  life: 6000,
};

export const system_dropdown = {
  empty: "無資料",
  emptyFilter: "查無無資料",
};

export const system_table = {
  empty: "無資料",
  size: 25,
};

export const system_email = {
  verify: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};
