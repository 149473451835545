import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { InventoryAPI } from "../../../axios/api";

import { palletExportColumns } from "../../../service/columns/inventory/pallet";
import { system_table, system_dropdown } from "../../../service/system";
import { createColumns, createSchema } from "../../../service/table";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function InventoryPalletPopDuplicate(props) {
  const {
    closePrePop,
    prePopOption,
    setPreReload,
    isForceShip,
    optionData,
    palletExportColumns,
    confirmToShip,
  } = props;
  const dispatch = useDispatch();
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbColumns, setTbColumns] = useState([]);

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex) {
      return;
    }

    setTbFirstIndex(startIndex);
  };

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(palletExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    let tempTemplate = { ...palletExportColumns };
    Object.entries(tempTemplate).forEach(([key, value]) => {
      tempTemplate[key].listUI = false;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "multi",
      activeTemplate: entries,
      defaultTemplate: tempTemplate,
      optionData: optionData,
    });
    tempColumns.push({
      field: "id",
      header: "",
      className: "justify-content-center",
      style: {
        width: 70,
        minWidth: 70,
      },
      body: (data, { rowIndex }) => (
        <>
          {data.duplicate_flag && (
            <span className="text-red-400">Duplicate</span>
          )}
        </>
      ),
    });

    setTbColumns(tempColumns);
  }, [optionData, tbData]);

  useEffect(() => {
    let temp = [];
    prePopOption.data.forEach((item) => {
      let row = { ...item };
      row.duplicate_flag =
        prePopOption.duplicateName.indexOf(item.shipment_name) !== -1;
      temp.push(row);
    });
    setTbData(temp);
    setTbTotal(temp.length);
  }, [prePopOption]);

  // 強制出貨
  const forceToShip = () => {
    dispatch(setProgress(true));
    closePrePop({ type: "duplicate" });
    confirmToShip(prePopOption.data);
  };

  return (
    <>
      <div className="m-3">
        <DataTable
          value={tbData}
          className="size-sm"
          size="normal"
          stripedRows
          // paginator
          // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          lazy
          first={tbFirstIndex}
          totalRecords={tbTotal}
          onPage={onPage}
          // rows={system_table.size}
          // scrollable
          // scrollDirection="both"
          responsiveLayout="scroll"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column key={`${item.field}_${index}`} {...item} />
          ))}
        </DataTable>

        <div className="flex border-top-1 border-300 mt-3 pt-3">
          <Button
            className="p-button-secondary p-button-outlined px-3 ml-auto"
            type="button"
            label="Cancel"
            onClick={() => closePrePop({ type: "duplicate" })}
          />
          <Button
            className="px-3 ml-2"
            type="button"
            label="Ship"
            disabled={!isForceShip}
            onClick={() => forceToShip()}
          />
        </div>
      </div>
    </>
  );
}
