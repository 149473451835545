export const grossExportColumns = {
  sku: {
    name: "Sku",
    value: "Sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 220,
    order: 1,
  },
  asin: {
    name: "Asin",
    value: "Asin",
    apiKey: "asin",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 2,
  },
  fnsku: {
    name: "Fnsku",
    value: "Fnsku",
    apiKey: "fnsku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 3,
  },
  warehouse_sku: {
    name: "倉庫型號",
    value: "倉庫型號",
    apiKey: "warehouse_sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 4,
  },
  fba_numbers: {
    name: "FBA庫存",
    value: "FBA庫存",
    apiKey: "fba_numbers",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 5,
  },
  total_number: {
    name: "倉庫總庫存",
    value: "倉庫總庫存",
    apiKey: "total_number",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 6,
  },
  CJ1: {
    name: "	CJ1",
    value: "CJ1",
    apiKey: "CJ1",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 7,
  },
  CJ2: {
    name: "CJ2",
    value: "CJ2",
    apiKey: "CJ2",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 8,
  },
  GNIQ1: {
    name: "GNIQ1",
    value: "GNIQ1",
    apiKey: "GNIQ1",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 9,
  },
  GNIQ2: {
    name: "GNIQ2",
    value: "GNIQ2",
    apiKey: "GNIQ2",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 10,
  },
  OMS1: {
    name: "OMS1",
    value: "OMS1",
    apiKey: "OMS1",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 11,
  },
  OMS2: {
    name: "OMS2",
    value: "OMS2",
    apiKey: "OMS2",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 12,
  },
  NOV: {
    name: "NOV",
    value: "NOV",
    apiKey: "NOV",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 13,
  },
  TOPO: {
    name: "TOPO",
    value: "TOPO",
    apiKey: "TOPO",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 14,
  },
  SDII: {
    name: "SDII",
    value: "SDII",
    apiKey: "SDII",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 15,
  },
  FC: {
    name: "FC",
    value: "FC",
    apiKey: "FC",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 16,
  },
};
