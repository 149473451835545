import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";

import { exportFile } from "../../service/excel";

import { Button } from "primereact/button";

export default function FileTransferPopExport(props) {
  const {
    isPop,
    prePopOption,
    closePrePop,
    moduleType, // 分類
    exportColumns, // 匯出預設欄位
    activeModule, // 生效的模板資料
    optionData = {}, // 選單資料
    exportData = [], // 匯出資料(當頁)
    getAllData, // 取得全部列表資料
  } = props;
  const dispatch = useDispatch();
  const [exportType, setExportType] = useState([
    { name: "xlsx", type: "xlsx" },
    { name: "xls", type: "xls" },
    { name: "csv", type: "csv" },
    { name: "txt", type: "txt" },
  ]);

  // 整理匯出資料
  const formatExport = async (type) => {
    dispatch(setProgress(true));

    let result = await getAllData({ type: "export" });

    if (result.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "警示",
          detail: "無資料",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    let entries = Object.values(activeModule.exportTemplate.settings);
    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    let filter = [];
    result.forEach((item, index) => {
      let title = [],
        row = [];
      entries.forEach((column: any) => {
        let columnSetting = exportColumns[column.apiKey]; // 預設的欄位設定

        if (column.checked && columnSetting.isShow) {
          if (index === 0) title.push(column.value);

          let value = item[column.apiKey];
          if (value !== null && typeof value === "object") {
            value = JSON.stringify(value);
          } else if (value === null) {
            value = "";
          }

          if (columnSetting.uiType === "select" && value !== "") {
            value =
              optionData[`${columnSetting.options}_obj`]?.[value]?.showLabel ??
              "";
          } else if (columnSetting.uiType === "check") {
            value = value === 1 ? "TRUE" : "FALSE";
          }
          row.push(value);
        }
      });

      if (index === 0) filter.push(title);
      filter.push(row);
    });

    exportFile({
      fileName: moduleType,
      type,
      data: filter,
      finishAction: () => dispatch(setProgress(false)),
    });
  };

  return (
    <>
      <div className="p-3">
        {exportType.map((item, index) => (
          <Button
            key={`export_type_${index}`}
            className="px-4 mr-3"
            label={item.name}
            onClick={() => formatExport(item.type)}
          />
        ))}
      </div>
    </>
  );
}
