import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { InventoryAPI } from "../../../axios/api";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInputtext,
  FormInputnumber,
} from "../../../components/formElement/index";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function InventoryPalletPopEdit(props) {
  const { closePrePop, prePopOption, setPreReload } = props;
  const dispatch = useDispatch();
  // 表單設定
  const defaultValues = {
    qty: 1,
    notes: "",
    shipment_name: "",
    shipment_id: "",
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        contact_phone: yup
          .string()
          .test("required", "必填", (value, { from: [{ value: data }] }) => {
            return data.qty > prePopOption.data.qty || !data.qty ? false : true;
          })
          .nullable(),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      ...submitData,
      id: prePopOption.data.id,
    };

    InventoryAPI.splitInventory({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "編輯成功",
            detail: "",
          })
        );
        closePrePop({ type: "edit" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "編輯失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid mx-0">
          <div className="sm:flex sm:align-items-center field col-12 px-0">
            <label
              htmlFor="qty"
              className={classNames(
                { "p-error": errors.qty },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Qty
            </label>
            <div className="col-12 sm:col-8">
              <FormInputnumber setting={setting} data={{ name: "qty" }} />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12 px-0">
            <label
              htmlFor="notes"
              className={classNames(
                { "p-error": errors.notes },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Notes
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext setting={setting} data={{ name: "notes" }} />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12 px-0">
            <label
              htmlFor="shipment_name"
              className={classNames(
                { "p-error": errors.shipment_name },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Shipment Name
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext
                setting={setting}
                data={{ name: "shipment_name" }}
              />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12 px-0">
            <label
              htmlFor="shipment_id"
              className={classNames(
                { "p-error": errors.shipment_id },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Shipment ID
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext setting={setting} data={{ name: "shipment_id" }} />
            </div>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="Back"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "edit" })}
            />
            <Button type="submit" label="Confirm" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
