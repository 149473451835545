export default {
  getWarehouse: "BackEnd/getWarehouseData",
  getWarehouseDetail: "BackEnd/getWarehouseDetail",
  setWarehouse: "BackEnd/setWarehouse",
  updateWarehouse: "BackEnd/updateWarehouse",

  getCarrier: "BackEnd/getCarrierData",
  getCarrierDetail: "BackEnd/getCarrierDetail",
  setCarrier: "BackEnd/setCarrier",
  updateCarrier: "BackEnd/updateCarrier",

  getGoogleSheetDumpTable: "BackEnd/getGoogleSheetDumpTable",
  setGoogleSheetDumpTable: "BackEnd/setGoogleSheetDumpTable",
  updateGoogleSheetDumpTable: "BackEnd/updateGoogleSheetDumpTable",
  deleteGoogleSheetDumpTable: "BackEnd/deleteGoogleSheetDumpTable",

  getGoogleSheetDumpSchedule: "BackEnd/getGoogleSheetDumpSchedule",
  setGoogleSheetDumpSchedule: "BackEnd/setGoogleSheetDumpSchedule",
  updateGoogleSheetDumpSchedule: "BackEnd/updateGoogleSheetDumpSchedule",
  deleteGoogleSheetDumpSchedule: "BackEnd/deleteGoogleSheetDumpSchedule",
};
