import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { CommonAPI } from "../../../axios/api";
import { useAbortedEffect } from "../../../components/hooks";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInputtext,
  FormInputnumber,
  FormDropdown,
} from "../../../components/formElement/index";
import {
  formatResData,
  checkFormUpdate,
  formatOptions,
} from "../../../service/common";
import { option_open, option_schedule_type } from "../../../service/option";
import { system_email } from "../../../service/system";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function CommonGoogleDumpPopEdit(props) {
  const { closePrePop, prePopOption, setPreReload } = props;
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState({});
  // 表單設定
  const defaultValues = {
    googlesheet_name: "",
    googlesheetid: "",
    tableid: null,
    schedule_type: null,
    status: 1,
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        googlesheet_name: yup.string().required("必填"),
        googlesheetid: yup.string().required("必填"),
        tableid: yup.number().required("必填").nullable(),
        schedule_type: yup.number().required("必填").nullable(),
        status: yup.number().required("必填").nullable(),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useAbortedEffect((signal) => {
    CommonAPI.getGoogleSheetDumpTable({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let temp = formatOptions(
          {
            google_table: data,
          },
          { uniqueKey: "id", showLabel: "name" }
        );

        formatOtherOptions(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
    });
  }, []);

  const formatOtherOptions = (data) => {
    let temp = formatOptions({
      open: option_open,
      schedule_type: option_schedule_type,
    });
    setOptionData({ ...data, ...temp });
  };

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useEffect(() => {
    if (prePopOption.data) {
      let temp = formatResData(defaultValues, prePopOption.data);
      reset(temp);
    }
  }, [prePopOption]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    if (
      !checkFormUpdate({
        newData: submitData,
        oldData: prePopOption.data ?? {},
      })
    ) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    let promise;
    if (prePopOption.data) {
      // 修改
      promise = CommonAPI.updateGoogleSheetDumpSchedule({ data: submitData });
    } else {
      // 新增
      promise = CommonAPI.setGoogleSheetDumpSchedule({ data: submitData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "編輯成功",
            detail: "",
          })
        );
        closePrePop({ type: "edit" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "編輯失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid mx-0">
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="googlesheet_name"
              className={classNames(
                { "p-error": errors.googlesheet_name },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Google Sheet Name
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext
                setting={setting}
                data={{ name: "googlesheet_name" }}
              />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="googlesheetid"
              className={classNames(
                { "p-error": errors.googlesheetid },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Google Sheet ID
            </label>
            <div className="col-12 sm:col-8">
              <FormInputtext
                setting={setting}
                data={{ name: "googlesheetid" }}
              />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="tableid"
              className={classNames(
                { "p-error": errors.tableid },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Table ID
            </label>
            <div className="col-12 sm:col-8">
              <FormDropdown
                setting={setting}
                data={{
                  name: "tableid",
                  options: optionData.google_table,
                  optionLabel: "showLabel",
                  optionValue: "showValue",
                  showClear: false,
                }}
              />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="schedule_type"
              className={classNames(
                { "p-error": errors.schedule_type },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Schedule Type
            </label>
            <div className="col-12 sm:col-8">
              <FormDropdown
                setting={setting}
                data={{
                  name: "schedule_type",
                  options: option_schedule_type,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                }}
              />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="status"
              className={classNames(
                { "p-error": errors.status },
                "col-12 sm:col-4 sm:text-right sm:mb-0"
              )}
            >
              Status
            </label>
            <div className="col-12 sm:col-8">
              <FormDropdown
                setting={setting}
                data={{
                  name: "status",
                  options: option_open,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                }}
              />
            </div>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="Back"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "edit" })}
            />
            <Button type="submit" label="Save" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
