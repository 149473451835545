import { system_dropdown } from "../../service/system";

import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function FormDropdown(props) {
  const {
    className,
    panelClassName,
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: {
      uiClassName,
      name,
      options = [],
      optionLabel = "label",
      optionValue = "value",
      placeholder = "",
      filter = false,
      disabled = false,
      showClear = true,
      saveFun = () => {},
    },
  } = props;

  const onChange = (e, field) => {
    field.onChange(e.value);
    if (isSubmitted) {
      trigger();
    } else {
      trigger(field.name);
    }

    saveFun();
  };

  return (
    <>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div
            className={classNames(
              "flex align-items-center flex-auto",
              className
            )}
          >
            <Dropdown
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full",
                uiClassName
              )}
              panelClassName={panelClassName}
              {...field}
              value={field.value || field.value == 0 ? field.value : null}
              onChange={(e) => onChange(e, field)}
              disabled={!isEdit || disabled}
              placeholder={placeholder}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              filter={filter}
              filterBy={optionValue}
              emptyMessage={system_dropdown.empty}
              emptyFilterMessage={system_dropdown.emptyFilter}
              showClear={showClear}
            />
          </div>
        )}
      />
    </>
  );
}
