import { classNames } from "primereact/utils";

export default function ViewPallet(props) {
  const {
    className = "",
    chooseData = [], // 選擇的 pallet 資料
    optionData = {}, // 選單資料
    clickFun = () => {},
  } = props;

  return (
    <>
      <div className={classNames("w-full", className)}>
        {chooseData.map((item, index) => (
          <div
            key={`pallet_item_${index}`}
            className={classNames("flex", {
              "border-top-1 border-300": index !== 0,
            })}
            onClick={clickFun}
          >
            <div className="w-5rem">
              {
                optionData.warehouse_obj?.[item.inventoryData?.warehouse_id]
                  ?.showLabel
              }
            </div>
            <div className="w-8rem">{item.inventoryData?.pallet_no}</div>
            <div className="w-6rem flex-auto">{item.inventoryData?.notes}</div>
            <div className="w-3rem text-right">{item.inventoryData?.qty}</div>
          </div>
        ))}
      </div>
    </>
  );
}
