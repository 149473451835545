export const googleDumpExportColumns = {
  googlesheet_name: {
    name: "Google Sheet Name",
    value: "Google Sheet Name",
    apiKey: "googlesheet_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 1,
  },
  googlesheetid: {
    name: "Google Sheet ID",
    value: "Google Sheet ID",
    apiKey: "googlesheetid",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 200,
    order: 2,
  },
  tableid: {
    name: "Table ID",
    value: "Table ID",
    apiKey: "tableid",
    uiType: "select",
    options: "google_table",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 3,
  },
  schedule_type: {
    name: "Schedule Type",
    value: "Schedule Type",
    apiKey: "schedule_type",
    uiType: "select",
    options: "schedule_type",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 4,
  },
  // status: {
  //   name: "Status",
  //   value: "Status",
  //   apiKey: "status",
  //   uiType: "select",
  //   options: "open",
  //   optionType: "number",
  //   isShow: true,
  //   checked: true,
  //   listDisabled: false,
  //   listUI: false,
  //   required: true,
  //   width: 100,
  //   order: 3,
  // },
};
