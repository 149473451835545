import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SystemAPI } from "../../axios/api";

import {
  FormInputtext,
  FormInputpassword,
  FormDropdown,
} from "../../components/formElement/index";
import { formatOptions } from "../../service/common";
import { option_open } from "../../service/option";
import { system_email } from "../../service/system";

import LoginCss from "../../styles/components/login.module.scss";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function SystemRegister(props) {
  // 步驟
  const [step, setStep] = useState(0);
  // 用於控制表單刷新
  const [formKey, setFormKey] = useState(new Date());

  return (
    <>
      <div
        className={`${LoginCss["login-wrap"]} flex flex-column align-items-center justify-content-center w-full h-full`}
      >
        <Card
          className={`${LoginCss["login-inner-wrap"]} w-10 sm:w-6 max-w-30rem text-2xl px-3`}
        >
          <h1 className="text-center mt-0 mb-6">Merchant</h1>

          {step === 0 ? (
            <ComSignUpDetail
              formKey={formKey}
              setFormKey={setFormKey}
              setStep={setStep}
            />
          ) : step === 1 ? (
            <ComVerify formKey={formKey} setFormKey={setFormKey} />
          ) : null}
        </Card>
      </div>
    </>
  );
}

function ComSignUpDetail(props) {
  const { formKey, setFormKey, setStep } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState({});
  // 表單欄位預設值
  const defaultValues = {
    User: {
      FName: "",
      LName: "",
      Email: "abcdef810352@gmail.com",
      Phone: "0917181779",
      Password: "",
    },
    Company: {
      Name: "",
      Type: null,
      Address: "",
      City: "",
      State: "",
      Postal: "",
      Country: null,
    },
    Amazon: {
      SellerId: "",
      MarketplaceId: null,
      AuthToken: "",
      AccessKeyId: "",
      SecretKey: "",
      TimeZoneId: null,
    },
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      User: yup.object().shape({
        FName: yup.string().required("必填"),
        LName: yup.string().required("必填"),
        Email: yup
          .string()
          .matches(system_email, {
            message: "電子郵件格式錯誤(ex:abc@gmail.com)",
            excludeEmptyString: true,
          })
          .required("必填"),
        Phone: yup.string().required("必填"),
        Password: yup.string().required("必填"),
      }),
      Company: yup.object().shape({
        Name: yup.string().required("必填"),
        Type: yup.string().required("必填").nullable(),
        Address: yup.string().required("必填"),
        City: yup.string().required("必填"),
        State: yup.string().required("必填"),
        Postal: yup.string().required("必填"),
        Country: yup.string().required("必填").nullable(),
      }),
      Amazon: yup.object().shape({
        SellerId: yup.string().required("必填"),
        MarketplaceId: yup.string().required("必填").nullable(),
        AuthToken: yup.string().required("必填"),
        AccessKeyId: yup.string().required("必填"),
        SecretKey: yup.string().required("必填"),
        TimeZoneId: yup.string().required("必填").nullable(),
      }),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useEffect(() => {
    let temp = formatOptions({
      mission: option_open,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    SystemAPI.signup({ data: submitData }).then((result) => {
      let { Code, Data, message } = result;

      if (Code === 100) {
        setStep(1);
        reset(defaultValues);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "註冊失敗",
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <form
        key={formKey}
        onSubmit={handleSubmit(onSubmit)}
        className="formgrid grid text-base max-h-30rem overflow-y-auto"
      >
        <div className="col-12 text-xl mb-3 pb-1 border-bottom-1 border-400">
          User
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.User?.FName },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            First Name
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "User.FName" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.User?.LName },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Last Name
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "User.LName" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.User?.Email },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Email
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "User.Email" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.User?.Phone },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Phone
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "User.Phone" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.User?.Password },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Password
          </label>
          <div className="col-8 pr-0">
            <FormInputpassword
              setting={setting}
              data={{ name: "User.Password" }}
            />
          </div>
        </div>

        <div className="col-12 text-xl mb-3 pb-1 border-bottom-1 border-400">
          Company
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.Name },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Name
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "Company.Name" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.Type },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Type
          </label>
          <div className="col-8 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "Company.Type",
                options: optionData.mission,
                optionLabel: "label",
                optionValue: "value",
              }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.Address },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Address
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Company.Address" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.City },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            City
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "Company.City" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.State },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            State
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "Company.State" }} />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.Postal },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Postal
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Company.Postal" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Company?.Country },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Country
          </label>
          <div className="col-8 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "Company.Country",
                options: optionData.mission,
                optionLabel: "label",
                optionValue: "value",
              }}
            />
          </div>
        </div>

        <div className="col-12 text-xl mb-3 pb-1 border-bottom-1 border-400">
          Amazon
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.SellerId },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Seller ID
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Amazon.SellerId" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.MarketplaceId },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Marketplace ID
          </label>
          <div className="col-8 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "Amazon.MarketplaceId",
                options: optionData.mission,
                optionLabel: "label",
                optionValue: "value",
              }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.AuthToken },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Auth Token
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Amazon.AuthToken" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.AccessKeyId },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Access Key ID
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Amazon.AccessKeyId" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.SecretKey },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Secret Key
          </label>
          <div className="col-8 pr-0">
            <FormInputtext
              setting={setting}
              data={{ name: "Amazon.SecretKey" }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors?.Amazon?.TimeZoneId },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Time Zone
          </label>
          <div className="col-8 pr-0">
            <FormDropdown
              setting={setting}
              data={{
                name: "Amazon.TimeZoneId",
                options: optionData.mission,
                optionLabel: "label",
                optionValue: "value",
              }}
            />
          </div>
        </div>

        <div className="flex align-items-center col-12">
          <Button
            type="button"
            label="Back"
            className="p-button-outlined p-button-secondary w-full mr-2"
            onClick={() => navigate("/login")}
          />
          <Button
            type="submit"
            label="Next"
            className="p-button-primary w-full"
          />
        </div>
      </form>
    </>
  );
}

function ComVerify(props) {
  const { formKey, setFormKey } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState({});
  // 表單欄位預設值
  const defaultValues = {
    Code: "",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      Code: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useEffect(() => {
    let temp = formatOptions({
      mission: option_open,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    delete submitData.re_password;

    SystemAPI.signupCheck({ data: submitData }).then((result) => {
      let { Code, Data, message } = result;

      if (Code === 100) {
        dispatch(
          setToast({
            severity: "success",
            summary: "註冊成功",
            detail: "",
          })
        );
        reset(defaultValues);
        navigate("/login");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "註冊失敗",
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <form
        key={formKey}
        onSubmit={handleSubmit(onSubmit)}
        className="formgrid grid text-base max-h-30rem overflow-y-auto"
      >
        <div className="flex align-items-center field col-12">
          <label
            className={classNames(
              { "p-error": errors.Code },
              "col-4 text-right mb-0 pr-0"
            )}
          >
            Check Code
          </label>
          <div className="col-8 pr-0">
            <FormInputtext setting={setting} data={{ name: "Code" }} />
          </div>
        </div>

        <div className="flex align-items-center col-12">
          <Button
            type="submit"
            label="Confirm"
            className="p-button-primary w-full"
          />
        </div>
      </form>
    </>
  );
}
