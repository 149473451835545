import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonAPI, DailyAPI } from "../../axios/api";
import moment from "moment";

import ModuleFileTransfer from "../../components/fileTransfer";
import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ViewPallet from "../../components/module/pallet/viewPallet";
import { FormCheckbox } from "../../components/formElement";
import { useAbortedEffect } from "../../components/hooks";
import {
  overviewExportColumns,
  wayfairFinancialImportColumns,
  wayfairFinancialExportColumns,
  shippedFeeImportColumns,
  shippedFeeExportColumns,
  trackingImportColumns,
  trackingExportColumns,
} from "../../service/columns/daily/overview";
import { system_table, system_dropdown } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  checkFormUpdate,
  checkColumnUpdate,
  cleanObj,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../service/table";
import {
  option_daily_overview_type,
  option_platform,
  option_shipping_status,
  option_carriers,
} from "../../service/option";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { ConfirmPopup } from "primereact/confirmpopup";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

export default function DailyOverview(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Daily" }, { label: "訂單總覽" }];
  const [reload, setReload] = useState({ list: false });
  // 註銷(全選)
  const [revokeChecked, setRevokeChecked] = useState(false);
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  // 確認資訊
  const [confirmOption, setConfirmOption] = useState({});
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    dateRange: [],
    typeList: null,
    isshippedList: null,
    isreceived: null,
    warehouse: null,
    keywords: "",
    isrevoked: 0,
    isconfirm: 1,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  // 彈出視窗
  const [isPop, setIsPop] = useState({
    copy: false, // 編輯
  });
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  const [optionData, setOptionData] = useState({});
  // tab
  const [tabData, setTabData] = useState([]);
  const [tabActive, setTabActive] = useState(0);
  // 預設模組資料
  const defTemplate = {
    overview: {
      import: {},
      export: overviewExportColumns,
    },
    wayfairFinancial: {
      import: wayfairFinancialImportColumns,
      export: wayfairFinancialExportColumns,
    },
    shippedFee: {
      import: shippedFeeImportColumns,
      export: shippedFeeExportColumns,
    },
    tracking: {
      import: trackingImportColumns,
      export: trackingExportColumns,
    },
  };
  // 匯入匯出 模組資料
  const [moduleData, setModuleData] = useState({
    importTemplate: {},
    exportTemplate: {},
  });
  // 表單設定
  const defaultValues = {
    checkAll_isshipped: false,
    data: [],
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        data: yup.array().of(
          yup.object().shape({
            // title: yup.string().required("必填"),
            // content: yup.string().required("必填"),
            // img_url: yup.string().required("必填"),
          })
        ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  // 帶入 tab 資料
  useEffect(() => {
    setTabData(option_daily_overview_type);
  }, [option_daily_overview_type]);

  // tab 切換
  const tabChange = (e) => {
    setTabActive(e.index);

    setTbData([]);
    setTbTotal(0);

    let temp = { ...filterData };
    setFilterData(temp);
    setSearch(temp);
    setTbFirstIndex(0);
  };

  useAbortedEffect((signal) => {
    CommonAPI.getWarehouse({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let temp = formatOptions(
          {
            warehouse: data,
          },
          { uniqueKey: "id", showLabel: "name" }
        );

        formatOtherOptions(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
    });
  }, []);

  const formatOtherOptions = (data) => {
    let temp = {
      ...data,
      ...formatOptions(
        {
          platform: option_platform,
          shipping_status: option_shipping_status,
          carriers: option_carriers,
        },
        { uniqueKey: "value", showLabel: "label" }
      ),
      ...formatOptions(
        {
          carriers_transfer: JSON.parse(JSON.stringify(option_carriers)),
        },
        { uniqueKey: "label", showLabel: "value" }
      ),
    };

    setOptionData(temp);
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    if (tabData.length === 0) return;

    let result = getPageSetting([
      `/daily/overview/${tabData[tabActive].value}`,
    ]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      if (result.params.startDate && result.params.endDate) {
        result.params.dateRange = [
          new Date(result.params.startDate),
          new Date(result.params.endDate),
        ];
      }
      result.params.typeList =
        result.params.typeList?.split(",").map((item) => Number(item)) ?? [];

      let isshippedList = [];
      if (result.params.isshippedList !== undefined)
        isshippedList.push(Number(result.params.isshippedList == 0 ? 0 : 1));
      // else isshippedList = isshippedList.concat([0, 1]);

      if (result.params.isreceived !== undefined)
        isshippedList.push(Number(result.params.isreceived == 0 ? 2 : 3));
      // else isshippedList = isshippedList.concat([2, 3]);

      result.params.isshippedList = isshippedList;

      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, [tabData]);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      getListData({ type: "list", signal });
    },
    [reload]
  );

  const getListData = ({ type = "list", signal }) => {
    let params = {
      ...search,
      page: search.isTotal ? -1 : search.page,
      size: search.isTotal ? -1 : search.size,
    };

    if (search.dateRange && search.dateRange[0] && search.dateRange[1]) {
      params.startDate = moment(search.dateRange[0]).format("YYYY-MM-DD");
      params.endDate = moment(search.dateRange[1]).format("YYYY-MM-DD");
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    delete params.dateRange;

    if (search.typeList) {
      params.typeList = search.typeList.join(",");
    }

    if (search.isshippedList) {
      let notShipped = search.isshippedList.indexOf(0) !== -1,
        shipped = search.isshippedList.indexOf(1) !== -1,
        notReceived = search.isshippedList.indexOf(2) !== -1,
        received = search.isshippedList.indexOf(3) !== -1;

      if (notShipped && shipped) params.isshippedList = null;
      else params.isshippedList = notShipped ? 0 : shipped ? 1 : null;

      if (notReceived && received) params.isreceived = null;
      else params.isreceived = notReceived ? 0 : received ? 1 : null;
    }

    if (tabData[tabActive].value === "wayfairFinancial") {
      params.typeList = "2";
    }

    params = cleanObj(params);

    if (type === "list") {
      setPageSetting({
        params,
        page: `/daily/overview/${tabData[tabActive].value}`,
      });
    } else if (type === "export") {
      params.page = -1;
      params.size = -1;
    }
    delete params.isTotal;

    return DailyAPI.getTotalOrderData({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        if (type === "list") {
          data.forEach((item) => {
            item.isreceived = !item.isreceived ? false : true;
            item.isinsurance = !item.isinsurance ? false : true;
            item.issign = !item.issign ? false : true;
            item.isshipped = !item.isshipped ? false : true;

            item.wayfair_remittance?.forEach((wayfair, index) => {
              item[`remittance_date_${index + 1}`] = wayfair.remittance_date;
              item[`remittance_${index + 1}`] = wayfair.remittance;
              item[`remittance_amount_${index + 1}`] =
                wayfair.remittance_amount;
              item[`discrepancy_${index + 1}`] = wayfair.discrepancy;
            });
          });

          let checkAll_isshipped = !data.some((item) => !item.isshipped);

          if (search.isTotal) {
            setTbRows(total);
          }

          setTbData(data);
          setTbTotal(total);
          reset({
            ...defaultValues,
            checkAll_isshipped: checkAll_isshipped,
            data: data,
          });
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          return data;
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  // 取得回傳的模板資料
  const getTemplate = ({ importTemplate = {}, exportTemplate = {} } = {}) => {
    setModuleData({ importTemplate, exportTemplate });
  };

  // 設定列表欄位
  useEffect(() => {
    let activeTab = tabData[tabActive]?.value ?? "overview";
    let activeTemplate =
        moduleData.exportTemplate.settings ?? defTemplate[activeTab]?.export,
      entries = Object.values(activeTemplate);
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 若有呈現 order_id，凍結包含本身在內左側的欄位
    let frozeColumn = [];
    if (tabActive === 0 && activeTemplate.order_id?.checked) {
      entries.forEach((item) => {
        if (item.checked && item.order <= activeTemplate.order_id.order)
          frozeColumn.push(item.apiKey);
      });
    }

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "single",
      saveFun: saveColumnAction,
      activeTemplate: entries,
      defaultTemplate: defTemplate[activeTab]?.export,
      formSetting: setting,
      frozenSetting: {
        frozen: true,
        align: "left",
        column: frozeColumn,
      },
      optionData: optionData,
      tbRows,
    });

    if (activeTab === "overview") {
      tempColumns.unshift({
        field: "back",
        header: "動作",
        style: {
          width: 65,
          minWidth: 65,
        },
        frozen: true,
        alignFrozen: "left",
        body: (data, { rowIndex }) => (
          <>
            <Button
              id={`button_back_${rowIndex % tbRows}`}
              type="button"
              label="返還"
              className="p-button-danger p-button-sm px-2 py-1 mr-2"
              onClick={() =>
                setTimeout(() => {
                  setConfirmOption({
                    target: document.getElementById(
                      `button_back_${rowIndex % tbRows}`
                    ),
                    message: "確認是否返還?",
                    icon: "pi pi-exclamation-triangle",
                    visible: true,
                    acceptLabel: "是",
                    rejectLabel: "否",
                    accept: () =>
                      cancelConfirm({
                        rowIndex: rowIndex % tbRows,
                      }),
                    reject: () => null,
                  });
                }, 200)
              }
            />
          </>
        ),
      });
    }

    let temp = [];
    tempColumns.forEach((item) => {
      if (item.field === "isshipped") {
        let label = item.header;
        item.header = () => (
          <FormCheckbox
            className="m-0"
            setting={setting}
            data={{
              name: "checkAll_isshipped",
              label: label,
              type: "boolean",
              value: true,
              changeFun: (e) => checkChange("all", item.field, e),
            }}
          />
        );
        item.body = (data, { rowIndex }) => (
          <>
            <FormCheckbox
              className="m-0"
              setting={setting}
              data={{
                name: `data[${rowIndex % tbRows}][${item.field}]`,
                label: "",
                type: "boolean",
                value: true,
                changeFun: (e) => checkChange("single", item.field, e),
                saveFun: () =>
                  saveColumnAction({
                    rowIndex: rowIndex % tbRows,
                    apiKey: item.field,
                  }),
              }}
            />
          </>
        );
      } else if (item.field === "daily_inventoryList") {
        item.body = (data, { rowIndex }) => (
          <>
            <ViewPallet
              inventoryData={data.inventoryList}
              chooseData={data[item.field]}
              optionData={optionData}
            />
          </>
        );
      } else if (item.field === "tracking_link") {
        item.body = (data, { rowIndex }) => (
          <>
            {data[item.field] ? (
              <a href={data[item.field]} target="_blank">
                連結
              </a>
            ) : (
              ""
            )}
          </>
        );
      }

      if (item.field !== "fnsku" && item.field !== "warehouse") {
        temp.push(item);
      }
    });

    setTbColumns(temp);
  }, [moduleData, setting, optionData, tabActive, tbData, tbRows]);

  // 設定列表欄位驗證
  useEffect(() => {
    let entries = Object.values(moduleData.exportTemplate.settings ?? {});
    if (entries.length === 0 || tbData.length) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempSchemas = createSchema({
      type: "list",
      activeTemplate: entries,
      defaultTemplate:
        defTemplate[tabData[tabActive]?.value ?? "overview"]?.export,
      yup: yup,
    });

    setSchema(
      yup
        .object({
          data: yup.array().of(yup.object().shape(tempSchemas)),
        })
        .required()
    );
  }, [moduleData, tbData, tabActive]);

  // 勾選-切換
  const checkChange = (type, fieldName, e) => {
    let tempData = getValues();
    if (!tempData.data || tempData.data.length === 0) return;

    let total = 0, // 可勾選數量
      checked = 0; // 勾選數量
    if (type === "all") {
      tempData.data.forEach((item) => {
        item[fieldName] = tempData[`checkAll_${fieldName}`];

        if (item[fieldName]) checked++;
      });
      reset(tempData);

      if (fieldName === "isshipped") {
        dispatch(setProgress(true));

        let jsonData = tempData.data.map((item) => ({
          daily_id: item.daily_id,
          type: item.type,
          [fieldName]: item[fieldName] ? 1 : 0,
        }));

        saveMultiAction({ jsonData: { data: jsonData }, msg: "變更" });
      }
    } else {
      tempData.data.forEach((item) => {
        total++;
        if (item[fieldName]) checked++;
      });

      if (tempData[`checkAll_${fieldName}`] !== !(total !== checked)) {
        tempData[`checkAll_${fieldName}`] = !(total !== checked);
        reset(tempData);
      }
    }
  };

  // 整理單筆資料
  const saveColumnAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      daily_id: data.daily_id,
      type: data.type,
      [apiKey]: data[apiKey],
    };

    if (
      apiKey === "isshipped" ||
      apiKey === "isreceived" ||
      apiKey === "isinsurance" ||
      apiKey === "issign"
    ) {
      jsonData[apiKey] = data[apiKey] ? 1 : 0;
    }

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    dispatch(setProgress(true));

    saveMultiAction({ jsonData: { data: [jsonData] }, msg: "變更" });
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let activeTab = tabData[tabActive].value;
    let updateData = [];
    tbData.forEach((item, index) => {
      let data = submitData.data[index];
      let update_flag = checkFormUpdate({
        newData: data,
        oldData: item,
        exclude: ["wayfair_remittance", "daily_inventoryList", "inventoryList"],
      });

      if (update_flag) {
        let temp = {
          daily_id: item.daily_id,
          type: item.type,
          ...formatTemplateColumns({
            type: "export",
            template: defTemplate[activeTab].export,
            data: [data],
          })[0],
        };

        temp.isreceived = temp.isreceived ? 1 : 0;
        temp.isinsurance = temp.isinsurance ? 1 : 0;
        temp.issign = temp.issign ? 1 : 0;
        temp.isshipped = temp.isshipped ? 1 : 0;

        delete temp.daily_inventoryList;

        updateData.push(temp);
      }
    });

    if (updateData.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    saveMultiAction({ jsonData: { data: updateData }, msg: "變更" });
  };

  // 儲存資料
  const saveMultiAction = ({ jsonData, msg = "" }) => {
    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "overview") {
      promise = DailyAPI.updateTotalOrderData({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        // dispatch(
        //   setToast({
        //     severity: "success",
        //     summary: `${msg}成功`,
        //     detail: "",
        //   })
        // );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 儲存匯入資料
  const saveImportAction = ({ jsonData, resetFileIpt }) => {
    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "wayfairFinancial") {
      promise = DailyAPI.setWayFairRemittance({ data: jsonData });
    } else if (activeTab === "shippedFee") {
      promise = DailyAPI.updateShippedFee({ data: jsonData });
    } else if (activeTab === "tracking") {
      promise = DailyAPI.updateTacking({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "匯入成功",
            detail: "",
          })
        );
        resetFileIpt();
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "匯入失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 返還
  const cancelConfirm = ({ rowIndex }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];

    let updateData = {
      daily_id: data.daily_id,
      type: data.type,
      isconfirm: 0,
    };

    saveMultiAction({ jsonData: { data: [updateData] }, msg: "變更" });
  };

  // 表格 列 樣式
  const tbRowClass = (data) => {
    return {
      "bg-complete":
        tabData[tabActive]?.value === "overview" &&
        data.isshipped &&
        data.isreceived, // 有出貨、有收到
      "bg-shipped":
        tabData[tabActive]?.value === "overview" &&
        data.isshipped &&
        !data.isreceived, // 有出貨、沒收到
      "bg-unshipped":
        tabData[tabActive]?.value === "overview" &&
        !data.isshipped &&
        !data.isreceived, // 沒出貨、沒收到
    };
  };

  return (
    <>
      <ConfirmPopup
        {...confirmOption}
        onHide={() =>
          setConfirmOption((state) => ({ ...state, visible: false }))
        }
      />

      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <TabView
        className="mb-3"
        activeIndex={tabActive}
        onTabChange={(e) => tabChange(e)}
        // scrollable
      >
        {tabData.map((item, index) => (
          <TabPanel key={`tab_item_${index}`} header={item.label}></TabPanel>
        ))}
      </TabView>

      <div className="grid mr-0">
        <div className="col-12 pr-0">
          <ModuleFileTransfer
            key={tabData[tabActive]?.value}
            setting={{
              importFlag: tabData[tabActive]?.value !== "overview",
              exportFlag: true,
            }}
            moduleType={tabData[tabActive]?.value}
            importColumns={
              defTemplate[tabData[tabActive]?.value ?? "overview"]?.import
            }
            exportColumns={
              defTemplate[tabData[tabActive]?.value ?? "overview"]?.export
            }
            exportData={tbData}
            optionData={optionData}
            saveAction={saveImportAction}
            getTemplate={getTemplate}
            getAllData={getListData}
          />
        </div>

        {tabData[tabActive]?.value === "overview" && (
          <>
            <div className="col-12 border-top-1 border-300 p-0"></div>
            <div className="col-12 sm:col-4 md:col-3 lg:col-3 pr-0">
              <Calendar
                className="w-full"
                name="dateRange"
                value={filterData.dateRange}
                onChange={(e) => changeFilter(e)}
                placeholder="Start Date ~ End Date"
                selectionMode="range"
                numberOfMonths={2}
                readOnlyInput
                showButtonBar
              />
            </div>
            <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
              <MultiSelect
                className="w-full"
                name="typeList"
                value={optionData.platform ? filterData.typeList : []}
                options={optionData.platform}
                onChange={(e) => changeFilter(e)}
                optionValue="value"
                optionLabel="label"
                placeholder="選擇平台"
                display="chip"
              />
            </div>
            <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
              <MultiSelect
                className="w-full"
                name="isshippedList"
                value={
                  optionData.shipping_status ? filterData.isshippedList : []
                }
                options={optionData.shipping_status}
                onChange={(e) => changeFilter(e)}
                optionValue="value"
                optionLabel="label"
                placeholder="選擇貨物狀態"
                display="chip"
              />
            </div>
            <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
              <Dropdown
                name="warehouse"
                className="w-full"
                value={filterData.warehouse}
                onChange={(e) => changeFilter(e)}
                options={optionData.warehouse}
                optionLabel="code"
                optionValue="id"
                placeholder="選擇倉庫"
                emptyMessage={system_dropdown.empty}
                showClear
              />
            </div>
            <div className="col-12 sm:col-4 md:col-3 lg:col-2 pr-0">
              <InputText
                name="keywords"
                className="w-full"
                value={filterData.keywords}
                onChange={(e) => changeFilter(e)}
                placeholder="Keyword"
              />
            </div>
            <div className="flex col-12 sm:col-4 md:col-3 lg:col-1 pr-0">
              <Button
                className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
                icon="pi pi-search"
                onClick={() => {
                  setSearch((state) => ({ ...state, ...filterData, page: 1 }));
                  setTbFirstIndex(0);
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className="mt-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataTable
            value={tbData}
            className={classNames("size-sm", {
              "table-empty": tbData.length === 0,
            })}
            size="normal"
            paginator
            paginatorTemplate={ModuleTablePaginator}
            currentPageReportTemplate="Total {totalRecords} items"
            rowClassName={tbRowClass}
            lazy
            first={tbFirstIndex}
            totalRecords={tbTotal}
            onPage={onPage}
            rows={tbRows}
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            scrollDirection="both"
            responsiveLayout="scroll"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column key={`${item.field}_${index}`} {...item} />
            ))}
          </DataTable>

          {tbData.length === 0 ? (
            <ModuleTableEmpty />
          ) : tabData[tabActive]?.value === "overview" ? (
            // <div className="flex -mt-6">
            //   <Button className="px-3 ml-auto" type="submit" label="Save" />
            // </div>
            <></>
          ) : null}
        </form>
      </div>
    </>
  );
}
