import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { DailyAPI } from "../../axios/api";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInputtext,
  FormInputnumber,
} from "../../components/formElement/index";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function DailyPopCopy(props) {
  const { closePrePop, prePopOption, setPreReload, qtyKey, activeTab } = props;
  const dispatch = useDispatch();
  // 表單設定
  const defaultValues = {
    order_id: "",
    qty: null,
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        order_id: yup.string().required(),
        qty: yup
          .number()
          .test("required", "必填", (value) => {
            return value <= prePopOption.data[qtyKey] - 1;
          })
          .nullable(),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      data: [
        {
          ...submitData,
          id: prePopOption.data.id,
        },
      ],
    };

    let promise;
    if (activeTab === "unshippedOrder") {
      promise = DailyAPI.copyDailyUnshippedOrderData({ data: jsonData });
    } else if (activeTab === "wayfairOrder") {
      promise = DailyAPI.copyDailyWayFairOrderData({ data: jsonData });
    } else if (activeTab === "dfOrder") {
      promise = DailyAPI.copyDailyDFOrderData({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "複製成功",
            detail: "",
          })
        );
        closePrePop({ type: "copy" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "複製失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid mx-0">
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="order_id"
              className={classNames(
                { "p-error": errors.order_id },
                "col-12 sm:col-3 sm:text-right sm:mb-0"
              )}
            >
              Order ID
            </label>
            <div className="col-12 sm:col-9">
              <FormInputtext setting={setting} data={{ name: "order_id" }} />
            </div>
          </div>
          <div className="sm:flex sm:align-items-center field col-12">
            <label
              htmlFor="qty"
              className={classNames(
                { "p-error": errors.qty },
                "col-12 sm:col-3 sm:text-right sm:mb-0"
              )}
            >
              Qty
            </label>
            <div className="col-12 sm:col-9">
              <FormInputnumber setting={setting} data={{ name: "qty" }} />
            </div>
          </div>
          <div className="text-right text-orange-400 field -mt-4 pr-2 col-12">
            <span className="mr-2">
              Qty 上限 {prePopOption.data[qtyKey] - 1}
            </span>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="Back"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "copy" })}
            />
            <Button type="submit" label="Save" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
