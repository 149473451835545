export const googleTableExportColumns = {
  name: {
    name: "Name",
    value: "Name",
    apiKey: "name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 1,
  },
  schema_name: {
    name: "Schema Name",
    value: "Schema Name",
    apiKey: "schema_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 2,
  },
  // status: {
  //   name: "Status",
  //   value: "Status",
  //   apiKey: "status",
  //   uiType: "select",
  //   options: "open",
  //   optionType: "number",
  //   isShow: true,
  //   checked: true,
  //   listDisabled: false,
  //   listUI: false,
  //   required: true,
  //   width: 100,
  //   order: 3,
  // },
};
