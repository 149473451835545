import request from "../request";
import Url from "../url/transfer";

const getIsUseExportImportModule = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getIsUseExportImportModule + path, data, options);
};
const getExportImportModuleList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getExportImportModuleList + path, data, options);
};
const setExportImportModule = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setExportImportModule + path, data, options);
};
const updateExportImportModule = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateExportImportModule + path, data, options);
};
const deleteExportImportModule = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteExportImportModule + path, data, options);
};

export default {
  getIsUseExportImportModule,
  getExportImportModuleList,
  setExportImportModule,
  updateExportImportModule,
  deleteExportImportModule,
};
