// 取得 cookie 值
export function getCookie(name) {
  const cookieVal = `; ${document.cookie}`;
  const parts = cookieVal.split(`; ${name}=`);
  if (parts.length >= 2) return parts.pop().split(";").shift();
  return "";
}

// 排除 JSON 資料
export function excludeJSON(data, exclude) {
  let temp = { ...data };
  exclude.forEach((item) => {
    delete temp[item];
  });

  return temp;
}

// 設定 formData 資料
export function formatFormData(data) {
  let formData = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    formData.append(name, value);
  });

  return formData;
}

// 設定回傳資料
export function formatResData(oldData, newData) {
  let temp = { ...oldData };
  Object.entries(temp).forEach(([key, val]) => {
    if (
      newData[key] !== null &&
      newData[key] !== undefined &&
      newData[key] !== ""
    ) {
      temp[key] = newData[key];
    }
  });

  return temp;
}

// 排除obj內無資料的項目
export function cleanObj(data) {
  let temp = {};
  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      temp[key] = value;
    }
  });

  return temp;
}

// 轉換options資料
export function formatOptions(
  rawData,
  { uniqueKey = "value", showLabel = "label", childKey = "child" } = {}
) {
  let tempObj = {};

  function formatOption(data, items) {
    items.forEach((item) => {
      item["showLabel"] = item[showLabel];
      item["showValue"] = item[uniqueKey];
      data[item[uniqueKey]] = item;

      if (item[childKey]) {
        formatOption(data, item[childKey]);
      }
    });
  }

  Object.entries(rawData).forEach(([name, value]) => {
    tempObj[`${name}_obj`] = {};
    formatOption(tempObj[`${name}_obj`], value);
  });

  return { ...rawData, ...tempObj };
}

// 確認表單是否更新
export function checkFormUpdate({ newData = {}, oldData = {}, exclude = [] }) {
  return Object.entries(newData).some(
    ([key, val]) =>
      val !== undefined && exclude.indexOf(key) === -1 && oldData[key] != val
  );
}

// 確認欄位是否更新
export function checkColumnUpdate(newData, rawData, name) {
  return newData[name] != rawData[name];
}

// 下載
export function downloadFileByLink({ url = "", filename = "" }) {
  let ext = url.split(".");
  ext = ext[ext.length - 1];

  let link = document.createElement("a");
  document.body.appendChild(link);
  link.href = url;
  link.download = `${filename}.${ext}`;
  link.target = "_blank";
  link.click();
  document.body.removeChild(link);
}

// 列表 page 參數設定
export function setPageSetting(data) {
  sessionStorage.setItem("pageSetting", JSON.stringify(data));
}

// 取得 列表 page 參數
export function getPageSetting(page) {
  let pageSetting = sessionStorage.getItem("pageSetting");
  if (pageSetting) pageSetting = JSON.parse(pageSetting);
  if (pageSetting && page.indexOf(pageSetting.page) !== -1) return pageSetting;

  return null;
}

// excel 日期傳換
export function parseExcelDateToDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}
